<template>
  <card>
    <h4 slot="header" class="card-title">Il tuo Profilo</h4>
    <form @submit.prevent="updateProfile">
      <div class="row">
        <div class="col-md-4">
          <base-input type="text"
                    label="Username"
                    placeholder="Username"
                    v-model="user.username">
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input type="email"
                    label="Email"
                    disabled="true"
                    placeholder="Email"
                    v-model="user.email">
          </base-input>
        </div>
        <div class="col-md-4">
          <!--<base-input type="text"
                    label="steamID"
                    pattern="^[0-9]{17}$" 
                    placeholder="Inserisci il tuo steamID"
                    v-model="user.steamID">
          </base-input>-->
          <label for="user.steamID">SteamID<i @click="openSteamIDPage()" class="fa fa-info-circle pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i></label>
          <input pattern="^[0-9]{17}$" 
          title="Lo steamID64 è un numero identificativo del tuo account Steam. Per sapere come trovarlo nel tuo account Steam clicca sulla 'i'"
          type="text" v-model="user.steamID" name="user.steamID" class="form-control" 
          :class="{ 'is-invalid': !user.steamID }" />
          <div v-if="!user.steamID" class="invalid-feedback-login">SteamID non può essere vuoto</div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <base-input type="text"
                    label="Nome"
                    placeholder="First Name"
                    v-model="user.first_name">
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input type="text"
                    label="Cognome"
                    placeholder="Last Name"
                    v-model="user.last_name">
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>About Me</label>
            <textarea rows="5" class="form-control border-input"
                      placeholder="Puoi aggiungere qualche informazione su di te"
                      v-model="user.about_me">
              </textarea>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right">
          Salva modifiche
        </button>
      </div>
      <div class="clearfix"></div>
    </form>

    <reset-password-form>
    </reset-password-form>

    <change-email-form>
    </change-email-form>

  </card>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import auth from '@/_helpers/auth-header';
  import { userService } from '@/_services';
  import { bus } from '@/main';
  import ResetPasswordForm from './ResetPasswordForm.vue';
  import ChangeEmailForm from './ChangeEmailForm.vue';



  var backendURL=process.env.VUE_APP_BE_URL

  export default {
    components: {
      Card,
      ResetPasswordForm,
      ChangeEmailForm
    },
    data () {
      return {
        user: {
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          steamID:'',
          about_me: ``
        }
      }
    },
    created() {
      this.getMe()
    },
    methods: {
      openSteamIDPage(){
          window.open("https://support.ubisoft.com/en-GB/Faqs/000027522/Finding-your-Steam-ID?fallback=en_GB&isSso=true&refreshStatus=noLoginData", '_blank');  
      },
      getMe() {
        var response = userService.getMe();
        response.then(
          json => {
            this.user.username=json.username;
            this.user.username=json.username;
            this.user.email=json.email;
            this.user.first_name=json.first_name;
            this.user.last_name=json.last_name;
            this.user.about_me=json.about_me;
            this.user.steamID=json.steamID;
          }
        )
      },
      updateProfile () {
        var response = userService.update(this.user);
        response.then(
          json => {
            this.user.username=json.username;
            this.user.username=json.username;
            this.user.email=json.email;
            this.user.first_name=json.first_name;
            this.user.last_name=json.last_name;
            this.user.about_me=json.about_me;
            this.user.steamID=json.steamID;
          }
        )
        bus.$emit("updateUserCard",this.user)
      }
    }
  }
</script>
<style>

</style>
