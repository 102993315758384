<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <user-card>
          </user-card>
        </div>
        <div class="col-md-9">
          <edit-profile-form>
          </edit-profile-form>
        </div>
      </div>
    <card>
      <h4 class="card-title"><i class="text-info fa fa-calendar"/> Il mio calendario</h4>
      <p class="card-category">Qui puoi trovare gli eventi a cui sei iscritto</p>
      <div class="row my-2">
            <event class="col-sm-6 mx-auto" v-for="(event, index) in event_list" 
            :event_name="event.event_name" 
            :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" 
            :view_mode="true"
            :championship_id="event.champ_id"
            :event_json_string="objToJSON(event)" v-bind:key="index"/>        
            <div v-if="event_list.length===0" class="col-sm-12 mx-auto text-center"><i class="text-danger fa fa-close"/> Non sei iscritto a nessun evento.</div>
      </div> 
    </card>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'
  import { eventService } from '@/_services';
import Card from '../components/Cards/Card.vue';


  export default {
 
    data(){
      return {
        event_list: []
      }
    },
    components: {
      EditProfileForm,
      UserCard,
      Card
    },
    created(){
      eventService.getMyEvents().then(json=>{
          this.event_list=json
      }
      );
    },
    methods:{
        objToJSON(obj){
            return JSON.stringify(obj)
        }
    }
  }

</script>
<style>

</style>
