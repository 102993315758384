<template>
  <card class="card-user">
    <img slot="image" src="/img/user_card.png" alt="..."/>
    <div class="author">
      
        <img class="avatar border-gray" v-bind:src="this.avatarURL" 
        @error="onImgError()"
        alt=""/>
        <p class="title">{{user.username}} <br />
          <small>{{user.mail}}</small>
        </p>
      
    </div>
    <!--<p class="description text-center"> {{user.about_me}}
    </p>-->
    <div class="row">
        <div class="col-xs-6  mx-auto mt-2">
          <input type="file" name="file" id="file" class="customInputFile" @change="onFileSelected">
          <label class="btn btn-info btn-fill" style="padding: 10px 14px;" for="file">Cambia immagine</label>
        </div>
        <div class="col-xs-6  mx-auto mt-2">
          <button class="btn btn-info btn-fill" style="padding: 10px 14px;" :disabled="!this.selectedFile" @click="onUpload">Upload immagine</button>
      </div>

    </div>

  </card>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import auth from '@/_helpers/auth-header';
  import { userService } from '@/_services';
  import { bus } from '@/main';

  var backendURL=process.env.VUE_APP_BE_URL

  export default {
    components: {
      Card
    },
    created (){
      this.getMe()
      bus.$on('updateUserCard', (data) => {
        this.user.username=data.username;
        this.user.email=data.email;
        this.user.first_name=data.first_name;
        this.user.last_name=data.last_name;
        this.user.about_me=data.about_me;
      })
    },
    data () {
      return {
        details: [
          {
            title: '12',
            subTitle: 'Files'
          },
          {
            title: '2GB',
            subTitle: 'Used'
          },
          {
            title: '24,6$',
            subTitle: 'Spent'
          }
        ],
        selectedFile: null,
        user: {
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          about_me: ``,
          steamID: '',
          id: ''
        },
        isAvatarSqareImg: false,
        avatarURL: '/img/faces/face-0.jpg',
        //for image refresh
        randomNumber:0
      }
    },
    methods: {
      removeLastChar(str){
        if(str)
          return str.slice(0,-1)
        else return null
      },
      onImgError(){
        this.avatarURL = '/img/faces/face-0.jpg'
      },
      getMe() {
        var response = userService.getMe();
        response.then(
          json => {
            console.log(json)
            this.user.username=json.username;
            this.user.username=json.username;
            this.user.email=json.email;
            this.user.first_name=json.first_name;
            this.user.last_name=json.last_name;
            this.user.about_me=json.about_me;
            this.user.id=json.id_;
            this.user.steamID=json.steamID;
            this.avatarURL=process.env.VUE_APP_AWS_BUCKET_URL+json.id_+'_avatar';
            console.log(this.avatarURL);


          }
        )
      },
      getClasses (index) {
        var remainder = index % 3
        if (remainder === 0) {
          return 'col-md-3 col-md-offset-1'
        } else if (remainder === 2) {
          return 'col-md-4'
        } else {
          return 'col-md-3'
        }
      },
      createImageReader(file,object,whenReady) {
          var reader = new FileReader()
          reader.onload = function(evt) {
              var image = new Image();
              image.onload = function(evt) {
                  var width = this.width;
                  var height = this.height;
                  if (whenReady) 
                    whenReady(width, height, object);
              };
              image.src = evt.target.result; 
          };
          reader.readAsDataURL(file);
      },
      onFileSelected(event){
        console.log(event)
        var currFile = event.target.files[0]

        this.createImageReader(currFile,this, function(w, h, object) {
            console.log("Hi the width is " + w + " and the height is " + h);
            if(w === h){
              console.log("QIO ")
              console.log("QIO "+w === h )
              object.isAvatarSqareImg = true
              }
            else
              object.isAvatarSqareImg = false
        });

        this.selectedFile = event.target.files[0]
        console.log(this.selectedFile)
        console.log(this.selectedFile.size)
        this.$confirm(
          {
            title: 'Informazione',
            message: "Immagine caricata. Per confermare l'upload dell'immagine clicca ora su 'Upload immagine'.",
            button: {
              yes: 'Ho capito',
            }
          }
        )
      },


      onUpload(event){
        if (this.selectedFile.size > 1024 * 1024 * 3) {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato è troppo grande, la dimensione massima per la foto profilo è di 3 MB.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        if (this.selectedFile['type']!=='image/jpeg' && this.selectedFile['type']!=='image/png') {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato deve essere un file jpeg o un file png.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        if(!this.isAvatarSqareImg){
          this.$confirm(
            {
              title: 'Errore',
              message: "L'immagine avatar deve essere quadrata, seleziona una immagine con 'altezza'='larghezza'.",
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }

        var headers_ = auth.authHeader()
        //headers_['Content-Type'] = 'multipart/form-data; charset=UTF-8'
        headers_['accept'] = 'application/json'
        const formData = new FormData()
        formData.append('file', this.selectedFile)

        const requestOptions = {
          method: 'POST',
          headers: headers_,
          body: formData
        };

        return fetch(`${backendURL}/users/upload_avatar`, requestOptions)
          .then(response => { 
            if(response.ok){
                this.randomNumber=this.randomNumber+1;
                this.avatarURL=process.env.VUE_APP_AWS_BUCKET_URL+this.user.id+'_avatar';
                this.avatarURL=this.avatarURL+'?rand='+this.randomNumber;
                return response.json();    
            } 
            if(response.status==401){
                userService.logout();
                this.$router.go('/login');
            }
              
          })
          .then(response => {
            console.log(response);
            this.$confirm(
              {
                title: 'Upload completato',
                message: "L'upload dell'immagine è stato completato con successo.",
                button: {
                  yes: 'Ho capito',
                }
              }
            )
          })
          .catch(err => {
            console.log(err);
            this.$confirm(
              {
                title: 'Upload non completato',
                message: "L'upload dell'immagine non è stato completato con successo, la preghiamo di riprovare.",
                button: {
                  yes: 'Ho capito',
                }
              }
            )
          });

      }
    }
  }

</script>
<style>

</style>
