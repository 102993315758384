/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import { store } from './_store'
import VeeValidate from 'vee-validate'


// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

// router setup
import { router } from './routes'

import './registerServiceWorker'
// plugin setup
Vue.use(LightBootstrap)
Vue.use(VeeValidate);


export const bus = new Vue();
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
