<template>
    <div class="content">
        <div class="row" v-if="game==='AC'">
            <div class="col-md-6 mx-auto ">
                <label  for="AC_result">URL risultati della sessione per AC</label>
                <input type="text" placeholder="URL risultati della sessione per AC" 
                    v-model="AC_result_url" name="AC_result" class="form-control" 
                    :class="{ 'is-invalid': !AC_result_url }" />
                <div v-if="!AC_result_url" class="invalid-feedback-login">URL dei risultati non può essere vuoto</div>

            </div>
                <div class="col-md-6  mx-auto mt-4">
                <button class="btn btn-info btn-fill" style="padding: 10px 14px;" :disabled="!this.AC_result_url" @click="onUploadAcResults">Upload risultati</button>
            </div>
        </div>

        <div v-if="game==='ACC'" class="text-center">
            Caricamento risultati di sessione per ACC non ancora supportato.
        </div>
        <div  v-if="game==='RF2'" class="text-center">
            Caricamento risultati di sessione per RF2 non ancora supportato.
        </div>
        <div  v-if="game==='AMS2'" class="text-center">
            Caricamento risultati di sessione per AMS2 non ancora supportato.
        </div>
    </div>    
</template>
<script>
import { eventService } from '@/_services';

export default {
    props: {
        game: String,
        session_id: String
    },
    data () {
        return {
            selectedResultFile: null,
            AC_result_url: ''
        }
    },
    created(){

    },
    methods: {
        onResultFileSelected(){
            

        },
        onUploadAcResults(){
            this.$confirm(
            {
                title: "Upload risultati di AC",
                message: `Confermi di voler caricare i risultati di sessione selezionati?`,
                button: {
                no: 'No',
                yes: 'Si'
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                    eventService.addAcSessionResult(this.session_id, this.AC_result_url).then(json=>{
                        this.$confirm(
                            {
                                title: 'Operazione completata',
                                message: "Risultati evento caricati correttamente.",
                                button: {
                                yes: 'Ho capito',
                                },
                                callback: confirm => {
                                if (confirm) {
                                    this.$router.go()
                                }
                                }
                            }
                        )
                    })
                    .catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Risultati evento non caricati correttamente.",
                            button: {
                                yes: 'Ho capito',
                            }
                        }
                    )
                )
                }
            }
        )
        },
        onUploadAccResults(){

        },
    }

}
</script>