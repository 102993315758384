<template>

<div id="page-top">
        <!-- Navigation-->
        <nav class="lp_navbar lp_navbar-expand-lg lp_navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="lp_navbar-brand js-scroll-trigger" href="#page-top"><img src="/lp-assets/img/logos/ATK_logo.png" class="atklogo" alt="" /></a>
                <button class="lp_navbar-toggler lp_navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fa fa-bars ml-1"></i>
                </button>
                <div class="collapse lp_navbar-collapse" id="navbarResponsive">
                    <ul class="lp_navbar-nav text-uppercase ml-auto">
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="#services">Servizi</a></li>
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="#portfolio">Campionati</a></li>
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="#about">About</a></li>
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="#team">Team</a></li>
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="#contact">Contattaci</a></li>
                        <li class="lp_nav-item"><a class="lp_nav-link js-scroll-trigger" href="/admin/championship-list">Login</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container">
                <div class="masthead-heading text-uppercase">It's time To Race</div>
                <div class="masthead-subheading">MoreFast, MoreGrip, MoreFun!</div>

                <a class="btn btn-outline-light btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a>
            </div>
        </header>
        <!-- Services-->
        <section class="page-section" id="services">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Servizi</h2>
                    <h3 class="section-subheading text-muted">Il gruppo ATK ti aspetta, ecco alcuni dei principali servizi offerti:</h3>
                </div>
                <div class="row text-center">
                    <div class="col-md-3">
                        <span class="fa-stack fa-4x">
                            <i class="fa fa-circle fa-stack-2x text-primary"></i>
                            <i class="fa fa-users fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ATK Community</h4>
                        <p class="text-muted">La community di ATK nasce per supportare i nuovi piloti nel rispetto dei valori di ATKSimracing.</p>
                    </div>
                    <div class="col-md-3">
                        <span class="fa-stack fa-4x">
                            <i class="fa fa-circle fa-stack-2x text-primary"></i>
                            <i class="fa fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ATK WebApp</h4>
                        <p class="text-muted">Il portale di ATK facilita i piloti nella gestione degli eventi e campionati offerti dal gruppo.</p>
                    </div>
                    <div class="col-md-3">
                        <span class="fa-stack fa-4x">
                            <i class="fa fa-circle fa-stack-2x text-primary"></i>
                            <i class="fa fa-graduation-cap fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ATK Accademy</h4>
                        <p class="text-muted">L'ATK accademy è l'esclusiva iniziativa di ATK per guidare i piloti neofiti all'apprendimento dei concetti basilari del SimRacing.</p>
                    </div>
                    <div class="col-md-3">
                        <span class="fa-stack fa-4x">
                            <i class="fa fa-circle fa-stack-2x text-primary"></i>
                            <i class="fa fa-flag-checkered fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">Campionati ACI</h4>
                        <p class="text-muted">ATK è organizzatore ufficiale di competizioni SIM racing per ACI Esport. Vai alla sezione campionati per saperne di più!</p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="counter-box"> <i class="fa fa-users fa-4x"></i> <span class="counter">503</span>
                            <p>Utenti iscritti</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="counter-box"> <i class="fa fa-calendar fa-4x"></i> <span class="counter">53</span>
                            <p>Eventi conclusi</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="counter-box"> <i class="fa fa-trophy fa-4x"></i> <span class="counter">15</span>
                            <p>Campionati e trofei</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Portfolio Grid-->
        <section class="page-section bg-light" id="portfolio">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Campionati</h2>
                    <h3 class="section-subheading text-muted">Segui i campionati attivi in questo momento.</h3>
                </div>
                <div class="row">

                    <div v-for="(championship, index) in currentChampionships" v-bind:key="index" class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" v-bind:href="`#portfolioModal${index+1}`">
                                <div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" v-bind:src="computeChampionshipImage(championship['id_'])" alt="" />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">{{championship['championship_name']}}</div>
                                <!--<div class="portfolio-caption-subheading text-muted">Illustration</div>-->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- About-->
        <section class="page-section" id="about">
            <div class="container">
                <div class="text-center">
                    <h2 class="mb-4 section-heading text-uppercase">About us</h2>
                    
                   <!-- <h3 class="section-subheading text-muted">Sottotitolo di esempio.</h3>-->
                </div>
                <ul class="timeline">
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/13.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>2018</h4>
                                <h4 class="subheading">Fondazione di ATKSimrancing</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Dopo anni di simulazione e gare reali, nel 2018 nasce il gruppo ATKSimRacing, creato da tre amici con la solita passione. 
                                Il nostro obiettivo è quello di trasmettere e condividere con tutti questa bellissima passione, cercando sempre di tener conto del reale mondo del MotorSport.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/14.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>2018-2019</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Il Gruppo inizia a prendere forma, viene sviluppata la piattaforma PS4 ed iniziano le prime gare ufficiali.</p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/3.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Ottobre 2019</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Viene sviluppata ed integrata la piattaforma PC, il divertimento si fa sempre più intenso e la community cresce ancora.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/12.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Gennaio 2020</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Il gruppo ha bisogno di un vero e proprio Staff, sette ragazzi con capacità e grande spirito organizzativo entrano a far parte dello Staff ATK, integrando un grande contributo per la continua crescita del gruppo.</p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/15.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Marzo 2020</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Vede la luce la prima MOD per AC creata interamente dal gruppo ricerca e sviluppo di ATK e grazie a livree e piste personalizzate ci spingiamo su Dirette e Live.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/11.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Giugno 2020</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">L’idea di ATK prende vita, strizzando l’occhio al professionismo Virtuale e Reale, stringiamo i primi accordi di partnership, da subito a credere in quello che facciamo sono il Gruppo Peroni Race e TrakRacer Italia, che diventano collaboratori attivi e con i quali sviluppiamo progetti che vedranno la luce nel corso del 2021.</p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/7.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Settembre 2020</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Altri Partner di grande rilievo iniziano ad avvicinarsi, Mercedes Italia - Ligier Automobiles - Bmw Roma, nuovi progetti in via di definizioni che ci vedranno impegnati per tutto il 2021 e 2022.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/9.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Dicembre 2020</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Nasce A.S.D ATKSimRacing, la necessità di affermare il gruppo nel panorama nazionale, richiede la titolarità di un associazione ufficiale e la conferma di una direzione di SimRacing professionale.
                                <br><br>
                                L’anno finisce con ottimi risultati raggiunti, quasi 300 utenti sulle due piattaforme PC e PS4, grande competenza e professionalità di tutto lo staff, dirette e live settimanali su canali streaming e social, oltre 50 eventi e 7 campionati disputati con una media di 25 partecipanti, siamo estremamente orgogliosi di questa piccola realtà che tutti hanno contribuito a realizzare.
                                </p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/10.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Gennaio 2021</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">ATKSimRacing viene approvato da una commissione, come Organizzatore Ufficiale per ACI eSPORT, nel corso dell’anno saranno organizzati vari campionati su AC con la titolarità sottoscritta da ACI.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="/lp-assets/img/about/8.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Aprile 2021</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Online il Portale Ufficiale di ATKSimRacing, webapp dedicata al SimRacing, con tutte le gare e campionati disponibili.
                                </p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image">
                            <h4>
                                <small class="d-none d-lg-block">
                                Vivi insieme a noi  
                                <br />
                                le prossime novità
                                <br />
                                di ATKSimracing!</small>
                            </h4>
                        </div>
                        <div class="timeline-panel d-lg-none">
                            <div class="timeline-body"><p class="text-muted">Vivi insieme a noi  
                                    <br />
                                    le prossime novità
                                    <br />
                                    di ATKSimracing!
                            </p></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- Team-->
        <section class="page-section bg-light" id="team">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Il nostro fantastico team</h2>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/ale.jpg" alt="" />
                            <h4>Alessandro Righi</h4>
                            <p class="text-muted">Fondatore ed Amministratore</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/andre.jpg" alt="" />
                            <h4>Andrea Nutini</h4>
                            <p class="text-muted">Fondatore ed Amministratore</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/bizz.jpg" alt="" />
                            <h4>Marco Bizzaglia</h4>
                            <p class="text-muted">Fondatore ed Amministratore</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
                <!--<div class="text-center">
                    <h2 class="section-heading text-uppercase">STAFF</h2>
                    <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/3.jpg" alt="" />
                            <h4>Daniele D'Andrea</h4>
                            <p class="text-muted">IT Chief</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/4.jpg" alt="" />
                            <h4>Enzo Lonano Nutini</h4>
                            <p class="text-muted">Responsabile supporto tecnico e sviluppo mod</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/5.jpg" alt="" />
                            <h4>Lorenzo D'Amelio</h4>
                            <p class="text-muted">Regista Ufficiale ATK Twitch e YouTube</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/6.jpg" alt="" />
                            <h4>Christian Esposito</h4>
                            <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/7.jpg" alt="" />
                            <h4>Enzo Lonano</h4>
                            <p class="text-muted">Responsabile supporto tecnico e sviluppo mod</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/8.jpg" alt="" />
                            <h4>Lorenzo D'Amelio</h4>
                            <p class="text-muted">Regista Ufficiale ATK Twitch e YouTube</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="staff-member">
                            <img class="mx-auto rounded-circle" src="/lp-assets/img/team/1.jpg" alt="" />
                            <h4>Christian Esposito</h4>
                            <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>-->
            </div>

            
        </section>
        <!-- Clients-->
        <div class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 col-sm-6 my-3">
                        <a href="https://www.bizzaglia.com/"><img class="img-fluid d-block mx-auto" src="/lp-assets/img/logos/Bizzaglia.png" alt="" /></a>
                    </div>
                    <div class="col-md-2 col-sm-6 my-3">
                        <a href="https://www.facebook.com/AGWRX/"><img class="img-fluid d-block mx-auto" src="/lp-assets/img/logos/agworks.png" alt="" /></a>
                    </div>
                    <div class="col-md-2 col-sm-6 my-3">
                        <a href="https://www.trakracer.it/"><img class="img-fluid d-block mx-auto" src="/lp-assets/img/logos/Trak.png" alt="" /></a>
                    </div>
                    <div class="col-md-2 col-sm-6 my-3">
                        <a href="https://www.facebook.com/Autoteknica/"><img class="img-fluid d-block mx-auto" src="/lp-assets/img/logos/Autoteknica.png" alt="" /></a>
                    </div>
                    <div class="col-md-2 col-sm-6 my-3">
                        <a href="https://www.y06informatica.com/"><img class="img-fluid d-block mx-auto" src="/lp-assets/img/logos/Y06.png" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact-->
        <section class="page-section" id="contact">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Contattaci</h2>
                    <h3 class="section-subheading text-muted">Inviaci una mail tramite questa form, ti ricontatteremo il prima possibile.</h3>
                </div>
                <form id="contactForm" name="sentMessage" novalidate="novalidate">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" id="name" type="text" placeholder="Your Name *" v-model="mail.name" required="required" data-validation-required-message="Please enter your name." />
                                <p class="help-block text-danger"></p>
                            </div>
                            <div class="form-group">
                                <input class="form-control" id="email" type="email" placeholder="Your Email *" v-model="mail.email" required="required" data-validation-required-message="Please enter your email address." />
                                <p class="help-block text-danger"></p>
                            </div>
                            <div class="form-group mb-md-0">
                                <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" v-model="mail.phone"  required="required" data-validation-required-message="Please enter your phone number." />
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <textarea class="form-control" id="message" placeholder="Your Message *" v-model="mail.message"  required="required" data-validation-required-message="Please enter a message."></textarea>
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <div id="success"></div>
                        <button  class="btn btn-primary btn-xl text-uppercase" :disabled="!mail.phone||!mail.message||!mail.email||!mail.name" id="sendMessageButton" @click.prevent="sendMail()">Send Message</button>
                        <div v-show="mailError" class="mt-2 alert alert-danger alert-with-icon" data-notify="container">
                            <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                            <span data-notify="message">Invio messaggio fallito</span>
                        </div>
                        <div v-show="mailSent" class="mt-2 alert alert-success alert-with-icon" data-notify="container">
                            <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                            <span data-notify="message">Messaggio inviato correttamente</span>
                        </div>
                        <div v-show="!mail.phone||!mail.message||!mail.email||!mail.name" class="mt-2 alert alert-warning alert-with-icon" data-notify="container">
                            <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                            <span data-notify="message">Devi riempire tutti i campi per contattarci!</span>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 text-lg-left">
                        <small>
                        Copyright © Atksimracing.it 2020<br>
                        A.S.D. ATK SIM RACING<br>
                        P.IVA: 02005090473<br>
                        MONTECATINI-TERME (PT) <br>
                        Via Paolo Casciani 9<br>
                        email amministrativa: asd.atksimracing@gmail.com<br>
                        email info: atksimracing@gmail.com
                        </small>
                    </div>
                    <div class="col-lg-2 text-lg-right">
                        <a  target="_blank" href="/privacy">Privacy Policy</a> / 
                        <a   target="_blank" href="/cookies">Cookies</a>
                    </div>
                    <div class="col-lg-5 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/atksimracing/"><i class="fa fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/atksimracing"><i class="fa fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.youtube.com/channel/UCtLP8NAG9vD7LnFpSzdCUfA"><i class="fa fa-youtube"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.twitch.tv/atksimracing"><i class="fa fa-twitch"></i></a>
                    </div>

                </div>
            </div>
        </footer>
        <!-- Portfolio Modals-->
        <!-- Modal 1-->
        <div v-for="(championship, index_modal) in currentChampionships" v-bind:key="index_modal" class="portfolio-modal modal fade" v-bind:id="`portfolioModal${index_modal+1}`" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-dismiss="modal"><img src="/lp-assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="modal-body">
                                    <!-- Project Details Go Here-->
                                    <h2 class="text-uppercase">{{championship["championship_name"]}}</h2>
                                    <!--<p class="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>-->
                                    <img class="img-fluid d-block mx-auto" style=" min-width:100%;" v-bind:src="computeChampionshipImage(championship['id_'])" alt="" />
                                    <p class="text-left" style="white-space: pre-line;">{{championship["championship_lp_details"]}}</p>
                                    <!--<ul class="list-inline">
                                        <li>Date: January 2020</li>
                                        <li>Client: Threads</li>
                                        <li>Category: Illustration</li>
                                    </ul>
                                    <button class="btn btn-primary" data-dismiss="modal" type="button">
                                        <i class="fa fa-times mr-1"></i>
                                        Close Project
                                    </button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!-- Bootstrap core JS-->
        <!--<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>-->
        <!--<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js"></script>-->
        <!-- Third party plugin JS-->
        <!--<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>-->
        <!-- Contact form JS-->
        <!--<script src="assets/mail/jqBootstrapValidation.js"></script>-->
        <!--<script src="assets/mail/contact_me.js"></script>-->
        <!-- Core theme JS-->
        <!--<script src="js/scripts.js"></script>-->
</template>

<script>
import { championshipService } from '@/_services';
import { utilsService } from '@/_services';

export default {
    name: 'app',
    data () {
        return {
            currentChampionships: [],
            pastChampionships: [],
            mail:
                {
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                },
            mailError:false,
            mailSent:false
        }
    },
    created(){
        championshipService.getPublicChampionshipsWithEventsSessions().then(json=>{
            var i;
            for (i = 0; i < json.length; i++) {
                this.splitChampionships(json[i]);
            }
            this.currentChampionships.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.pastChampionships.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
        }
        );
    },
    mounted() {
        /*let externalScript1 = document.createElement('script')
        externalScript1.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js')
        document.head.appendChild(externalScript1)*/
        let externalScript1 = document.createElement('script')
        externalScript1.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js')
        document.head.appendChild(externalScript1)

        

        let externalScript2 = document.createElement('script')
        externalScript2.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js')
        document.head.appendChild(externalScript2)

        let externalScript3 = document.createElement('script')
        externalScript3.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js')
        document.head.appendChild(externalScript3)


        let externalScript4 = document.createElement('script')
        externalScript4.setAttribute('src', '/lp-assets/mail/jqBootstrapValidation.js')
        document.head.appendChild(externalScript4)

//        let externalScript5 = document.createElement('script')
//        externalScript5.setAttribute('src', '/lp-assets/mail/contact_me.js')
//        document.head.appendChild(externalScript5)

        let externalScript6 = document.createElement('script')
        externalScript6.setAttribute('src', '/lp-assets/js/scripts.js')
        document.head.appendChild(externalScript6)

    /*let externalScript7 = document.createElement('script')
    externalScript7.setAttribute('src', 'https://use.fontawesome.com/releases/v5.15.1/js/all.js')
    document.head.appendChild(externalScript7)*/

    },

  methods: {
        computeChampionshipImage(champ_id){
            return process.env.VUE_APP_AWS_BUCKET_URL+champ_id+"_championship_image"
        },
        sendMail(){
            utilsService.sendContactUs(this.mail).then(
                    data=>{
                        this.mail.email=''
                        this.mail.phone=''
                        this.mail.name=''
                        this.mail.message=''
                        this.mailError=false
                        this.mailSent=true
                    }
                ).catch(err => 
                    this.mailError=true,
                    this.mailSent=false
                );
        },
        splitChampionships(championship){
            
            //if all status are 2 --> closed session
            if(this.checkAllValue(championship, "status",2)){
                if(!championship.hidden==true)
                    this.pastChampionships.push(championship)
                console.log("TEST 1")
            }
            else{
                if(!championship.hidden==true)
                    this.currentChampionships.push(championship)
                console.log("TEST 2")
            }
        },
        //all json key with a specific value
        checkAllValue(json, input_key, value) {
            var key
            var found = true
            for (key in json) {
                if (typeof (json[key]) === "object" ) {
                    console.log("TEST recall")
                    found = found && this.checkAllValue(json[key], input_key,value);
                } else if (String(key) === String(input_key) && String(json[key]) === String(value)) {
                    console.log("TEST 3")
                    console.log(String(input_key)+" "+String(json[key]))
                    return true;
                } else if (String(key) === String(input_key) && String(json[key]) !== String(value)) {
                    console.log("TEST 4")
                    console.log(String(input_key)+" "+String(json[key]))
                    return false;
                }
            }
            return found;
        },
  }
}
/*@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400,700,400italic,700italic');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700');
 <!-- Google fonts-->
<link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet" type="text/css" />
<link href="https://fonts.googleapis.com/css?family=Droid+Serif:400,700,400italic,700italic" rel="stylesheet" type="text/css" />
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700" rel="stylesheet" type="text/css" />
*/
</script>

<style scoped src="../../public/lp-assets/css/styles.css"> </style>
<style scoped url="https://fonts.googleapis.com/css?family=Montserrat:400,700"></style>
<style scoped url="https://fonts.googleapis.com/css?family=Droid+Serif:400,700,400italic,700italic"></style>
<style scoped url="https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700"></style>

