<template>
  <card class="mt-4">
    <h4 slot="header" class="card-title">Cambia la tua password</h4>
                <form>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="old_password">Vecchia password <i :class="is_show_old_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showOldPassword"></i></label>
                                <base-input :type="is_show_old_password ? 'text' : 'password'"
                                            name="old_password"
                                            placeholder="inserisci una password"
                                            v-model="user.old_password">
                                </base-input>
                                </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="password">Password <i :class="is_show_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showPassword"></i></label>
                                <base-input name="password" :type="is_show_password ? 'text' : 'password'"
                                            placeholder="inserisci una password"
                                            v-model="user.password">
                                </base-input>
                                <small v-show="user.password && !has_minimum_lenth" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 8 caratteri.</small>
                                <p></p>
                                <small v-show="user.password && !has_uppercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 maiuscola.</small>
                                <p></p>
                                <small v-show="user.password && !has_lowercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 minuscola.</small>
                                <p></p>
                                <small v-show="user.password && !has_number" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 numero.</small>
                                <p></p>
                                </div>
                                <div class="form-group col-md-6">
                                <label for="password_check">Conferma nuova password <i :class="is_show_check_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showCheckPassword"></i></label>
                                <base-input :type="is_show_check_password ? 'text' : 'password'"
                                            name="password_check"
                                            placeholder="Conferma la password"
                                            v-model="password_confirmation">
                                </base-input>
                                <small v-show="password_confirmation && !is_the_same_pwd" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve coincidere alla password.</small>
                                <p></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 align">
                                <p class="text-danger" v-if="reset_failed"><i class="fa fa-times" aria-hidden="true"> </i> Reset password fallito, sicuro di aver inserito la vecchia password correttamente?.</p>
                                <p class="text-success" v-if="reset_success"><i class="fa fa-check" aria-hidden="true"> </i> Reset password completato.</p>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <button type="button" 
                                    :disabled="!user.password || !user.old_password || !password_confirmation
                                            ||!has_minimum_lenth||!has_uppercase||!has_lowercase || !has_number"
                                    class="btn btn-info btn-fill float-right" @click="completeReset">
                                    Cambia password
                                    </button>
                                </div>
                            </div>
                        </div>
                </form>
  </card>
</template>
<script>
import { userService } from '@/_services';

export default {

    data () {
        return {
            has_minimum_lenth: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            reset_failed: false,
            reset_success: false,
            password_confirmation: '',
            is_the_same_pwd: false,
            is_show_password: false,
            is_show_old_password: false,
            is_show_check_password: false,

            user: {
                old_password: '',
                email: '',
                password: ''
            }
        }
    },
    watch:{
        password(){
            this.has_minimum_lenth = (this.user.password.length >= 8);
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            },
        password_confirmation(){
            this.is_the_same_pwd = this.user.password == this.password_confirmation;
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            }
        },
    computed: {
        password() {
            return this.user.password;
        }
    },
    created () {
        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.user.email = curr_user.sub
    },
    methods: {
        completeReset(){
            this.reset_failed=false
            this.reset_success=false
            userService.userResetPassword(this.user).then(data=>{
                this.reset_success=true;
            }).catch(err => {
                this.reset_failed=true;
            });
        },
        goToLogin(){
            this.$router.push('/login')
        },
        showOldPassword(){
            this.is_show_old_password = !this.is_show_old_password;
        },
        showPassword(){
            this.is_show_password = !this.is_show_password;
        },
        showCheckPassword(){
            this.is_show_check_password = !this.is_show_check_password;
        }
    }
};
</script>
<style scoped>
</style>