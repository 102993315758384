<template>
    <div class="global-item" >         

        <div class="center-item" >         
                <form>
                    <img src="/img/ATK_logo.png" alt="atk logo" class="mx-auto d-block w-50">
                        <div class="row pb-2">
                           <div class="col-md-11 my-auto">
                                <h6>Completa la registrazione</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                            <base-input type="email"
                                        label="Email"
                                        placeholder="Email"
                                        disabled=true
                                        v-model="user.email">
                            </base-input>
                            </div>
                            <div class="form-group col-md-6">
                            <base-input type="steamID"
                                        label="steamID"
                                        :disabled="registration_success || registration_failed"
                                        placeholder="Inserisci il tuo steamID"
                                        v-model="user.steamID">
                            </base-input>
                            <small v-show="!user.steamID" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Non può essere vuoto</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                            <base-input type="text"
                                        label="Nome"
                                        :disabled="registration_success || registration_failed"
                                        placeholder="First Name"
                                        v-model="user.first_name">
                            </base-input>
                            <small v-show="!user.first_name" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Non può essere vuoto</small>
                            <p></p>
                            </div>
                            <div class="form-group col-md-6">
                            <base-input type="text"
                                        label="Cognome"
                                        :disabled="registration_success || registration_failed"
                                        placeholder="Last Name"
                                        v-model="user.last_name">
                            </base-input>
                            <small v-show="!user.last_name" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Non può essere vuoto</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <base-input type="password"
                                            label="Password"
                                            :disabled="registration_success || registration_failed"
                                            placeholder="inserisci una password"
                                            v-model="user.password">
                                </base-input>
                                </div>
                                <div class="form-group col-md-6">
                                <base-input type="password"
                                            label="Conferma password"
                                            :disabled="registration_success || registration_failed"
                                            placeholder="Conferma la password"
                                            v-model="password_confirmation">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <small v-show="!has_minimum_lenth" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 8 caratteri.</small>
                                <p></p>
                                <small v-show="!has_uppercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 maiuscola.</small>
                                <p></p>
                                <small v-show="!has_lowercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 minuscola.</small>
                                <p></p>
                                <small v-show="!has_number" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 numero.</small>
                                <p></p>
                            </div>
                            <div class="col-md-6">
                                <small v-show="!is_the_same_pwd" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve coincidere alla password.</small>
                                <p></p>
                            </div>
                        </div>
 <!--                       <div class="row">
                            <div class="col-md-12">
                                <small>La Password deve contenere almeno: 8 caratteri, una minuscola, una maiuscola ed un numero.</small>
                            </div>
                        </div>-->
                        <div class="row mt-4">
                            <div class="col-md-12">
                            <div class="form-group">
                                <label>About Me</label>
                                <textarea rows="2" class="form-control border-input"
                                        placeholder="Puoi aggiungere qualche informazione su di te"
                                        :disabled="registration_success || registration_failed"
                                        v-model="user.about_me">
                                </textarea>
                            </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-center">
                                    <button type="button" 
                                    :disabled="registration_success || registration_failed || !has_lowercase || !has_uppercase ||
                                              !has_minimum_lenth || !has_number || !user.last_name || !user.first_name ||
                                              !user.steamID || !is_the_same_pwd"
                                    class="btn btn-info btn-fill float-right" @click="completeRegistration">
                                    Completa la registrazione
                                    </button>
                                </div>
                            </div>
                                <div class="col-md-6">
                                    <button type="button" v-if="registration_success || registration_failed"   class="btn btn-info btn-fill float-right" @click="goToLogin">
                                        Vai alla login page
                                    </button>
                                </div>

                        </div>
                        <p class="text-danger mt-4" v-show="registration_failed"><i class="fa fa-times" aria-hidden="true"> </i> Registrazione fallita, il link di attivazione è scaduto. Deve tornare alla login page ed effettuare una nuova registrazione.</p>
                        <p class="text-success mt-4" v-show="registration_success"><i class="fa fa-check" aria-hidden="true"> </i> Registrazione completata, vai alla login page per accedere.</p>
                </form>

        </div>
    </div>

</template>

<script>
import { userService } from '@/_services';

export default {
    data () {
        return {
            has_minimum_lenth: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,

            registration_failed: false,
            registration_success: false,
            password_confirmation: '',
            is_the_same_pwd: false,
            user: {
                password: '',
                email: '',
                first_name: '',
                last_name: '',
                steamID: '',
                about_me: ``,
            }
        }
    },
    watch:{
        password(){
            this.has_minimum_lenth = (this.user.password.length >= 8);
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
        },
        password_confirmation(){
            this.is_the_same_pwd = this.user.password == this.password_confirmation;
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            }
        },
    computed: { 
        password() {
            return this.user.password;
        }
    },
    created () {
        console.log("GETTING TOKEN FROM URL")
        console.log(this.$route.query.token)
        console.log(this.$route.query.first_name)
        console.log(this.$route.query.last_name)
        console.log(this.$route.query.email)
        console.log(this.$route.query.steamID)
        
        this.token=this.$route.query.token
        this.user.first_name=this.$route.query.first_name
        this.user.last_name=this.$route.query.last_name
        this.user.email=this.$route.query.email
        this.user.steamID=this.$route.query.steamID
    },
    methods: {
        completeRegistration(){
            userService.completeRegistration(this.user,this.token).then(data=>{
                this.registration_success=true;
            }).catch(err => {
                this.registration_failed=true;
            });
        },
        goToLogin(){
            this.$router.push('/login')
        }
    }
};
</script>
<style scoped>
</style>