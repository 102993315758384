eset<template>
    <div class="global-item" >         

        <div class="center-item" >         
                <form>
                    <img src="/img/ATK_logo.png" alt="atk logo" class="mx-auto d-block w-50">
                        <div class="row pb-2">
                           <div class="col-md-12 mx-auto text-center">
                                <h6>Cambio mail</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="text-danger " v-show="change_failed"><i class="fa fa-times" aria-hidden="true"> </i> Cambio mail fallito, la mail può essere cambiata una sola volta usando lo stesso link. Riprova il cambiamento o contatta un amministratore.</p>
                                <p class="text-success " v-show="change_success"><i class="fa fa-check" aria-hidden="true"> </i> La mail è stata cambiata correttamente, effettua il logout ed un nuovo login(usando la nuova mail) per vedere i cambiamenti.</p>
                            </div>
                        </div>
                        <div class="row">
                                <div class="col-md-12">
                                    <button type="button" v-if="change_success || change_failed"   class="btn btn-info btn-fill float-right" @click="goToLogin">
                                        Vai alla login page
                                    </button>
                                </div>
                        </div>
                </form>
        </div>
    </div>

</template>

<script>
import { userService } from '@/_services';

export default {
    data () {
        return {
            change_failed: false,
            change_success: false
        }
    },

    computed: {
    },
    created () {
        console.log("GETTING TOKEN FROM URL")
        console.log(this.$route.query.token)
        this.token=this.$route.query.token
        this.completeChange()
    },
    methods: {
        completeChange(){
            userService.completeChangeEmail(this.token).then(data=>{
                this.change_success=true;
            }).catch(err => {
                this.change_failed=true;
            });
        },
        goToLogin(){
            this.$router.push('/login')
        }
    }
};
</script>
<style scoped>
</style>