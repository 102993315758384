<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications></notifications>
    <router-view></router-view>
    <cookie-law theme="dark-lime" buttonText='Accetta informativa'>
      <div slot="message">
        Questo sito utilizza solo cookie tecnici "Necessari" al suo funzionamento basilare del sito e cookie di terze parti.<br>
        Non sono utilizzati cookie di profilazione nè proprietari nè di terze parti. <a   target="_blank" href="/cookies">Leggi informativa cookie completa</a>
      </div>
    </cookie-law>
  </div>
    
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import CookieLaw from 'vue-cookie-law'

  global.jQuery = require('jquery');
  var $ = global.jQuery;
  window.$ = $;

  export default {
    name: 'app',
    components: { CookieLaw },
    computed: {
        ...mapState({
            alert: state => state.alert
        })
    },
    methods: {
        ...mapActions({
            clearAlert: 'alert/clear' 
        })
    },
    watch: {
        $route (to, from){
            // clear alert on location change
            this.clearAlert();
        }
    } 
};
  /*export default {}*/
</script>
<style lang="scss">
  .vue-notifyjs.notifications{
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .list-item {
      display: inline-block;
      margin-right: 10px;

    }
    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }
    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }
    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }
</style>
