<template>
  <ul class="nav nav-mobile-menu">
 <!--   <base-dropdown v-if="expiration_notified">
      <template slot="title">
        <i class="nc-icon nc-send"></i>
        <b class="caret"></b>
        <span class="notification">1 Notifica</span>
      </template>
      <a class="dropdown-item" href="#"><small>ATK-Pro in scadenza<br> il <strong>{{pro_member_until}}</strong>.<br>Per rinnovare clicca <a href="#" @click="goToUpgrade()">qui</a></small></a>
    </base-dropdown>-->
    <!--<li class="nav-item">
      <a href="#" class="nav-link">
        <i class="nc-icon nc-zoom-split hidden-lg-up"></i>
        <span class="d-lg-none">Search</span>
      </a>
    </li>
    <!--<base-dropdown title="Dropdown">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something</a>
      <a class="dropdown-item" href="#">Something else here</a>
      <div class="divider"></div>
      <a class="dropdown-item" href="#">Separated link</a>
    </base-dropdown>-->

    <li class="nav-item">
      <a class="nav-link" href="#">
        {{loggedin_user.email}}
      </a>
    </li>
    <li class="nav-item">
      <a class="my-0 w-50 ml-5"
          :class="loggedin_user.scope==='Admin' ? 'btn btn-danger btn-fill': 'btn btn-success btn-fill'" href="#">
          {{loggedin_user.scope}}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" @click="logOut">
        <span class="no-icon">Log out</span>
      </a>
    </li>
  </ul>
</template>
<script>
  import { userService } from '@/_services';
  import { paymentService } from '@/_services';


  export default {
    name: 'mobile-menu',
    data () {
      return {
        activeNotifications: false,
        loggedin_user: {
          id: '',
          scope: '',
          email: ''
        }
      }
    },
    created(){
      var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
      this.loggedin_user.id = curr_user.id
      if (curr_user.scopes.includes("Admin"))
        this.loggedin_user.scope="Admin"
      else
        this.loggedin_user.scope="Driver"
      this.loggedin_user.email = curr_user.sub  

    },
    methods:{
      goToUpgrade(){
        console.log("QUI")
        this.$router.push('/admin/upgrade')
      },
      logOut(){
        userService.logout();
        this.$router.push('/login');
      }
    }
  }
</script>
<style>
</style>
