<template>
    <div class="global-item" >         

        <div class="center-item" >         
                <form @submit.prevent="handleSubmit" >
                    <img src="/img/ATK_logo.png" alt="atk logo" class="mx-auto d-block w-50">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" @keydown="keymonitor" v-model="email" name="email" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && !email }" />
                        <div v-show="submitted && !email" class="invalid-feedback-login">Email non può essere vuoto</div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password <i :class="is_show_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showPassword"></i></label>
                        <input :type="is_show_password ? 'text' : 'password'" @keydown="keymonitor" v-model="password" name="password" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && !password }" />
                        <div v-show="submitted && !password" class="invalid-feedback-login">La password non può essere vuota</div>
                    </div>
                    <div v-show="auth_failed && password && email" class="invalid-feedback-login">Errore di autenticazione, email o password errata</div>

                    <div class="form-group">
                        <button class="btn btn-info btn-fill" :disabled="status.loggingIn">Login</button>
                        <img v-show="status.loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        <p class="mt-4">Non sei ancora registrato? <a href="#"  @click="showModalRegistration = true"> Iscriviti</a></p>
                        <p class="mt-4">Hai dimenticato la password? <a href="#"  @click="showModalResetPassword = true"> Richiedi un cambio password.</a></p>
                    </div>
                </form>
                <div id="login">
                <transition name="fade" appear>
                    <div class="modal-overlay" v-if="showModalRegistration" @click="closePopup"></div>
                </transition>
                    <transition name="slide" appear>
                    <form class="modal-popup" v-if="showModalRegistration" @submit.prevent="startRegistration">
                        <div class="row pb-2">
                           <div class="col-md-11 my-auto">
                                <h6 >Registra una nuova utenza</h6>
                            </div>
                            <div class="col-md-1">
                                <i class="fa fa-times" @click="closePopup" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="user.email">Email</label>
                                <input @keydown="keymonitorRegister" type="email" v-model="user.email" name="user.email" class="form-control form-control-lg" 
                                :class="{ 'is-invalid': submitted_register && !user.email }" />
                                <div v-if="submitted_register && !user.email" class="invalid-feedback-login">Email non può essere vuoto</div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="user.steamID">SteamID<i @click="openSteamIDPage()" class="fa fa-info-circle pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i></label>
                                <input @keydown="keymonitorRegister" pattern="^[0-9]{17}$" 
                                title="Lo steamID64 è un numero identificativo del tuo account Steam. Per sapere come trovarlo nel tuo account Steam clicca sulla 'i'"
                                type="text" v-model="user.steamID" name="user.steamID" class="form-control form-control-lg" 
                                :class="{ 'is-invalid': submitted_register && !user.steamID }" />
                                <div v-if="submitted_register && !user.steamID" class="invalid-feedback-login">SteamID non può essere vuoto</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="user.first_name">Nome</label>
                                <input @keydown="keymonitorRegister" type="text" v-model="user.first_name" name="user.first_name" class="form-control form-control-lg" 
                                :class="{ 'is-invalid': submitted_register && !user.first_name }" />
                                <div v-if="submitted_register && !user.first_name" class="invalid-feedback-login">Nome non può essere vuoto</div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="user.last_name">Cognome</label>
                                <input @keydown="keymonitorRegister" type="text" v-model="user.last_name" name="user.last_name" class="form-control form-control-lg" 
                                :class="{ 'is-invalid': submitted_register && !user.last_name }" />
                                <div v-if="submitted_register && !user.last_name" class="invalid-feedback-login">Cognome non può essere vuoto</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <base-checkbox label="Dichiaro di aver letto e compreso il "  
                                
                                v-model="rules_read"> 
                                <a href="/regolamento" target="_blank"> regolamento</a>.
                                </base-checkbox> 
                                <div v-if="submitted_register && !rules_read" class="invalid-feedback-login">Devi leggere ed accettare il regolamento per registrarti.</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 my-auto">
                                <p class="text-success" v-show="start_registration_success"><i class="fa fa-check" @click="closePopup" aria-hidden="true"> </i> Riceverà una mail in pochi secondi per confermare la creazione</p>
                                <p class="text-danger" v-show="start_registration_failed"><i class="fa fa-times" @click="closePopup" aria-hidden="true"> </i> Errore, riprovi o contatti un amministratore</p>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group text-center">
                                    <button type="submit" :disabled="start_registration_success" class="btn btn-info btn-fill float-right">
                                    Crea utente
                                    </button>
                                </div>
                        </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </transition>



                <transition name="fade" appear>
                    <div class="modal-overlay" v-if="showModalResetPassword" @click="closePopupPwd"></div>
                </transition>
                    <transition name="slide" appear>
                    <form class="modal-popup" v-if="showModalResetPassword" @submit.prevent="startResetPassword">
                        <div class="row pb-2">
                           <div class="col-md-11 my-auto">
                                <h6 >Richiedi un cambio password</h6>
                            </div>
                            <div class="col-md-1">
                                <i class="fa fa-times" @click="closePopupPwd" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label for="user.email">Email</label>
                                <input @keydown="keymonitorRegister" type="email" v-model="user.email" name="user.email" class="form-control form-control-lg" 
                                :class="{ 'is-invalid': submitted_resetpwd && !user.email }" />
                                <div v-if="submitted_resetpwd && !user.email" class="invalid-feedback-login">Email non può essere vuoto</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 my-auto">
                                <p class="text-success" v-show="start_reset_password_success"><i class="fa fa-check" @click="closePopupPwd" aria-hidden="true"> </i> Riceverà una mail in pochi secondi per confermare il reset password</p>
                                <p class="text-danger" v-show="start_reset_password_failed"><i class="fa fa-times" @click="closePopupPwd" aria-hidden="true"> </i> Errore, riprovi o contatti un amministratore</p>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group text-center">
                                    <button type="submit" :disabled="start_reset_password_success" class="btn btn-info btn-fill float-right">
                                    Cambia password
                                    </button>
                                </div>
                        </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </transition>
                </div>
        </div>
    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { userService } from '@/_services';

export default {
    data () {
        return {
            email: '',
            password: '',
            submitted: false,
            auth_failed: false,
            start_registration_failed: false,
            start_registration_success: false,
            start_reset_password_success: false,
            start_reset_password_failed: false,
            submitted_register: false,
            submitted_resetpwd: false,
            showModalRegistration: false,
            showModalResetPassword: false,
            registration_started: false,
            registration_error: false,
            is_show_password: false,
            rules_read:false,
            user: {
                email: '',
                first_name: '',
                last_name: '',
                steamID: '',
                password:''
            }
        }
    },
    computed: {
        ...mapState('account', ['status'])
    },
    created () {
        // reset login status
        this.logout();
    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        handleSubmit (e) {
            this.submitted = true;
            const { email, password } = this;
            if (email && password) {
                this.login({ email, password }).then(error=>{
                if(!this.$store.getters.getAccount.user)
                {
                    console.log("auth failed")
                    this.auth_failed=true;
                }
                });
            }
        },
        openSteamIDPage(){
            window.open("https://support.ubisoft.com/en-GB/Faqs/000027522/Finding-your-Steam-ID?fallback=en_GB&isSso=true&refreshStatus=noLoginData", '_blank');  
        },
        closePopup(){
            this.showModalRegistration=false;
            this.submitted_register = false;
            this.start_registration_success = false;
            this.start_registration_failed = false;
            this.user.steamID= ''
            this.user.email= ''
            this.user.first_name= ''
            this.user.last_name= ''
        },
        closePopupPwd(){
            this.showModalResetPassword=false;
            this.submitted_resetpwd = false;
            this.start_reset_password_success= false;
            this.start_reset_password_failed = false;
            this.user.email= ''
        },
        keymonitor(){
            this.auth_failed=false;
        },
        keymonitorRegister(){
            this.submitted_register=false;
            this.submitted_resetpwd=false;
        },
        startRegistration(){
            this.submitted_register = true;
            if(this.user.email && this.user.steamID && this.user.first_name && this.user.last_name && this.rules_read)
                userService.startRegistration(this.user).then(
                    data=>{
                        this.start_registration_success=true
                    }
                ).catch(err => 
                    this.start_registration_failed=true
                );
        },
        startResetPassword(){
            this.submitted_resetpwd = true;
            if(this.user.email )
                userService.startResetPassword(this.user).then(
                    data=>{
                        this.start_reset_password_success=true
                    }
                ).catch(err => 
                    this.start_reset_password_failed=true
                );
        },
        showPassword(){
            this.is_show_password = !this.is_show_password;
        }
    }
};
</script>
<style scoped>

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.popup {
 box-shadow: 1px 0 10px 15px rgba(0, 0, 0, .3);
}

.modal-overlay {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.5);
}
.modal-popup {
 position: fixed;
 z-index: 99;
 background-color: #FFF;
 border-radius: 4px;
 transform: translate(-50%, -50%);
 top: 50%;
 left: 50%;
 padding: 25px;

}

.fade-enter-active,
.fade-leave-active {
 transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
 transform: translateY(-50%) translateX(100vw);
}
</style>