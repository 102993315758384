/*import config from 'config';*/
import auth from '../_helpers/auth-header';
import { userService } from '@/_services';


var backendURL=process.env.VUE_APP_BE_URL

export const paymentService = {
    paypalCaptureOrder
};

function paypalCaptureOrder(order_id,event_id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_,
    };

    return fetch(`${backendURL}/payment/${order_id}?event_id=${event_id}`, requestOptions).then(handleResponse);
};


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //if update --> 304 ("Not modified") is not a problem
        if (!response.ok ) {
            if (response.status === 401 ) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            if(response.status !== 304){
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        }
        //console.log(data);
        return data;
    });

}
