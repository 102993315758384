<template>
    <div class="content">
        <card>
            <h4 slot="header" class="card-title">Dettagli evento</h4>
            <transition-expand>
            <div class="row justify-content-center mb-2">
            <img
                v-bind:src="event_image_URL" 
                alt="..."
                @error="onImgError()"
                class="img-fluid" style="min-height:219px; -o-object-fit: contain; object-fit: contain;"/>
            
            </div>
            </transition-expand>
            <div class="row">
                <h4 v-if="event.cost!=0 & event.cost!=666" slot="header" class="card-title m-2">Costo di iscrizione {{event.cost}} €</h4>
                <h4 v-if="event.cost==0  & event.cost!=666" slot="header" class="card-title m-2">Iscrizione gratuita</h4>
            </div>
            <div class="row">
                <div class="col-md-4">
                <base-input type="text"
                            label="Nome evento"
                            readonly
                            placeholder="Nome evento"
                            v-model="event.event_name">
                </base-input>
                </div>
                <div class="col-md-4">
                <base-input
                            label="Max Drivers" type="number" placeholder=""
                            :min="1" :max="99" inline center controls size="small"
                            readonly
                            v-model="event.max_drivers">
                </base-input>
                </div>
                <div class="col-md-4">
                <base-input type="text"
                            label="Discord dedicato"
                            readonly
                            placeholder="Discord dedicato"
                            v-model="event.event_discord">
                </base-input>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-md-6">
                <div class="form-group">
                    <label for="sel2">Simulatore</label>
                    <select disabled=true class="form-control" v-model="event.game" id="sel2">
                        <option selected>AC</option>
                        <option >ACC</option>
                        <option>RF2</option>
                        <option>AMS2</option>
                    </select>
                    </div>
                </div>
            </div>-->

            <div class="row justify-content-center text-center" v-show="event.subscriber_ids.indexOf(loggedin_user.id)>-1" style="margin-bottom:14px;">
                    <div v-if="event.event_track_mod_url" class="col-md-4">
                        <a  class="btn btn-info btn-fill" v-bind:href="event.event_track_mod_url" target="_blank">Download Mod tracciato</a>
                    </div>
                    <div v-if="event.event_car_mod_url" class="col-md-4">
                        <a  class="btn btn-info btn-fill" v-bind:href="event.event_car_mod_url" target="_blank">Download Mod macchina</a>
                    </div>
                    <div v-if="event.event_app_mod_url" class="col-md-4">
                        <a  class="btn btn-info btn-fill" v-bind:href="event.event_app_mod_url" target="_blank">Download Mod App</a>
                    </div>
            </div>
            <div class="alert alert-danger alert-with-icon" data-notify="container" v-show="event.subscriber_ids.indexOf(loggedin_user.id)<0">
                <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                <span data-notify="message">Le mod di auto, piste ed applicazioni necessarie per partecipare saranno scaricabili da qui in seguito ad una corretta iscrizione.
                </span>
            </div>


            <!--PRATICA-->
            <card v-if="isPraticaActive">
                <div class="row">
                    <div class="col-md-6">
                            <label class="control-label font-weight-bold" style="display: inline-block;">Pratica</label>
                    </div>
                </div>
                <transition-expand>
                <div v-if="isPraticaActive">
                <div class="row">

                    <div class="col-md-6">
                    <base-input
                                label="Durata" type="number" placeholder=""
                                readonly
                                :min="1" :max="400" inline center controls size="small"
                                v-model="session_practice.duration">
                    </base-input>
                    </div>
                    <div v-if="practiceStartDate" class="col-md-6">
                    <base-input
                        label="Ora di inizio sessione" readonly type="text"
                        v-model="practiceStartDate">
                    </base-input>
                    </div>
                </div>


                </div>
                </transition-expand>
            </card>
            <!--QUALIFICA-->
            <card v-if="isQualifyActive">
                <div class="row">
                    <div class="col-md-6">
                            <label class="control-label font-weight-bold" style="display: inline-block;">Qualifica</label>
                    </div>
                </div>
                <transition-expand>
                <div v-if="isQualifyActive">
                <div class="row">
                    <div class="col-md-6">
                    <base-input
                                label="Durata" type="number" placeholder=""
                                readonly
                                :min="1" :max="400" inline center controls size="small"
                                v-model="session_qualify.duration">
                    </base-input>
                    </div>
                    <div v-if="qualifyStartDate" class="col-md-6">
                    <base-input
                        label="Ora di inizio sessione" readonly type="text"
                        v-model="qualifyStartDate">
                    </base-input>                    
                    </div>
                </div>
                </div>
                </transition-expand>
            </card>

            <!--Gara1-->
            <card v-if="isRaceOneActive">
                <div class="row">
                    <div class="col-md-6">
                            <label class="control-label font-weight-bold" style="display: inline-block;">Gara 1</label>
                    </div>
                </div>
                <transition-expand>
                <div v-if="isRaceOneActive">
                <div class="row">
                    <div class="col-md-6">
                    <base-input
                                label="Durata" type="number" placeholder=""
                                readonly
                                :min="1" :max="400" inline center controls size="small"
                                v-model="session_raceOne.duration">
                    </base-input>
                    </div>
                    <div v-if="raceOneStartDate" class="col-md-6">
                    <base-input
                        label="Ora di inizio sessione" readonly type="text"
                        v-model="raceOneStartDate">
                    </base-input>                    
                    </div>
                </div>
                </div>
                </transition-expand>
            </card>
            <!--Gara2-->
            <card v-if="isRaceTwoActive">
                <div class="row">
                    <div class="col-md-6">
                            <label class="control-label font-weight-bold" style="display: inline-block;">Gara 2</label>
                    </div>
                </div>
                <transition-expand>
                <div v-if="isRaceTwoActive">
                <div class="row">
                    <div class="col-md-6">
                    <base-input
                                label="Durata" type="number" placeholder=""
                                readonly
                                :min="1" :max="400" inline center controls size="small"
                                v-model="session_raceTwo.duration">
                    </base-input>
                    </div>
                    <div v-if="raceTwoStartDate" class="col-md-6">
                    <base-input
                        label="Ora di inizio sessione" readonly type="text"
                        v-model="raceTwoStartDate">
                    </base-input>                    </div>
                </div>
                </div>
                </transition-expand>
            </card>
            <div class="row">
                <div class="col-md-12">
                <div class="form-group">
                    <label>Dettagli evento</label>
                    <textarea :rows="event.event_details_rows" class="form-control border-input"
                            placeholder="Dettagli evento non presenti"
                            readonly
                            v-model="event.event_details">
                    </textarea>
                </div>
                </div>
            </div>
            <div  class="text-center">

                <!--<button v-if='championship_id' class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-times"/> Non puoi iscriverti ad un evento singolo di un campionato</button>
                -->
                <button v-if='!event.champ_id && 
                                event.subscriber_ids.indexOf(loggedin_user.id)<0 && 
                                event.max_drivers-event.subscriber_ids.length>0 && 
                                !isOneSesionLive() &&
                                !noSessions() &&
                                !isEventEnded() &&
                                event.subscription_open' 
                                class="btn btn-success btn-fill float-right" @click="this.subscribe">
                    <i class="fa fa-plus"/> Iscriviti</button>

                <button v-if='!event.champ_id && 
                                event.subscriber_ids.indexOf(loggedin_user.id)<0 && 
                                event.max_drivers-event.subscriber_ids.length>0 && 
                                !isOneSesionLive() &&
                                !noSessions() &&
                                !isEventEnded() &&
                                !event.subscription_open' 
                                class="btn btn-danger btn-fill float-right" >
                    <i class="fa fa-times"/> Iscrizioni chiuse</button>

                <button v-if='!event.champ_id && 
                                event.subscriber_ids.indexOf(loggedin_user.id)>-1 && 
                                !isOneSesionLive() &&
                                !noSessions() &&
                                !isEventEnded() &&
                                event.cost==0' 
                                class="btn btn-danger btn-fill float-right" @click="this.unsubscribe">
                    <i class="fa fa-eraser"/> Cancella iscrizione</button>
                <button v-if='!event.champ_id && 
                                event.subscriber_ids.indexOf(loggedin_user.id)>-1 && 
                                !isOneSesionLive() &&
                                !noSessions() &&
                                !isEventEnded() &&
                                event.cost>0' 
                                class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-eraser"/> Non puoi cancellare l'iscrizione da un evento a pagamento</button>
                <button v-if='!event.champ_id && isOneSesionLive()' class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-times"/> Non puoi modificare l'iscrizione di un evento Live</button>
                <button v-if='!event.champ_id && 
                                event.max_drivers-event.subscriber_ids.length===0 && 
                                !isOneSesionLive() &&
                                !noSessions() &&
                                !isEventEnded() &&
                                event.subscriber_ids.indexOf(loggedin_user.id)<0' 
                                class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-close"/> Non ci sono posti disponibili</button>

                <button v-if='!event.champ_id && isEventEnded()' class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-times"/> Non puoi modificare l'iscrizione di un evento terminato.</button>
                <button v-if='!event.champ_id && noSessions() && !isEventEnded()' class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-times"/> Non puoi modificare l'iscrizione di un evento senza sessioni.</button>
            </div>
            <div class="clearfix"></div>
        </card>
          <card v-if='!event.champ_id' class="card-plain">
            <template slot="header">
              <h4 class="card-title">Utenti iscritti</h4>
              <p class="card-category">in ordine di iscrizione</p>
            </template>
            <div class="tableScrollableFixHeader" >
                <table class="table table-striped table-bordered ">
                    <thead >
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')">SteamId</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')">Rimuovi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in this.subscriberDetails" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{user['first_name']}} {{user['last_name']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">{{user['steamID']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">
                                <a @click="unsubscribe_user(user)" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                Disiscrivi
                              </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </card>
    </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue';
import Toggle from 'src/components/Toggle.vue';
import TransitionExpand from 'src/components/TransitionExpand.vue';
import { eventService } from '@/_services';
import moment from 'moment';


export default {
    components: {
      Card,
      Toggle,
      TransitionExpand
    },
    data () {
        return {
        event: {
          session_ids: [],
          subscriber_ids: [],
          sessions:[],
          event_name: '',
          max_drivers: 0,
          event_car_mod_url: '',
          event_track_mod_url: '',
          event_app_mod_url: '',
          event_discord:'',

          id : '',
          cost: 0,
          event_details: ``,
          event_details_rows: 1,
          subscription_open: true,
          champ_id: '',
          game:''
        },
        session_practice: {
          sessionType: 'Practice',
          //#0 not started, 1 ongoing/live, 2 ended, -1 not present on db
          status: -1,
          start_schedule: '',
          server_url: '',
          duration: '',
          password: '',
          session_result_id: ''
        },
        session_qualify: {
          sessionType: 'Qualify',
          //#0 not started, 1 ongoing/live, 2 ended, -1 not present on db
          status: -1,
          start_schedule: '',
          server_url: '',
          duration: '',
          password: '',
          session_result_id: ''
        },
        session_raceOne: {
          sessionType: 'Race',
          //#0 not started, 1 ongoing/live, 2 ended, -1 not present on db
          status: -1,
          start_schedule: '',
          server_url: '',
          duration: '',
          password: '',
          session_result_id: ''
        },
        session_raceTwo: {
          sessionType: 'Race2',
          //#0 not started, 1 ongoing/live, 2 ended, -1 not present on db
          status: -1,
          start_schedule: '',
          server_url: '',
          duration: '',
          password: '',
          session_result_id: ''
        },        
        selectedFile: null,
        event_image_URL: "/img/event_default.jpg",
        isPraticaActive: false,
        isQualifyActive: false,
        isRaceOneActive: false,
        isRaceTwoActive: false,
        practiceStartDate: null,
        qualifyStartDate: null,
        raceOneStartDate: null,
        raceTwoStartDate: null,
        loggedin_user: {
            id: "",
            scopes: []
            },
        subscriberDetails: [],
        }
    },
    created(){
        console.log("GETTING VALUES FROM URL")
        console.log(this.$route.query.event_id)
        this.event_id=this.$route.query.event_id
        eventService.getEventWithSessions(this.event_id).then(json=>{
            var json_event = json
            if(json_event){
                //var json_event = JSON.parse(this.$route.params.event)
                this.event.event_name =json_event.event_name
                this.event.session_ids = json_event.session_ids
                this.event.subscriber_ids = json_event.subscriber_ids
                this.event.max_drivers = json_event.max_drivers
                this.event.event_car_mod_url = json_event.event_car_mod_url
                this.event.event_details = json_event.event_details
                this.event.game = json_event.game
                this.event.cost = json_event.cost
                this.event.event_track_mod_url = json_event.event_track_mod_url
                this.event.event_app_mod_url = json_event.event_app_mod_url
                this.event.champ_id = json_event.champ_id
                this.event.sessions = json_event.sessions
                this.event.event_discord = json_event.event_discord

                this.event.subscription_open = json_event.subscription_open
                this.event.id = json_event.id_

                //if(json_event.championship_id)
                //    this.championship_id = json_event.championship_id
                
                this.getSubscriberDetails()


                if(this.event.event_details)
                    this.event.event_details_rows = this.event.event_details.split(/\r\n|\r|\n/).length
                

                if(this.event.id){
                    if(!this.event.champ_id)
                        this.event_image_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.event.id+"_event_image"
                    else
                        //this.event_image_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.championship_id+"_championship_image"
                        this.event_image_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.event.id+"_champ_event_image"
                }
                this.event.sessions
                var arrayLength = this.event.sessions.length;
                for (var i = 0; i < arrayLength; i++) {
                    if(this.event.sessions[i].sessionType==="Practice"){
                        this.isPraticaActive=true
                        if(json_event.sessions[i].start_schedule)
                            this.session_practice.start_schedule=json_event.sessions[i].start_schedule
                            this.session_practice.status=json_event.sessions[i].status
                            this.session_practice.duration=json_event.sessions[i].duration
                            this.practiceStartDate=this.fromUnixToReadable(this.session_practice.start_schedule)
                    }
                    else if(json_event.sessions[i].sessionType==="Qualify"){
                        this.isQualifyActive=true
                        if(json_event.sessions[i].start_schedule)
                            this.session_qualify.start_schedule=json_event.sessions[i].start_schedule
                            this.session_qualify.status=json_event.sessions[i].status
                            this.session_qualify.duration=json_event.sessions[i].duration
                            this.qualifyStartDate=this.fromUnixToReadable(this.session_qualify.start_schedule)
                    }
                    else if(json_event.sessions[i].sessionType==="Race"){
                        this.isRaceOneActive=true
                        if(json_event.sessions[i].start_schedule)
                            this.session_raceOne.start_schedule=json_event.sessions[i].start_schedule
                            this.session_raceOne.status=json_event.sessions[i].status
                            this.session_raceOne.duration=json_event.sessions[i].duration
                            this.raceOneStartDate=this.fromUnixToReadable(this.session_raceOne.start_schedule)
                    }
                    else if(json_event.sessions[i].sessionType==="Race2"){
                        this.isRaceTwoActive=true
                        if(json_event.sessions[i].start_schedule){
                            this.session_raceTwo.start_schedule=json_event.sessions[i].start_schedule
                            this.session_raceTwo.status=json_event.sessions[i].status
                            this.session_raceTwo.duration=json_event.sessions[i].duration
                            this.raceTwoStartDate=this.fromUnixToReadable(this.session_raceTwo.start_schedule)
                        }
                    }
            }

        }
            
        }).catch(err => 
            this.$router.push({name: 'Not Found', })
        );

        this.setCurrentUserInfo()


        

    },
    methods: {
        onImgError(){
            console.log("ERROR")
            this.event_image_URL = "/img/event_default.jpg"
        },
        toUnix(dateString) {
            return moment(dateString, 'YYYY-MM-DDTHH:mm').unix()
        },
        fromUnixToReadable(unix){ 
            return moment.unix(unix).format('DD-MM-YYYY   HH:mm')
        },
        setCurrentUserInfo(){
            var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
            this.loggedin_user.id = curr_user.id
            this.loggedin_user.scopes = curr_user.scopes
        },
        getSubscriberDetails(){
            console.log(this.event)
            eventService.getSubscriberDetails(this.event.id).then(json=>{
                JSON.parse(JSON.stringify(json))
                this.subscriberDetails = json
            })
        },
        subscribe(){
            var confirmString = ""
            if(this.event.cost>0)
                confirmString = ", procedi al pagamento"
            this.$confirm(
            {
                message: `Vuoi confermare l'iscrizione all'evento?`,
                button: {
                no: 'No',
                yes: 'Si' + confirmString
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    if(this.event.cost<=0){
                        eventService.subscribe(this.event.id).then(
                            this.$confirm(
                            {
                                title: 'Operazione completata',
                                message: "Iscrizione all'evento effettuata correttamente.",
                                button: {
                                    yes: 'Ho capito',
                                },
                                callback: confirm => {
                                    if (confirm) {
                                        this.$router.push("/admin/event-list")
                                    }
                                }
                            })
                        ).
                        catch(err => 
                            this.$confirm(
                                {
                                    title: 'Errore',
                                    message: "Iscrizione all'evento fallita. Riprova e se il problema persiste contatta un amministratore.",
                                    button: {
                                        yes: 'Ho capito',
                                    },
                                    callback: confirm => {
                                        if (confirm) {
                                            this.$router.push("/admin/event-list")
                                    }
                                }
                                }
                            )
                        );
                    }
                    //else manda a pagina di pagamento
                    else
                    this.goToPaymentEvent()
                }




                }
            }
            )
        },
        goToPaymentEvent(){
            /*this.$router.push({
                name: 'Payment Event', 
                params: { type:"event", id: this.event.id }
            });*/
            this.$router.push({ path: 'payment-event', query: { type:"event", id: this.event.id }})
        },
        unsubscribe(){
            this.$confirm(
            {
                message: `Vuoi confermare cancellare l'iscrizione all'evento?`,
                button: {
                no: 'No',
                yes: 'Si'
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    eventService.unsubscribe(this.event.id).then(
                        this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "Cancellazione all'evento effettuata correttamente.",
                            button: {
                                yes: 'Ho capito',
                            }
                        })
                    ).
                    catch(err => 
                        this.$confirm(
                            {
                                title: 'Errore',
                                message: "Cancellazione all'evento fallita. Riprovi o contatti un amministratore.",
                                button: {
                                    yes: 'Ho capito',
                                }
                            }
                        )
                    );


                    this.$router.push("/admin/event-list")
                }
                }
            }
            )
        },
        unsubscribe_user(user){
            this.$confirm(
            {
                message: `Vuoi confermare cancellare l'iscrizione di `+ user['first_name']+' ' + user['last_name'] +` dall'evento?`,
                button: {
                no: 'No',
                yes: 'Si'
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    eventService.unsubscribe_user(this.event.id,user['id_']).then(
                        this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "Cancellazione all'evento effettuata correttamente.",
                            button: {
                                yes: 'Ho capito',
                            }
                        })
                    ).
                    catch(err => 
                        this.$confirm(
                            {
                                title: 'Errore',
                                message: "Cancellazione all'evento fallita. Riprovi o contatti un amministratore.",
                                button: {
                                    yes: 'Ho capito',
                                }
                            }
                        )
                    );


                    this.$router.push("/admin/event-list")
                }
                }
            }
            )
        },
        isOneSesionLive(){
            if(this.session_practice.status===1 || this.session_qualify.status===1 || this.session_raceOne.status===1 || this.session_raceTwo.status===1){
                return true;
            }
            return false;
        },
        isEventEnded(){
            if((this.session_practice.status===2 || this.session_practice.status===-1) && 
                (this.session_qualify.status===2 || this.session_qualify.status===-1) && 
                (this.session_raceOne.status===2 || this.session_raceOne.status===-1) && 
                (this.session_raceTwo.status===2 || this.session_raceTwo.status===-1)){
                return true;
            }
            return false;
        },
        noSessions(){
            if(this.event.session_ids.length ===0){
                return true;
            }
            return false;
        }
    }
};
</script>
<style>

</style>
