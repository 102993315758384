import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'
import LoginPage from '../pages/login.vue'
import LandingPage from '../pages/LP.vue'



// Admin pages
//import Overview from 'src/pages/Overview.vue'
import CompleteRegistration from 'src/pages/UserProfile/CompleteRegistration.vue'
import CompletePasswordReset from 'src/pages/UserProfile/CompletePasswordReset.vue'
import CompleteChangeMail from 'src/pages/UserProfile/CompleteChangeMail.vue'


import UserProfile from 'src/pages/UserProfile.vue'
import Users from 'src/pages/Users.vue'
import TableList from 'src/pages/TableList.vue'
import EventList from 'src/pages/EventList.vue'
import ChampionshipList from 'src/pages/ChampionshipList.vue'

import EventDetails from 'src/pages/EventDetails.vue'
import EventResult from 'src/pages/EventResult.vue'
import EventLive from 'src/pages/EventLive.vue'
import ChampionshipDetails from 'src/pages/ChampionshipDetails.vue'
import ChampionshipLeaderboard from 'src/pages/ChampionshipLeaderboard.vue'
import FAQ from 'src/pages/FAQ.vue'
import Privacy from 'src/pages/Privacy.vue'
import Cookies from 'src/pages/Cookies.vue'


import PaymentEvent from 'src/pages/PaymentEvent.vue'


import Typography from 'src/pages/Typography.vue'
import Icons from 'src/pages/Icons.vue'
import Maps from 'src/pages/Maps.vue'
import Notifications from 'src/pages/Notifications.vue'

Vue.use(Router);

// configure router
export const router = new Router({
  mode: 'history',
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/home'
  },
  { 
    path: '/login', 
    component: LoginPage 
  },
  { 
    path: '/home', 
    component: LandingPage 
  },
  { 
    path: '/privacy', 
    component: Privacy 
  },
  { 
    path: '/cookies', 
    component: Cookies 
  },
  { 
    path: '/complete_registration', 
    component: CompleteRegistration 
  },
  { 
    path: '/complete_password_reset', 
    component: CompletePasswordReset 
  },
  { 
    path: '/complete_change_email', 
    component: CompleteChangeMail 
  },
  {
    path: '/regolamento',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/user',
    children: [
/*      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },*/
      {
        path: 'user',
        name: 'User',
        component: UserProfile
      },
      {
        path: 'users-page',
        name: 'Users',
        component: Users
      },
      {
        path: 'table-list',
        name: 'Table List',
        component: TableList
      },
      {
        path: 'event-list',
        name: 'Event List',
        component: EventList
      },
      {
        path: 'championship-list',
        name: 'Championship List',
        component: ChampionshipList
      },
      {
        path: 'event-details',
        name: 'Event Details',
        component: EventDetails
      },
      {
        path: 'event-result',
        name: 'Event Result',
        component: EventResult
      },
      {
        path: 'championship-leaderboard',
        name: 'Championship Leaderboard',
        component: ChampionshipLeaderboard
      },
      {
        path: 'event-live',
        name: 'Event Live',
        component: EventLive
      },
      {
        path: 'championship-details',
        name: 'Championship Details',
        component: ChampionshipDetails
      },
      {
        path: 'FAQ',
        name: 'FAQ',
        component: FAQ
      },
      {
        path: 'typography',
        name: 'Typography',
        component: Typography
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: 'maps',
        name: 'Maps',
        component: Maps
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'payment-event',
        name: 'Payment Event',
        component: PaymentEvent
      }
    ]
  },
  { path: '*', name: 'Not Found',component: NotFound }
]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/home', '/privacy','/regolamento','/cookies','/complete_registration','/complete_password_reset','/complete_change_email','/index'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    console.log('back to login');
    return next('/login');
  }

  next();
})
