<template>
  <card>
    <h4 v-if="event_json_string_for_update === ''" slot="header" class="card-title">Aggiungi tappa ad un campionato</h4>
    <h4 v-if="event_json_string_for_update !==''" slot="header" class="card-title">Modifica tappa ad un campionato</h4>

    <transition-expand>
      <div class="row justify-content-center mb-2" v-if="event_image_browser_URL">
      <img
          v-bind:src="this.event_image_browser_URL" 
          alt="..."
          @error="onImgError()"
          class="img-fluid" style="min-height:219px; -o-object-fit: contain; object-fit: contain;"/>
      </div>
    </transition-expand>
    <form>

      <div class="row">
          <div class="col-md-6" v-if="!this.event_json_string_for_update">
          <div class="form-group">
              <label for="sel2">Seleziona il campionato</label>
              <select class="form-control" @change="onChampionshipSelectionChange()" v-model="selected_championship" id="sel1">
                <option v-bind:value="champ" v-for="(champ,index) in championship_list" 
                v-bind:key="index">{{champ.championship_name}}</option>
              </select>
            </div>
          </div>

          <!--<div class="col-md-3">
          <div class="form-group">
              <label for="sel2">Simulatore</label>
              <select disabled=true class="form-control" v-model="event.game" id="sel2">
                  <option selected>AC</option>
                  <option >ACC</option>
                  <option>RF2</option>
                  <option>AMS2</option>
              </select>
              </div>
          </div>-->
      </div>

      <div class="row">
        <div class="col-md-6">
          <label  for="event.event_name">Nome tappa di campionato</label>
          <input type="text" placeholder="Nome tappa" v-model="event.event_name" name="event.event_name" class="form-control" 
          :class="{ 'is-invalid': !event.event_name }" />
          <div v-show="!event.event_name" class="invalid-feedback-login">Nome tappa non può essere vuoto</div>
        </div>


        <div class="col-md-6">
          <base-input
                    label="Max Drivers" type="number" placeholder="1" disabled=true 
                    :min="1" :max="99" inline center controls size="small"
                    v-model="event.max_drivers">
          </base-input>
        </div>
      </div>

      <a> (1080x566)</a>
      <div class="row">
        <div class="col-md-6">
            <input type="file" name="file_champ" id="file_champ" class="customInputFile" @change="onEventChampFileSelected"> 
            <label class="btn btn-info btn-fill float-center" style="padding: 10px 14px;" for="file_champ">Seleziona file copertina</label>
            <a v-if="this.selectedFile"> {{this.selectedFile.name}}</a>
        </div>

      </div>

      <div class="row">
            <div class="col-md-4">
            <base-input type="text"
                        label="URL Mod tracciato (link esterno)"
                        placeholder="URL Mod tracciato"
                        v-model="event.event_track_mod_url">
            </base-input>
            </div>
            <div class="col-md-4">
            <base-input type="text"
                        label="URL Mod macchina (link esterno)"
                        placeholder="URL Mod macchina"
                        v-model="event.event_car_mod_url">
            </base-input>
            </div>
            <div class="col-md-4">
            <base-input type="text"
                        label="URL App (link esterno)"
                        placeholder="URL App"
                        v-model="event.event_app_mod_url">
            </base-input>
            </div>
      </div>


      <!--PRATICA-->
      <card>
        <div class="row">
          <div class="col-md-6">
            <toggle :value=isPraticaActive_prop @onToggle="onTogglePractice" label="Pratica"/>
          </div>
        </div>
        <transition-expand>
        <div v-if="isPraticaActive_prop">
          <div class="row">
            <div class="col-md-6">
              <base-input type="text"
                          label="URL Server (AC emperor server, senza slash finale)"
                          placeholder="URL Server"
                          v-model="session_practice.server_url">
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input
                        label="Durata" type="number" placeholder="1"
                        :min="1" :max="400" inline center controls size="small"
                        v-model="session_practice.duration">
              </base-input>
            </div>
          </div>
          <div class="row">
          <div class="col-md-4">
            <base-input
                      label="Server (-1 per server singolo)" type="number" placeholder="-1"
                      :min="-1" :max="20" inline center controls size="small"
                      v-model="session_practice.server_num">
            </base-input>
          </div>
              <div class="col-md-4">
                <base-input
                          label="HTTP port" type="number" 
                          :min="1" :max="99999" inline center controls size="small"
                          v-model="session_practice.http_port">
                </base-input>
              </div>
            <div class="col-md-4 d-flex align-items-center">
              <input type="datetime-local" label="test" v-model="practiceStartDate" />
            </div>
          </div>


        </div>
        </transition-expand>
      </card>
      <!--QUALIFICA-->
      <card>
        <div class="row">
          <div class="col-md-6">
            <toggle :value=isQualifyActive_prop @onToggle="onToggleQualify" label="Qualifica"/>
          </div>
        </div>
        <transition-expand>
        <div v-if="isQualifyActive_prop">
          <div class="row">
            <div class="col-md-6">
              <base-input type="text"
                          label="URL Server (AC emperor server, senza slash finale)"
                          placeholder="URL Server"
                          v-model="session_qualify.server_url">
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input
                        label="Durata" type="number" placeholder="1"
                        :min="1" :max="400" inline center controls size="small"
                        v-model="session_qualify.duration">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
            <base-input
                      label="Server (-1 per server singolo)" type="number" placeholder="-1"
                      :min="-1" :max="20" inline center controls size="small"
                      v-model="session_qualify.server_num">
            </base-input>
          </div>
              <div class="col-md-4">
                <base-input
                          label="HTTP port" type="number" 
                          :min="1" :max="99999" inline center controls size="small"
                          v-model="session_qualify.http_port">
                </base-input>
              </div>
            <div class="col-md-4 d-flex align-items-center">
              <input type="datetime-local" label="test" v-model="qualifyStartDate" />
            </div>
          </div>
        </div>
        </transition-expand>
      </card>

      <!--Gara1-->
      <card>
        <div class="row">
          <div class="col-md-6">
            <toggle :value=isRaceOneActive_prop @onToggle="onToggleRaceOne" label="Gara 1"/>
          </div>
        </div>
        <transition-expand>
        <div v-if="isRaceOneActive_prop">
          <div class="row">
            <div class="col-md-6">
              <base-input type="text"
                          label="URL Server (AC emperor server, senza slash finale)"
                          placeholder="URL Server"
                          v-model="session_raceOne.server_url">
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input
                        label="Durata" type="number" placeholder="1"
                        :min="1" :max="400" inline center controls size="small"
                        v-model="session_raceOne.duration">
              </base-input>
            </div>
          </div>
          <div class="row">
          <div class="col-md-6">
            <base-input
                      label="Server (-1 per server singolo)" type="number" placeholder="-1"
                      :min="-1" :max="20" inline center controls size="small"
                      v-model="session_raceOne.server_num">
            </base-input>
          </div>
              <div class="col-md-4">
                <base-input
                          label="HTTP port" type="number" 
                          :min="1" :max="99999" inline center controls size="small"
                          v-model="session_raceOne.http_port">
                </base-input>
              </div>
            <div class="col-md-6 d-flex align-items-center">
              <input type="datetime-local" label="test" v-model="raceOneStartDate" />
            </div>
          </div>
        </div>
        </transition-expand>
      </card>
      <!--Gara2-->
      <card>
        <div class="row">
          <div class="col-md-6">
            <toggle :value=isRaceTwoActive_prop @onToggle="onToggleRaceTwo" label="Gara 2"/>
          </div>
        </div>
        <transition-expand>
        <div v-if="isRaceTwoActive_prop">
          <div class="row">
            <div class="col-md-6">
              <base-input type="text"
                          label="URL Server (AC emperor server, senza slash finale)"
                          placeholder="URL Server"
                          v-model="session_raceTwo.server_url">
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input
                        label="Durata" type="number" placeholder="1"
                        :min="1" :max="400" inline center controls size="small"
                        v-model="session_raceTwo.duration">
              </base-input>
            </div>
          </div>
          <div class="row">
          <div class="col-md-6">
            <base-input
                      label="Server (-1 per server singolo)" type="number" placeholder="-1"
                      :min="-1" :max="20" inline center controls size="small"
                      v-model="session_raceTwo.server_num">
            </base-input>
          </div>
              <div class="col-md-4">
                <base-input
                          label="HTTP port" type="number" 
                          :min="1" :max="99999" inline center controls size="small"
                          v-model="session_raceTwo.http_port">
                </base-input>
              </div>
            <div class="col-md-6 d-flex align-items-center">
              <input type="datetime-local" label="test" v-model="raceTwoStartDate" />
            </div>
          </div>
        </div>
        </transition-expand>
      </card>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Dettagli evento</label>
            <textarea rows="5" class="form-control border-input"
                      placeholder=
"Temperatura: 19 gradi
Meteo: variabile
Regole: bla,bla,bla
puoi premere invio per andare a capo"
                      v-model="event.event_details">
              </textarea>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button v-if="event_json_string_for_update === '' && selected_championship !== null" type="submit" class="btn btn-info btn-fill float-right" @click.prevent="createEvent">
          Crea tappa
        </button>
        <label v-if="selected_championship === null && !this.event_json_string_for_update" class="btn btn-danger btn-fill float-right">
          Nessun campionato selezionato
        </label>
        <button v-if="event_json_string_for_update !== '' " type="submit" class="btn btn-info btn-fill float-right" @click.prevent="updateEvent">
          Modifica tappa
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue';
  import Toggle from 'src/components/Toggle.vue';
  import TransitionExpand from 'src/components/TransitionExpand.vue';

  import auth from '@/_helpers/auth-header';
  import { userService } from '@/_services';
  import { eventService } from '@/_services';
  import { championshipService } from '@/_services';


  import { bus } from '@/main';
  import moment from 'moment';


  var backendURL=process.env.VUE_APP_BE_URL

  export default {
    components: {
      Card,
      Toggle,
      TransitionExpand
    },
    props: {
      event_json_string_for_update: {
        type: String
      }
    },
    watch: { 
      event_json_string_for_update: 
        function() { // watch it
          this.init()
        }
      },
    data(){
        return this.initCreate();
    },
    created() {
      this.init()
    },
    methods: {
      initCreate(){
          return {
            event: {
              session_ids: [],
              subscriber_ids: [],
              event_name: '',
              max_drivers: 0,
              event_car_mod_url: '',
              event_track_mod_url: '',
              event_app_mod_url: '',

              event_details: ``,
              event_type: 'Championship',
              champ_id:'',
              game: 'AC',
              //cost cannot be null otherwise someone could subscribe without payment
              cost: 666,
              id: ''
            },
            session_practice: {
              sessionType: 'Practice',
              //#0 not started, 1 ongoing/live, 2 ended
              status: 0,
              start_schedule: '',
              server_url: '',
              server_num: -1,
              duration: 10,
              password: '',
              http_port:'',
              session_result_id: ''
            },
            session_qualify: {
              sessionType: 'Qualify',
              //#0 not started, 1 ongoing/live, 2 ended
              status: 0,
              start_schedule: '',
              server_url: '',
              server_num: -1,
              duration: 10,
              password: '',
              http_port:'',
              session_result_id: ''
            },
            session_raceOne: {
              sessionType: 'Race',
              //#0 not started, 1 ongoing/live, 2 ended
              status: 0,
              start_schedule: '',
              server_url: '',
              server_num: -1,
              duration: 10,
              password: '',
              http_port:'',
              session_result_id: ''
            },
            session_raceTwo: {
              sessionType: 'Race2',
              //#0 not started, 1 ongoing/live, 2 ended
              status: 0,
              start_schedule: '',
              server_url: '',
              server_num: -1,
              duration: 10,
              password: '',
              http_port:'',
              session_result_id: ''
            },        
            selectedFile: null,
            event_image_browser_URL: "/img/event_default.jpg",
            isPraticaActive_prop: false,
            isQualifyActive_prop: false,
            isRaceOneActive_prop: false,
            isRaceTwoActive_prop: false,
            practiceStartDate: this.currentDate(),
            qualifyStartDate: this.currentDate(),
            raceOneStartDate: this.currentDate(),
            raceTwoStartDate: this.currentDate(),
            championship_list: [],
            selected_championship: null
          }
      },
      init(){
        this.getChampionships()
        //If is in update mode --> event_json_string_for_update passed as props
        if(this.event_json_string_for_update){
          console.log("UPDATE MODE " +this.event_json_string_for_update)
          var json_event = JSON.parse(this.event_json_string_for_update)

          //this.event_image_browser_URL = process.env.VUE_APP_AWS_BUCKET_URL+json_event.championship_id+"_championship_image"

          this.event.event_name =json_event.event_name
          this.event.session_ids = json_event.session_ids
          this.event.subscriber_ids = json_event.subscriber_ids
          this.event.max_drivers = json_event.max_drivers
          this.event.event_car_mod_url = json_event.event_car_mod_url
          this.event.event_details = json_event.event_details
          this.event.champ_id = json_event.champ_id
          this.event.game = json_event.game
          //cost cannot be null otherwise someone could subscribe without payment
          this.event.cost = 666

          this.event.event_track_mod_url = json_event.event_track_mod_url
          this.event.event_app_mod_url = json_event.event_app_mod_url

          this.event.id = json_event.id_

          if(this.event.id){
              this.event_image_browser_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.event.id+"_champ_event_image"
          }

          var arrayLength = json_event.sessions.length;
          for (var i = 0; i < arrayLength; i++) {
              if(json_event.sessions[i].sessionType==="Practice"){
                  this.isPraticaActive_prop=true
                  if(json_event.sessions[i].start_schedule)
                      this.session_practice.start_schedule=json_event.sessions[i].start_schedule
                      this.session_practice.status=json_event.sessions[i].status
                      this.session_practice.server_url=json_event.sessions[i].server_url
                      this.session_practice.server_num=json_event.sessions[i].server_num
                      this.session_practice.duration=json_event.sessions[i].duration
                      this.session_practice.http_port=json_event.sessions[i].http_port
                      this.practiceStartDate=this.fromUnix(this.session_practice.start_schedule)
              }
              else if(json_event.sessions[i].sessionType==="Qualify"){
                  this.isQualifyActive_prop=true
                  if(json_event.sessions[i].start_schedule)
                      this.session_qualify.start_schedule=json_event.sessions[i].start_schedule
                      this.session_qualify.status=json_event.sessions[i].status
                      this.session_qualify.server_url=json_event.sessions[i].server_url
                      this.session_qualify.server_num=json_event.sessions[i].server_num
                      this.session_qualify.duration=json_event.sessions[i].duration
                      this.session_qualify.http_port=json_event.sessions[i].http_port
                      this.qualifyStartDate=this.fromUnix(this.session_qualify.start_schedule)
              }
              else if(json_event.sessions[i].sessionType==="Race"){
                  this.isRaceOneActive_prop=true
                  if(json_event.sessions[i].start_schedule)
                      this.session_raceOne.start_schedule=json_event.sessions[i].start_schedule
                      this.session_raceOne.status=json_event.sessions[i].status
                      this.session_raceOne.server_url=json_event.sessions[i].server_url
                      this.session_raceOne.server_num=json_event.sessions[i].server_num
                      this.session_raceOne.duration=json_event.sessions[i].duration
                      this.session_raceOne.http_port=json_event.sessions[i].http_port
                      this.raceOneStartDate=this.fromUnix(this.session_raceOne.start_schedule)
              }
              else if(json_event.sessions[i].sessionType==="Race2"){
                  this.isRaceTwoActive_prop=true
                  if(json_event.sessions[i].start_schedule){
                      this.session_raceTwo.start_schedule=json_event.sessions[i].start_schedule
                      this.session_raceTwo.status=json_event.sessions[i].status
                      this.session_raceTwo.server_url=json_event.sessions[i].server_url
                      this.session_raceTwo.server_num=json_event.sessions[i].server_num
                      this.session_raceTwo.duration=json_event.sessions[i].duration
                      this.session_raceTwo.http_port=json_event.sessions[i].http_port
                      this.raceTwoStartDate=this.fromUnix(this.session_raceTwo.start_schedule)
                  }
              }

          }
        }
        //It's a create reinit empty variables
        else{
            Object.assign(this.$data, this.initCreate());
        }
      },
      getChampionships(){
          championshipService.getChampionships().then(json=>{
              console.log("Campionati: "+json)
              this.championship_list = json
          })
      },
      onEventChampFileSelected(event){
        console.log(event)
        console.log("Updating IMG")
        var currFile = event.target.files[0]
        if (currFile.size > 1024 * 1024 * 5) {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato è troppo grande, la dimensione massima per la preview evento è di 5 MB.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        if (currFile['type']!=='image/jpeg' && currFile['type']!=='image/png') {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato deve essere un file jpeg o un file png.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        console.log("Updating IMG")
        this.selectedFile = event.target.files[0]
        this.event_image_browser_URL = URL.createObjectURL(this.selectedFile)
      },
      isPraticaActive(){
          this.isPraticaActive_prop = true;
      },
      onTogglePractice(){
        this.isPraticaActive_prop=!this.isPraticaActive_prop
      },
      removeItemAll(arr, value) {
        var i = 0;
        while (i < arr.length) {
          if (arr[i] === value) {
            arr.splice(i, 1);
          } else {
            ++i;
          }
        }
        return arr;
      },
      onImgError(){
        this.event_image_browser_URL = "/img/event_default.jpg"
      },
      onToggleQualify(){
        this.isQualifyActive_prop=!this.isQualifyActive_prop
      },
      onToggleRaceOne(){
        this.isRaceOneActive_prop=!this.isRaceOneActive_prop
      },
      onToggleRaceTwo(){
        this.isRaceTwoActive_prop=!this.isRaceTwoActive_prop
      },
      toUnix(dateString) {
        return moment(dateString, 'YYYY-MM-DDTHH:mm').unix()
      },
      fromUnix(unix){ 
        return moment.unix(unix).format('YYYY-MM-DDTHH:mm')
      },
      currentDate(){
        return moment().format('YYYY-MM-DDTHH:mm')
      },
      onChampionshipSelectionChange(event){
          console.log(this.selected_championship)
          this.event.max_drivers = this.selected_championship.max_drivers 
          //this.event_image_browser_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.selected_championship.id_+"_championship_image"
      },
      createEvent(){
        /*
        0 Confirmation popup
        1 Upload IMG
        2 Create Event (with IMG URL)
        3 Add sessions to Event */
        this.$confirm(
          {
            title: 'Crea tappa',
            message: 'Vuoi creare la nuava tappa per il campionato "'+ this.selected_championship.championship_name +'"?',
            button: {
              no: 'No',
              yes: 'Si'
            },
            /**
            * Callback Function
            * @param {Boolean} confirm 
            */
            callback: confirm => {
              if (confirm) {
                this.event.subscriber_ids = this.selected_championship.subscriber_ids
                this.event.champ_id=this.selected_championship.id_
                console.log(this.event.champ_id)
                championshipService.addEventToChampionship(this.selected_championship.id_,this.event).then(json=>{
                    var event_ID = json['id_'];
                    if(this.selectedFile)
                      eventService.uploadEventImage(event_ID+"_champ",this.selectedFile)
                    if(event_ID){
                      if(this.isPraticaActive_prop){
                        this.session_practice.start_schedule=this.toUnix(this.practiceStartDate)
                        eventService.registerSession(this.session_practice).then(json=>{
                          if(json['id_']){
                            eventService.addSessionToEvent(event_ID,json['id_'])
                          }
                        });
                      }
                      new Promise(resolve => setTimeout(resolve, 1000));
                      if(this.isQualifyActive_prop){
                        this.session_qualify.start_schedule=this.toUnix(this.qualifyStartDate)
                        eventService.registerSession(this.session_qualify).then(json=>{
                          if(json['id_']){
                            eventService.addSessionToEvent(event_ID,json['id_'])
                          }
                        });
                      }
                      new Promise(resolve => setTimeout(resolve, 1000));
                      if(this.isRaceOneActive_prop){
                        this.session_raceOne.start_schedule=this.toUnix(this.raceOneStartDate)
                        eventService.registerSession(this.session_raceOne).then(json=>{
                          if(json['id_']){
                            eventService.addSessionToEvent(event_ID,json['id_'])
                          }
                        });
                      }
                      new Promise(resolve => setTimeout(resolve, 1000));
                      if(this.isRaceTwoActive_prop){
                        this.session_raceTwo.start_schedule=this.toUnix(this.raceTwoStartDate)
                        eventService.registerSession(this.session_raceTwo).then(json=>{
                          if(json['id_']){
                            eventService.addSessionToEvent(event_ID,json['id_'])
                          }
                        });
                      }
                        this.$confirm(
                          {
                            title: 'Operazione completata',
                            message: "La tappa di campionato è stata aggiunta correttamente.",
                            button: {
                              yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                          }
                        )
                    }
                }
                ).catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "La tappa di campionato non è stata creata correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                        }
                    )
                );
              }
            }
          }
        )
    },
      updateEvent(){
        //TODO Alla fine della operazione manda alla pagina precedente
        this.$confirm(
          {
            message: `Confermi di voler modificare l'evento e tutte le sessioni contenute?`,
            button: {
              no: 'No',
              yes: 'Si'
            },
            /**
            * Callback Function
            * @param {Boolean} confirm 
            */
            callback: confirm => {
              if (confirm) {
                var json_previous_event = JSON.parse(this.event_json_string_for_update)


                var event_ID = this.event.id;
                if(this.selectedFile)
                  eventService.uploadEventImage(event_ID+"_champ",this.selectedFile)

                var old_practice_id
                var old_qualify_id
                var old_raceOne_id
                var old_raceTwo_id
                for(var i = 0; i < json_previous_event["sessions"].length; i++) {
                  var curr_session = json_previous_event["sessions"][i];
                  console.log(curr_session)

                  if(curr_session["sessionType"]==="Practice")
                    old_practice_id=curr_session["id_"]
                  if(curr_session["sessionType"]==="Qualify")
                    old_qualify_id=curr_session["id_"]
                  if(curr_session["sessionType"]==="Race")
                    old_raceOne_id=curr_session["id_"]
                  if(curr_session["sessionType"]==="Race2")
                    old_raceTwo_id=curr_session["id_"]
                }
                console.log("OLD PRACTICE "+old_practice_id)
                console.log("OLD QUAL "+old_qualify_id)
                console.log("OLD RACE "+old_raceOne_id)
                console.log("OLD RACE2 "+old_raceTwo_id)

                //if practice active & there was a practice already
                if(this.isPraticaActive_prop && old_practice_id){
                  this.session_practice.start_schedule=this.toUnix(this.practiceStartDate)
                  //reset status when changing a session, can be tht the start date has been changed
                  this.session_practice.status=0
                  eventService.updateSession(old_practice_id,this.session_practice)
                }
                else if(this.isPraticaActive_prop && !old_practice_id){
                  this.session_practice.start_schedule=this.toUnix(this.practiceStartDate)
                  eventService.registerSession(this.session_practice).then(json=>{
                        if(json['id_']){
                          eventService.addSessionToEvent(this.event.id,json['id_'])
                        }
                      });
                }
                else if(!this.isPraticaActive_prop && old_practice_id){
                  eventService.deleteSession(old_practice_id)
                  this.event.session_ids = this.removeItemAll(this.event.session_ids, old_practice_id)
                }

                new Promise(resolve => setTimeout(resolve, 1000));
                if(this.isQualifyActive_prop && old_qualify_id){
                  this.session_qualify.start_schedule=this.toUnix(this.qualifyStartDate)
                  //reset status when changing a session, can be tht the start date has been changed
                  this.session_qualify.status=0
                  eventService.updateSession(old_qualify_id,this.session_qualify)
                }
                else if(this.isQualifyActive_prop && !old_qualify_id){
                  this.session_qualify.start_schedule=this.toUnix(this.qualifyStartDate)
                  eventService.registerSession(this.session_qualify).then(json=>{
                        if(json['id_']){
                          eventService.addSessionToEvent(this.event.id,json['id_'])
                        }
                      });
                }
                else if(!this.isQualifyActive_prop && old_qualify_id){
                  eventService.deleteSession(old_qualify_id)
                  this.event.session_ids = this.removeItemAll(this.event.session_ids, old_qualify_id)
                }

                new Promise(resolve => setTimeout(resolve, 1000));
                if(this.isRaceOneActive_prop && old_raceOne_id){
                  this.session_raceOne.start_schedule=this.toUnix(this.raceOneStartDate)
                  //reset status when changing a session, can be tht the start date has been changed
                  this.session_raceOne.status=0
                  eventService.updateSession(old_raceOne_id,this.session_raceOne)
                }
                else if(this.isRaceOneActive_prop && !old_raceOne_id){
                  this.session_raceOne.start_schedule=this.toUnix(this.raceOneStartDate)
                  eventService.registerSession(this.session_raceOne).then(json=>{
                        if(json['id_']){
                          eventService.addSessionToEvent(this.event.id,json['id_'])
                        }
                      });
                }
                else if(!this.isRaceOneActive_prop && old_raceOne_id){
                  eventService.deleteSession(old_raceOne_id)
                  this.event.session_ids = this.removeItemAll(this.event.session_ids, old_raceOne_id)
                }


                new Promise(resolve => setTimeout(resolve, 1000));
                if(this.isRaceTwoActive_prop && old_raceTwo_id){
                  console.log("UPDATE")
                  this.session_raceTwo.start_schedule=this.toUnix(this.raceTwoStartDate)
                  //reset status when changing a session, can be tht the start date has been changed
                  this.session_raceTwo.status=0
                  eventService.updateSession(old_raceTwo_id,this.session_raceTwo)
                }
                else if(this.isRaceTwoActive_prop && !old_raceTwo_id){
                  console.log("REGISTER")
                  this.session_raceTwo.start_schedule=this.toUnix(this.raceTwoStartDate)
                  eventService.registerSession(this.session_raceTwo).then(json=>{
                        if(json['id_']){
                          eventService.addSessionToEvent(this.event.id,json['id_'])
                        }
                      });
                }
                else if(!this.isRaceTwoActive_prop && old_raceTwo_id){
                  eventService.deleteSession(old_raceTwo_id)
                  this.event.session_ids = this.removeItemAll(this.event.session_ids, old_raceTwo_id)
                }
                //TODO 304 not modified on first call prevent other calls to be executed, done.....to be tested
                eventService.updateEvent(this.event.id,this.event).then().catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "L'evento non è stato modificato correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },
                            callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/event-list')
                                }
                            }
                        }
                    )
                )


                this.$confirm(
                  {
                    title: 'Operazione completata',
                    message: "L'evento è stato modificato correttamente.",
                    button: {
                      yes: 'Ho capito',
                    },
                    callback: confirm => {
                      if (confirm) {
                          this.$router.go('/admin/event-list')
                      }
                      }
                  }
                )
              }
            }
          }
        )
    }
  }
  }
</script>
<style>

</style>
