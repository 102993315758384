<template>
    <div class="content">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-12" style="position:relative;">
                              <img
                                  v-bind:src="championship_img_url"
                                  alt="..."
                                  class="img-fluid" 
                                  @error="onImgError()"
                                  style=" min-width:100%;"/>
                                <img v-if="new_championship"
                                    src="/img/new_ribbon.png"
                                    alt="..."
                                    class="img-fluid new-image" 
                                    @error="onImgError()"
                                    />
                            </div>
                        </div>
                            <div class="row ">
                                <div class="col-xs-6 mx-auto">
                                    <div class="card-body h-100 align-items-center d-flex py-2" >
                                      <div class="btn-group align-bottom mx-auto"> 
                                        <!--<h5 class="card-title font-weight-bold mb-4 w-100">{{event_name}}</h5>-->
                                        <div v-bind:class="{'btn btn-success btn-fill': (max_drivers-subscriber_ids.length)>3,  
                                          'btn btn-warning btn-fill': (max_drivers-subscriber_ids.length)<4,  
                                          'btn btn-danger btn-fill': (max_drivers-subscriber_ids.length)===0}"
                                          style="min-width:80px;">
                                            <i class="fa fa-users"></i>
                                            <small >   {{subscriber_ids.length}}/{{max_drivers}}</small>
                                        </div>
                                        <div class="btn btn-neutral btn-fill border" @click="openLeaderboardPage"
                                          style="min-width:80px;">
                                            <i class="fa fa-flag-checkered mr-2"></i>
                                            <small>Class.</small>
                                        </div>
                                        <div class="btn btn-success btn-fill border" @click="this.goToDetails"
                                          style="min-width:80px;">
                                            <i class="fa fa-sign-in mr-2"></i>
                                            <small>Dettagli</small>
                                        </div>
                                      </div>
                                    </div>
                                </div>

                                <div class="col-xs-5 mx-auto " v-show="!view_mode && loggedin_user.scopes.includes('Admin')">
                                    <div class="card-body  h-100 align-items-center d-flex py-2" >
                                        <div class="btn-group align-bottom mx-auto"> 
                                            <button href="#"  class="btn btn-info btn-fill" @click="this.showParentManageForm"><i class="fa fa-pencil-square-o"></i></button>
                                            <button href="#" class="btn btn-fill btn-danger" @click="this.deleteChampionship"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</template>
<script>
import { championshipService } from '@/_services';
import moment from 'moment';


export default {
    name: 'championship',
    data(){
      return {
        championship_img_url: "/img/event_default.jpg",
        new_championship : false,
        loggedin_user: {
            id: '',
            scopes: [],
            email: ''
        }
      }
    },
    props: {
      championship_json_string: {
        type: String
      },
      view_mode:{
        type: Boolean,
        default: () => false
      },
      event_ids: {
        type: Array,
        default: () => []
      },
      subscriber_ids: {
        type: Array,
        default: () => []
 /*       validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1}*/
      },
      championship_name: {
        type: String
      },
      max_drivers: {
        type: Number,
      },
      id: {
        type: String,
        default: ""
      }
    },
    created(){
      if(this.id){
        this.championship_img_url = process.env.VUE_APP_AWS_BUCKET_URL+this.id+"_championship_image"
      }
      var championship_json = JSON.parse(this.championship_json_string)
      var curr_time = moment().format("X")
      var created_on = championship_json.created_on
      console.log(championship_json)
      console.log(curr_time)
      console.log(created_on)

      //1 day is 86400 seconds, here we consider an event new if it has been created in the last 2 days
      this.new_championship = curr_time-created_on < 86400*2

      var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
      this.loggedin_user.id = curr_user.id
      this.loggedin_user.email = curr_user.sub
      this.loggedin_user.scopes = curr_user.scopes
    },
    methods: {
      openLeaderboardPage(){
        console.log("Open leaderboard")
        var championship_json = JSON.parse(this.championship_json_string)
        let routeData = this.$router.resolve({name: 'Championship Leaderboard', query: {championship_id: championship_json["id_"]}});
        window.open(routeData.href, '_blank');
      },
      goToDetails(){
        var championship_json = JSON.parse(this.championship_json_string)
        this.$router.push({name: 'Championship Details', query: {championship_id: championship_json["id_"]}});

        //this.$router.push({
        //    name: 'Championship Details', 
        //    params: { championship: this.championship_json_string }
        //});
      },
      onImgError(){
        this.championship_img_url = "/img/event_default.jpg"
      },
      showParentManageForm(){
        console.log("EMIT "+this.championship_json_string)
        this.$emit('showManageOnParent', this.championship_json_string)
      },
      deleteChampionship(){
        this.$confirm(
          {
            title: 'Cancella campionato',
            message: "Sei sicuro di voler cancellare il campionato selezionato e tutti i relativi eventi e sessioni?",
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                  championshipService.deleteChampionshipEventsAndSessions(this.id).then(data=>{
                       this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "Il campionato è stato cancellato correttamente.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                        }
                    )
                  }).
                  catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Il campionato non è stato cancellato correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                        }
                    )
                );
              }
              }
          }
        )
      }
    }
}
</script>
<style>
</style>