<template>
    <div class="content">
            <card>
                <h4 class="card-title my-auto pb-2">Risultati campionato "{{leaderboard_json['championship_name']}}"</h4>
                <div style="overflow-x: auto">
                <table class="table table-striped table-bordered " >
                    <thead >
                        <tr>
                            <th>Rank</th>
                            <th>Nome pilota</th>
                            <th>Punti tot.</th>
                            <th v-for="(sess_id, index) in leaderboard_json['all_sessions']" :key="index">Gara {{index+1}}
                                <i @click="openEventResultPage(sess_id)" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                            </th>
                            <!--<th>Penalità Tot.</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(curr_user, index) in users" :key="index">
                            <td>{{curr_user["ranking"]}}</td>
                            <td>{{curr_user["driver_name"]}}</td>
                            <td>{{curr_user["total_score"]}}</td>
                            <td v-for="(sess_id, index) in leaderboard_json['all_sessions']" :key="index">
                                <!--<span class="font-weight-bold">Punti: </span>-->
                                <div class="d-inline-block">{{getScoreForSession(curr_user,sess_id)+getScoreForSessionBestLap(curr_user,sess_id)+getScoreForPoleMan(curr_user,sess_id)}}

                                <!--<span class="font-weight-bold">Penalità: </span>{{getPenaltyForSession(curr_user,sess_id)}}-->
                                
                                
                                <small v-if="getScoreForSessionBestLap(curr_user,sess_id)>0" class="my-0 py-0 px-1  btn-success btn-fill text-white">
                                    Best lap</small>
                                <small v-if="getScoreForPoleMan(curr_user,sess_id)>0" class="my-0 py-0 px-1  btn-warning btn-fill text-white">
                                    Pole</small>
                                </div>
                            </td>         
                            <!--<td>{{curr_user["total_penalty"]}}</td>-->
                        </tr>
                    </tbody>
                </table>
                </div>
            </card>

    </div>
</template>
<script>
import { championshipService } from '@/_services';
import { eventService } from '@/_services';


export default {
    data () {
        return {
            loggedin_user: {
                id: '',
                scopes: [],
                email: ''
            },
            championship_id: '',
            leaderboard_json: '',
            users: []
        }
    },
    created(){
        console.log("ChampionshipLeaderboard CREATED")

        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.loggedin_user.id = curr_user.id
        this.loggedin_user.email = curr_user.sub
        this.loggedin_user.scopes = curr_user.scopes

        console.log("GETTING VALUES FROM URL")
        console.log(this.$route.query.championship_id)
        this.championship_id=this.$route.query.championship_id

        championshipService.getChampionshipLeaderboard(this.championship_id).then(json=>{
            this.leaderboard_json = json
            var ranking = 1
            for(var curr_steamID in json["rank"]){
                console.log(curr_steamID)
                for(var json_element in json){
                    if(json_element===curr_steamID){
                    json[json_element]["ranking"]=ranking
                    this.users.push(json[json_element])
                    }
                }
                ranking=ranking+1
            }

        })
        this.computeRank()
    },
    methods: {
        openEventResultPage(session_id){
            eventService.getEventBySessionId(session_id).then(data=>{
                console.log("Open result "+data)
                let routeData = this.$router.resolve({name: 'Event Result', query: {event_id: data}});
                window.open(routeData.href, '_blank');
            })
        },
        computeRank(){
            if(this.leaderboard_json){

            }
        },
        getScoreForSession(driver,sess_id){
            var score = 0
            for(var race_result in driver["results"]){
                if(driver["results"][race_result]["session_id"]===sess_id)
                    score = score + driver["results"][race_result]["score"] 
            }
            return score
        },
        getScoreForSessionBestLap(driver,sess_id){
            var score = 0
            for(var race_result in driver["results"]){
                if(driver["results"][race_result]["session_id"]===sess_id)
                    score = score + driver["results"][race_result]["score_bestlap"] 
            }
            return score
        },
        getScoreForPoleMan(driver,sess_id){
            var score = 0
            for(var race_result in driver["results"]){
                if(driver["results"][race_result]["session_id"]===sess_id)
                    score = score + driver["results"][race_result]["score_poleman"] 
            }
            return score
        },
        getPenaltyForSession(driver,sess_id){
            var penalty = 0
            for(var race_result in driver["results"]){
                if(driver["results"][race_result]["session_id"]===sess_id)
                    penalty = penalty+ driver["results"][race_result]["penalty"]
            }
            return penalty
        }
    }
};
</script>
