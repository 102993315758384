eset<template>
    <div class="global-item" >         

        <div class="center-item" >         
                <form>
                    <img src="/img/ATK_logo.png" alt="atk logo" class="mx-auto d-block w-50">
                        <div class="row pb-2">
                           <div class="col-md-11 my-auto">
                                <h6>Cambia la tua password</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                            <base-input type="email"
                                        label="Email"
                                        placeholder="Email"
                                        disabled=true
                                        v-model="user.email">
                            </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="password">Password <i :class="is_show_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showPassword"></i></label>
                                <base-input :type="is_show_password ? 'text' : 'password'"
                                            name="password"
                                            :disabled="reset_success || reset_failed"
                                            placeholder="inserisci una password"
                                            v-model="user.password">
                                </base-input>
                                </div>
                                <div class="form-group col-md-6">
                                <label for="password_check">Password <i :class="is_show_check_password ? 'fa fa-eye-slash': 'fa fa-eye'" aria-hidden="true" @click="showCheckPassword"></i></label>
                                <base-input :type="is_show_check_password ? 'text' : 'password'"
                                            name="password_check"
                                            :disabled="reset_success || reset_failed"
                                            placeholder="Conferma la password"
                                            v-model="password_confirmation">
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <small v-show="!has_minimum_lenth" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 8 caratteri.</small>
                                <p></p>
                                <small v-show="!has_uppercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 maiuscola.</small>
                                <p></p>
                                <small v-show="!has_lowercase" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 minuscola.</small>
                                <p></p>
                                <small v-show="!has_number" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve contenere almeno 1 numero.</small>
                                <p></p>
                            </div>
                            <div class="col-md-6">
                                <small v-show="!is_the_same_pwd" class="text-danger text-align-left"><i class="fa fa-times" aria-hidden="true"> </i> Deve coincidere alla password.</small>
                                <p></p>
                            </div>
                        </div>
 <!--                       <div class="row">
                            <div class="col-md-12">
                                <small>La Password deve contenere almeno: 8 caratteri, una minuscola, una maiuscola ed un numero.</small>
                            </div>
                        </div>-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-center">
                                    <button type="button" 
                                    :disabled="reset_success || reset_failed || !has_lowercase || !has_uppercase ||
                                              !has_minimum_lenth || !has_number || !is_the_same_pwd"
                                    class="btn btn-info btn-fill float-right" @click="completeReset">
                                    Cambia password
                                    </button>
                                </div>
                            </div>
                                <div class="col-md-6">
                                    <button type="button" v-if="reset_success || reset_failed"   class="btn btn-info btn-fill float-right" @click="goToLogin">
                                        Vai alla login page
                                    </button>
                                </div>

                        </div>
                        <p class="text-danger mt-4" v-show="reset_failed"><i class="fa fa-times" aria-hidden="true"> </i> Reset password fallito, il link è scaduto. Deve tornare alla login page ed effettuare una nuova richiesta.</p>
                        <p class="text-success mt-4" v-show="reset_success"><i class="fa fa-check" aria-hidden="true"> </i> Reset password completato, vai alla login page per accedere.</p>
                </form>

        </div>
    </div>

</template>

<script>
import { userService } from '@/_services';

export default {
    data () {
        return {
            has_minimum_lenth: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,

            reset_failed: false,
            reset_success: false,
            password_confirmation: '',
            is_the_same_pwd: false,
            is_show_password: false,
            is_show_check_password: false,
            user: {
                email: '',
                password: ''
            }
        }
    },
    watch:{
        password(){
            this.has_minimum_lenth = (this.user.password.length >= 8);
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            },
        password_confirmation(){
            this.is_the_same_pwd = this.user.password == this.password_confirmation;
            this.has_number    = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            }
        },
    computed: {
        password() {
            return this.user.password;
        }
    },
    created () {
        console.log("GETTING TOKEN FROM URL")
        console.log(this.$route.query.token)
        console.log(this.$route.query.email)
        
        this.token=this.$route.query.token
        this.user.email=this.$route.query.email
    },
    methods: {
        completeReset(){
            userService.completeResetPassword(this.user,this.token).then(data=>{
                this.reset_success=true;
            }).catch(err => {
                this.reset_failed=true;
            });
        },
        goToLogin(){
            this.$router.push('/login')
        },
        showPassword(){
            this.is_show_password = !this.is_show_password;
        },
        showCheckPassword(){
            this.is_show_check_password = !this.is_show_check_password;
        }
    }
};
</script>
<style scoped>
</style>