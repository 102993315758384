/*import config from 'config';*/
import auth from '../_helpers/auth-header';
import { userService } from '@/_services';



var backendURL= process.env.VUE_APP_BE_URL


export const championshipService = {
    registerChampionship,
    uploadChampionshipImage,
    getChampionshipsWithEventsSessions,
    getPublicChampionshipsWithEventsSessions,
    getChampionships,
    getChampionshipLeaderboard,
    updateChampionship,
    addEventToChampionship,
    deleteChampionshipEventsAndSessions,
    deleteEventFromChampionship,
    subscribe,
    getChampionshipById,
    getChampionshipWithEventsById,
    getSubscriberDetails,
    unsubscribe,
    unsubscribe_user
};

function getChampionshipsWithEventsSessions() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/getAllWithEvent/`, requestOptions).then(handleResponse);
}

function getPublicChampionshipsWithEventsSessions() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/getAllP/`, requestOptions).then(handleResponse);
}

function getChampionshipById(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/${id}`, requestOptions).then(handleResponse);
}


function getChampionshipWithEventsById(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/getWithEvents/${id}`, requestOptions).then(handleResponse);
}


function getChampionships() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/?limit=1999&skip=0`, requestOptions).then(handleResponse);
}

function deleteChampionshipEventsAndSessions(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'DELETE',
        headers: headers_,
    };

    return fetch(`${backendURL}/championships/${id}`, requestOptions).then(handleResponse);
};

function deleteEventFromChampionship(id,event_id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'DELETE',
        headers: headers_,
    };

    return fetch(`${backendURL}/championships/removeEventFromChampionship/${id}?event_id=${event_id}`, requestOptions).then(handleResponse);
};

function registerChampionship(championship) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers_,
        //headers: auth.authHeader(),
        body: JSON.stringify(championship)
    };

    return fetch(`${backendURL}/championships/`, requestOptions).then(handleResponse);
};

function updateChampionship(id,championship) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'PATCH',
        headers: headers_,
        //headers: auth.authHeader(),
        body: JSON.stringify(championship)
    };

    return fetch(`${backendURL}/championships/${id}`, requestOptions).then(handleResponse);
};

function addEventToChampionship(id,event) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'PUT',
        headers: headers_,
        //headers: auth.authHeader(),
        body: JSON.stringify(event)
    };

    return fetch(`${backendURL}/championships/addEventToChampionship/${id}`, requestOptions).then(handleResponse);
};


function subscribe(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/${id}/subscribe`, requestOptions).then(handleResponse);
};

function unsubscribe(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/${id}/unsubscribe`, requestOptions).then(handleResponse);
};

function unsubscribe_user(id, userId) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/championships/${id}/unsubscribe_user?user_id=${userId}`, requestOptions).then(handleResponse);
};


function uploadChampionshipImage(id,file){

    var headers_ = auth.authHeader()
    //headers_['Content-Type'] = 'multipart/form-data; charset=UTF-8'
    headers_['accept'] = 'application/json'
    const formData = new FormData()
    formData.append('file', file)

    const requestOptions = {
      method: 'POST',
      headers: headers_,
      body: formData
    };

    return fetch(`${backendURL}/championships/upload_image/${id}`, requestOptions).then(handleResponse);
};

function getSubscriberDetails(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'GET',
        headers: headers_
    };
    return fetch(`${backendURL}/championships/subscriberDetails/${id}`, requestOptions).then(handleResponse);
}

function getChampionshipLeaderboard(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'GET',
        headers: headers_
    };
    return fetch(`${backendURL}/championships/leaderboard/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            if(response.status !== 304){
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        }
        //console.log(data);
        return data;
    });

}



