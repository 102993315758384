<template>
    <div class="content">
    <card>
        <div class="d-flex align-items-center" v-if="paidFor">
            <p class="alert alert-success my-auto mx-auto">Grazie per essersi iscritto a: <strong>{{event_name}}</strong>.</p>
        </div>
        <div class="my-auto text-center" v-if="payment_ongoing">
          <i  class="fa fa-spinner fa-4x mb-4 fa-spin mx-auto" aria-hidden="true"></i>
          <p><strong>Stiamo elaborando la sua richiesta, attenda su questa pagina.</strong></p>
        </div>
        <div class="alert alert-danger my-auto  text-center" v-if="isPaymentError">
            Il pagamento dell'iscrizione a <strong>{{event_name}}</strong> ha avuto un problema. Riprovi o contatti un amministratore!
        </div>
        <label v-if="!paidFor" class="mb-3 w-100 text-center">Se vuoi iniziare il processo di pagamento per l'evento/campionato selezionato procedi con i metodi di pagmento suggeriti</label>
        <div class="row" v-if="!paidFor">
            <div class="col-md-4  pb-3">
                <br><br>
                <label v-if="event_type=='event'"> Evento:   <strong>{{event_name}}</strong></label>
                <label v-if="event_type=='championship'"> Campionato:   <strong>{{event_name}}</strong></label>
                <br>
                <label> Costo iscrizione:   <strong>{{event_cost}}€</strong></label>
            </div>
            <div class="col-md-8 mx-auto">
                <div ref="paypal" class="d-flex  align-items-center">
                  <i v-if="!loaded" class="fa fa-spinner fa-4x mb-4 fa-spin mx-auto" aria-hidden="true"></i>
                </div>
            </div>
        </div>            
    </card>
    </div>

</template>

<script>
import { paymentService } from '@/_services';
import { eventService } from '@/_services';
import { championshipService } from '@/_services';



// import image from "../assets/lamp.png"
export default {
  name: "HelloWorld",
  data: function() {
    return {
      loaded: false,
      paidFor: false,
      payment_ongoing: false,
      isPaymentError: false,
      event_name: "",
      event_cost: 0,
      event_id: "",
      event_type: "",
      product: {
        price: 60,
        description: "Abbonamento ATK-Pro 12 mesi",
      }
    };
  },
  mounted: function() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?currency=EUR&client-id="+process.env.VUE_APP_PAYPAL_CLIENTID;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  created(){
        console.log("GETTING VALUES FROM URL")
        console.log(this.$route.query.type)
        this.event_type=this.$route.query.type
        console.log(this.$route.query.id)
        if(this.$route.query.type=="event")
          eventService.getEventById(this.$route.query.id).then(json=>{
            //this.event_json = json
            console.log(json)
            console.log(json["event_name"])
            this.event_name=json["event_name"]
            this.event_cost=json["cost"]
            this.event_id=json["id_"]
          }).catch(err => 
              this.$router.push({name: 'Not Found', })
          );
        else if(this.$route.query.type=="championship")
          championshipService.getChampionshipById(this.$route.query.id).then(json=>{
            //this.event_json = json
            console.log(json)
            console.log(json["championship_name"])
            this.event_name=json["championship_name"]
            this.event_cost=json["cost"]
            this.event_id=json["id_"]
          }).catch(err => 
              this.$router.push({name: 'Not Found', })
          );
        else this.$router.push({name: 'Not Found', })
  },
  methods: {
    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            this.payment_ongoing=true
            return actions.order.create({
              purchase_units: [
                {
                  description: this.event_name,
                  amount: {
                    currency_code: "EUR",
                    value: this.event_cost
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            //const order = await actions.order.capture();
            console.log(data)
            paymentService.paypalCaptureOrder(data.orderID,this.event_id).then(completed=>{
            this.paidFor = true;
            this.payment_ongoing=false
            }).catch(err =>
              this.isPaymentError=true
            );
          },
          onError: err => {
            console.log(err);
            this.isPaymentError=true;
            this.payment_ongoing=false
          },
          onCancel: cancel => {
            this.payment_ongoing=false
          }
        })
        .render(this.$refs.paypal);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>