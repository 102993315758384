<template>
  <card class="mt-4">
    <h4 slot="header" class="card-title">Cambia la tua mail</h4>
                <form >
                        <div class="row align-items-center">
                            <div class="form-group col-md-6">
                                <base-input type="email"
                                            label="Nuova mail"
                                            v-model="user.email"/>
                            </div>
                            <div class="form-group col-md-6">
                                <button type="button" 
                                :disabled="!user.email"
                                class="btn btn-info btn-fill float-right" @click="completeChange">
                                Cambia la mail
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <p class="text-danger" v-if="change_failed"><i class="fa fa-times" aria-hidden="true"> </i> Cambio mail fallito, contatta un amministratore.</p>
                            <p class="text-success" v-if="change_success"><i class="fa fa-check" aria-hidden="true"> </i> Cambio mail iniziato, riceverà a breve una mail (sul nuovo indirizzo) per confermare il cambio.</p>
                        </div>
                </form>
  </card>
</template>
<script>
import { userService } from '@/_services';

export default {

    data () {
        return {
            old_mail: '',
            change_failed: false,
            change_success: false,
            user:{
                email:'',
                first_name: '',
                last_name: '',
                steamID: ''
                }
        }
    },

    computed: {

    },
    created () {
        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.old_mail = curr_user.sub
    },
    methods: {
        completeChange(){
            this.change_failed=false
            this.change_success=false
            userService.startChangeMail(this.user).then(data=>{
                this.change_success=true;
            }).catch(err => {
                this.change_failed=true;
            });
        }
    }
};
</script>
<style scoped>
</style>