<template>
  <div class="content">
    <div class="container-fluid">
      <card v-if="loggedin_user.scopes.includes('Admin')">
          <form>
            <div class="row">
                <div class="col-md-12">
                <label  for="email">Operazioni admin</label>
                <input type="email" placeholder="email dell'utente da modificare" 
                v-model="email_action" name="email" class="form-control" 
                :class="{ 'is-invalid': !email_action }" />
                <div v-show="!email_action" class="invalid-feedback-login">Devi inserire una email</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button :disabled="!email_action" type="submit" 
                    class="btn btn-success btn-fill float-right w-100" 
                    @click.prevent="makeAdmin">
                    <i class="fa fa-plus"></i>  Rendi Admin
                    </button>
                </div>
                <div class="col-md-4">
                    <button :disabled="!email_action" type="submit" 
                    class="btn btn-danger btn-fill float-right w-100" 
                    @click.prevent="removeAdmin">
                    <i class="fa fa-times"></i>  Rimuovi Admin
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button :disabled="!email_action" type="submit" 
                    class="btn btn-success btn-fill float-right w-100" 
                    @click.prevent="enableUserAdmin">
                    <i class="fa fa-plus"></i> Abilita utente
                    </button>
                </div>
                <div class="col-md-4">
                    <button :disabled="!email_action" type="submit" 
                    class="btn btn-warning btn-fill float-right w-100" 
                    @click.prevent="disableUserAdmin">
                    <i class="fa fa-ban"></i> Disabilita utente
                    </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                    <button :disabled="!email_action" type="submit" 
                    class="btn btn-danger btn-fill float-right w-100" 
                    @click.prevent="deleteUser">
                    <i class="fa fa-user-times"></i>  Cancella utente
                    </button>
                </div>
            </div>
          </form>
        </card>

        <card
        class="card-plain strpied-tabled-with-hover"
        body-classes="table-full-width table-responsive">

        <div class="row">
            <div class="col-md-6 my-auto">
                    <h4 class="card-title">Utenti</h4>
            </div>
        </div> 
            <div style="overflow-x: auto">
                <table class="table table-hover table-striped table-bordered ">
                    <thead>
                        <tr>
                            <th >-</th>
                            <th @click="sort('first_name')">Nome</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')" @click="sort('username')">Username</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')" @click="sort('steamID')">SteamId</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')" @click="sort('steamID')">Email</th>
                            <th @click="sort('roles')">Ruolo</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')" @click="sort('steamID')">Stato</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click="onRowClick(user_db_entry)" v-for="(user_db_entry, index) in sortedUsers" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{user_db_entry['first_name']}} {{user_db_entry['last_name']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">{{user_db_entry['username']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">{{user_db_entry['steamID']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">{{user_db_entry['email']}}</td>
                            <td>
                              <a v-if="user_db_entry['roles'].includes('Admin')" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                Admin
                              </a>
                              <a v-if="!user_db_entry['roles'].includes('Admin')" class="my-0 py-0 px-1 btn btn-success btn-fill text-white">
                                Driver
                              </a>
                            </td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">
                              <a v-if="user_db_entry['disabled']" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                Disabilitato
                              </a>
                              <a v-if="!user_db_entry['disabled']" class="my-0 py-0 px-1 btn btn-success btn-fill text-white">
                                Attivo
                              </a>
                            </td>                                                
                        </tr>
                    </tbody>
                </table>
            </div>
        </card>

        <transition name="fade" appear>
            <div class="modal-overlay" v-if="showModalUserSelected" @click="closePopup"></div>
        </transition>
            <transition name="slide" appear>
            <form class="modal-popup" v-if="showModalUserSelected">
                <div class="row pb-2">
                    <div class="col-md-11 my-auto">
                        <h6 >Utente selezionato</h6>
                    </div>
                    <div class="col-md-1 text-right">
                        <i class="fa fa-times" @click="closePopup" aria-hidden="true"></i>
                    </div>
                    <card class="card-user w-100">
                      <img slot="image" src="/img/user_card.png" alt="..."/>
                    <div class="author">
                      <img class="avatar border-gray" v-bind:src="this.selected_user.avatarURL" 
                      @error="onImgError()"
                      alt=""/>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ">
                          <span class="font-weight-bold">Nome:</span>
                          {{selected_user.name}}
                        </div>
                        <div class="col-md-6">
                          <span class="font-weight-bold">Username:</span>
                          {{selected_user.username}}
                        </div>
                      </div>
                    <div class="row">
                        <div v-if="loggedin_user.scopes.includes('Admin')" class="col-md-6 ">
                          <span class="font-weight-bold">Steam-ID:</span>
                          {{selected_user.steamID}}
                        </div>
                        <div v-if="loggedin_user.scopes.includes('Admin')" class="col-md-6 ">
                          <span class="font-weight-bold">Email:</span>
                          {{selected_user.email}}
                        </div>
                      </div>
                    </card>
                </div>
                <div class="clearfix"></div>
            </form>
        </transition> 
    </div>
  </div>
</template>
<script>
import { userService } from '@/_services';
import Card from '../components/Cards/Card.vue';

export default {
    components: {
        Card

    },
    data () {
        return {
            email_action: '',
            loggedin_user: {
                id: '',
                scopes: [],
                email: ''
            },
            json_all_users_db: null,
            showModalUserSelected: false,
            currentSort:'rank',
            currentSortDir:'asc',
            selected_user: {
              steamID: null,
              name: null,
              email: null,
              username: null,
              avatarURL: '/img/faces/face-0.jpg'
            }
        }
    },
    computed:{
      sortedUsers:function() {
        console.log("QUI")
        if (this.json_all_users_db){
        return this.json_all_users_db.sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort==="first_name"){
            if(a[this.currentSort].toLowerCase()+" "+a["last_name"].toLowerCase() < b[this.currentSort].toLowerCase()+" "+a["last_name"].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase()+" "+a["last_name"].toLowerCase() > b[this.currentSort].toLowerCase()+" "+a["last_name"].toLowerCase()) return 1 * modifier;
          }
          if(this.currentSort==="roles"){
            if(a[this.currentSort].length < b[this.currentSort].length ) return -1 * modifier;
            if(a[this.currentSort].length > b[this.currentSort].length ) return 1 * modifier;
          }
          if(a[this.currentSort] && b[this.currentSort] && !this.currentSort==="rank"){
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          }
          else{
            if(a[this.currentSort]< b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          }
          return 0;
        });
        }
      }
    },
    created(){
        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.loggedin_user.id = curr_user.id
        this.loggedin_user.email = curr_user.sub
        this.loggedin_user.scopes = curr_user.scopes
        this.getAllUsers()
    },
    methods:{
        parseToInt(value){
          if(isNaN(parseInt(value)))
            return "NC"
          else return parseInt(value)
        },
        sort:function(s) {
          console.log("sort")
          //if s == current sort, reverse
          if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          }
          this.currentSort = s;
        },
        onImgError(){
          this.selected_user.avatarURL = '/img/faces/face-0.jpg'
        },
        closePopup(){
            this.showModalUserSelected=false;
            //selected user to be set to null
            this.selected_user.steamID=null
            this.selected_user.name=null
            this.selected_user.email=null
            this.selected_user.username=null
            this.selected_user.avatarURL= '/img/faces/face-0.jpg'
        },
        onRowClick(user_db_entry){
          console.log(user_db_entry["id_"])

          this.selected_user.steamID=user_db_entry["steamID"]
          this.selected_user.name=user_db_entry['first_name']+" "+user_db_entry['last_name']
          this.selected_user.email=user_db_entry['email']
          this.selected_user.username=user_db_entry['username']
          this.selected_user.avatarURL=process.env.VUE_APP_AWS_BUCKET_URL+user_db_entry["id_"]+'_avatar';
        
          this.showModalUserSelected=true
        },
        async getAllUsers(){
          await userService.getAll().then(json=>{
            this.json_all_users_db = json
          })
        },
        deleteUser(){
        this.$confirm(
          {
            title: 'Cancella utente',
            message: "Sei sicuro di voler cancellare l'utente: "+this.email_action+"? La cancellazione non potrà essere ripristinata in seguito.",
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                userService.delete_user(this.email_action).then(data=>{
                    this.$confirm(
                    {
                        title: 'Operazione completata',
                        message: "L'utente è stato cancellato correttamente.",
                        button: {
                            yes: 'Ho capito',
                        }
                    }
                    )})
                    .catch(err => 
                        this.$confirm(
                          {
                              title: 'Errore',
                              message: "L'utente non è stato cancellato correttamente, email inesistente. Puoi correggere la mail e riprovare",
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  console.log(err);
                                }
                          }
                      )
                    );

              }
              }
          }
        );
        },
        makeAdmin(){
        this.$confirm(
          {
            title: 'Rendi user admin',
            message: "Sei sicuro di voler rendere l'utente: "+this.email_action+" un admin?",
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                userService.make_admin(this.email_action).then(data=>{
                    this.$confirm(
                    {
                        title: 'Operazione completata',
                        message: "L'utente è stato reso admin correttamente. Per beneficiare a pieno delle sue nuove funzionalità di admin chiedi all'utente di effettuare un logout e un nuovo login.",
                        button: {
                            yes: 'Ho capito',
                        }
                    }
                    )})
                    .catch(err => {
                        console.log(err)
                        errore = ""
                        if(err==="Not Found"){
                            var errore = "Utente non trovato, correggi la mail e riprova."
                        }
                        else if(err==="Not Modified")
                            errore = "L'utente è gia un admin."
                        
                        this.$confirm(

                          {
                              title: 'Errore',
                              message: "L'utente non è stato reso admin. "+errore,
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  console.log(err);
                                }
                          }
                      )
                    });

              }
              }
          }
        );
        },
        removeAdmin(){
        this.$confirm(
          {
            title: 'Rimuovi ruolo admin',
            message: "Sei sicuro di voler rimuovere il ruolo admin all'utente: "+this.email_action,
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                userService.remove_admin(this.email_action).then(data=>{
                    this.$confirm(
                    {
                        title: 'Operazione completata',
                        message: "Privilegi admin rimossi correttamente.",
                        button: {
                            yes: 'Ho capito',
                        }
                    }
                    )})
                    .catch(err => {
                        console.log(err)
                        errore = ""
                        if(err==="Not Found"){
                            var errore = "Utente non trovato, correggi la mail e riprova."
                        }
                        else if(err==="Not Modified")
                            errore = "L'utente già non aveva il ruolo admin."
                        
                        this.$confirm(

                          {
                              title: 'Errore',
                              message: "Ruolo admin non rimosso correttamente. "+errore,
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  console.log(err);
                                }
                          }
                      )
                    });

              }
              }
          }
        );
        },
        enableUserAdmin(){
        this.$confirm(
          {
            title: 'Abilita utente',
            message: "Sei sicuro di voler abilitare l'utente: "+this.email_action,
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                userService.enable_user(this.email_action).then(data=>{
                    this.$confirm(
                    {
                        title: 'Operazione completata',
                        message: "Utente abilitato con successo.",
                        button: {
                            yes: 'Ho capito',
                        }
                    }
                    )})
                    .catch(err => {
                        console.log(err)
                        errore = ""
                        if(err==="Not Found"){
                            var errore = "Utente non trovato, correggi la mail e riprova."
                        }
                        else if(err==="Not Modified")
                            errore = "L'utente era già abilitato."
                        
                        this.$confirm(

                          {
                              title: 'Errore',
                              message: "Abilitazione fallita. "+errore,
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  console.log(err);
                                }
                          }
                      )
                    });

              }
              }
          }
        );
        },
        disableUserAdmin(){
        this.$confirm(
          {
            title: 'Disabilita utente',
            message: "Sei sicuro di voler disabilitare l'utente: "+this.email_action,
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                userService.disable_user(this.email_action).then(data=>{
                    this.$confirm(
                    {
                        title: 'Operazione completata',
                        message: "Utente disabilitato con successo.",
                        button: {
                            yes: 'Ho capito',
                        }
                    }
                    )})
                    .catch(err => {
                        console.log(err)
                        errore = ""
                        if(err==="Not Found"){
                            var errore = "Utente non trovato, correggi la mail e riprova."
                        }
                        else if(err==="Not Modified")
                            errore = "L'utente era già disabilitato."
                        
                        this.$confirm(

                          {
                              title: 'Errore',
                              message: "Disabilitazione fallita. "+errore,
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  console.log(err);
                                }
                          }
                      )
                    });

              }
              }
          }
        );
        }
    }
}
</script>
<style scoped>

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.popup {
 box-shadow: 1px 0 10px 15px rgba(0, 0, 0, .3);
}

.modal-overlay {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.5);
}
.modal-popup {
 position: fixed;
 z-index: 99;
 background-color: #FFF;
 border-radius: 4px;
 transform: translate(-50%, -50%);
 top: 50%;
 left: 50%;
 padding: 25px;

}

.fade-enter-active,
.fade-leave-active {
 transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
 transform: translateY(-50%) translateX(100vw);
}
</style>