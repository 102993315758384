<template>
  <card>
    <h4 v-if="championship_json_string_for_update ===''" slot="header" class="card-title">Crea campionato</h4>
    <h4 v-if="championship_json_string_for_update !==''" slot="header" class="card-title">Modifica campionato</h4>

    <transition-expand>
      <div class="row justify-content-center mb-2" v-if="championship_image_browser_URL">
      <img
          v-bind:src="this.championship_image_browser_URL" 
          alt="..."
          @error="onImgError()"
          class="img-fluid" style="min-height:219px; -o-object-fit: contain; object-fit: contain;"/>
      </div>
    </transition-expand>
    <form>
      <div class="row">
        <div class="col-md-6">
          <label  for="championship.championship_name">Nome campionato</label>
          <input type="text" placeholder="Nome campionato" v-model="championship.championship_name" name="championship.championship_name" class="form-control" 
          :class="{ 'is-invalid': !championship.championship_name }" />
          <div v-show="!championship.championship_name" class="invalid-feedback-login">Nome campionato non può essere vuoto</div>
        </div>
        <div class="col-md-3">
          <base-input
                    label="Max Drivers" type="number" placeholder="1"
                    :min="1" :max="99" inline center controls size="small"
                    v-model="championship.max_drivers">
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input
                    label="Costo iscrizione" type="number" placeholder="0"
                    :min="0" :max="999" inline center controls size="small"
                    v-model="championship.cost">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label  for="championship.championship_discord">Discord dedicato</label>
          <input type="text" placeholder="Discord dedicato" v-model="championship.championship_discord" name="championship.championship_discord" class="form-control"/>
        </div>
      </div>
      <a> (1080x566)</a>
      <div class="row">
        <div class="col-md-4">
            <input type="file" name="file" id="file" class="customInputFile" @change="onFileSelected"> 
            <label class="btn btn-info btn-fill float-center" style="padding: 10px 14px;" for="file">Seleziona file copertina</label>
            <a v-if="this.selectedFile"> {{this.selectedFile.name}}</a>
        </div>
        <div class="col-md-4">
            <base-checkbox label="Nascondi" v-model="championship.hidden"> 
            </base-checkbox> 
        </div>
        <div class="col-md-4">
            <base-checkbox label="Iscrizioni aperte" v-model="championship.subscription_open"> 
            </base-checkbox> 
        </div>

      </div>
      <card class="mt-4">
        <h4 slot="header" class="card-title">Allega documenti:</h4>
        <button href="#" type="button" class="btn btn-fill btn-success" @click="championship.files.push(['',''])"><i class="fa fa-plus"></i></button>
        <div class="row" v-for="(file, index) in this.championship.files" v-bind:key="index">
          <div class="col-md-5">
            <base-input type="text"
                      label="Nome documento"
                      placeholder="Nome documento"
                      v-model="file[0]">
            </base-input>
          </div>
          <div class="col-md-5 align-self-center">
            <base-input type="text"
                      label="Link documento"
                      placeholder="Link documento"
                      v-model="file[1]">
            </base-input>
            </div>
            <div class="col-md-2 align-self-center">
              <button href="#" class="btn btn-fill btn-danger" @click="championship.files.splice(index,1)"><i class="fa fa-trash"></i></button>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Dettagli campionato</label>
            <textarea rows="5" class="form-control border-input"
                      placeholder=
"Temperatura: 19 gradi
Meteo: variabile
Regole: bla,bla,bla
puoi premere invio per andare a capo"
                      v-model="championship.championship_details">
              </textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Dettagli campionato (per homepage)</label>
            <textarea rows="5" class="form-control border-input"
                      placeholder=
"Inserisci la descrizione che vorresti vedere sulla Home Page
Puoi andare a capo premendo invio"
                      v-model="championship.championship_lp_details">
              </textarea>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button  v-if="championship_json_string_for_update === ''" type="submit" class="btn btn-info btn-fill float-right" @click.prevent="createChampionship">
          Crea campionato
        </button>
        <button v-if="championship_json_string_for_update !== ''" type="submit" class="btn btn-info btn-fill float-right" @click.prevent="updateChampionship">
          Modifica campionato
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
    <card class="mt-4">
          <h4 v-if="event_list !== []" slot="header" class="card-title">Tappe di campionato:</h4>
          <div class="row">
                <event @showManageOnParent="showManageEventInUpdate" class="col-sm-6" v-for="(event, index) in event_list" 
                :event_name="event.event_name" 
                :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" 
                :championship_id="championship.id"
                :event_json_string="objToJSON(event)" v-bind:key="index"/>
          </div>
    </card>
  </card>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue';
  import Toggle from 'src/components/Toggle.vue';
  import TransitionExpand from 'src/components/TransitionExpand.vue';

  import auth from '@/_helpers/auth-header';
  import { userService } from '@/_services';
  import { eventService } from '@/_services';
  import { championshipService } from '@/_services';


  import { bus } from '@/main';
  import moment from 'moment';


  var backendURL=process.env.VUE_APP_BE_URL

  export default {
    components: {
      Card,
      Toggle,
      TransitionExpand
    },
    props: {
      championship_json_string_for_update: {
        type: String
      }
    },
    watch: { 
      championship_json_string_for_update: 
        function() { // watch it
          console.log("Watch")
          this.init()
        }
      },
    data () {
      return this.initCreate();
    },
    created() {
    },
    methods: {
      showManageEventInUpdate(value){
        var enhanced_event_json = JSON.parse(value)
        enhanced_event_json.championship_id = JSON.parse(this.championship_json_string_for_update).id_
        this.$emit('showManageOnParent', JSON.stringify(enhanced_event_json))
      },
      initCreate(){
        return {
          championship: {
            event_ids: [],
            subscriber_ids: [],
            championship_name: '',
            championship_discord:'',
            max_drivers: 0,
            championship_details: ``,
            championship_lp_details: ``,

            files: [],
            cost: 0,
            hidden:false,
            subscription_open: true,
            id: ""
          },
          selectedFile: null,
          championship_image_browser_URL: "/img/event_default.jpg",
          event_list: []
        }
      },
      init(){
        //If is in update mode --> event_json_string_for_update passed as props
        if(this.championship_json_string_for_update){
          console.log("UPDATE MODE " +this.championship_json_string_for_update)
          var json_championship = JSON.parse(this.championship_json_string_for_update)
          this.event_list = json_championship.events
          this.championship.event_ids = json_championship.event_ids
          this.championship.subscriber_ids = json_championship.subscriber_ids
          this.championship.championship_name = json_championship.championship_name
          this.championship.championship_discord = json_championship.championship_discord

          this.championship.max_drivers = json_championship.max_drivers
          this.championship.championship_details = json_championship.championship_details
          this.championship.championship_lp_details = json_championship.championship_lp_details

          this.championship.hidden = json_championship.hidden
          this.championship.subscription_open = json_championship.subscription_open

          this.championship.cost = json_championship.cost
          

          for (var key in json_championship.files) {
              this.championship.files.push([key,json_championship.files[key]])
          }

          console.log(this.championship.files)
          this.championship.id = json_championship.id_

          console.log("QUI2 " +this.championship.id)

          if(this.championship.id){
              this.championship_image_browser_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.championship.id+"_championship_image"
          }
        }
        //It's a create reinit empty variables
        else{
            Object.assign(this.$data, this.initCreate());
        }
      },
      onFileSelected(event){
        console.log(event)
        var currFile = event.target.files[0]
        if (currFile.size > 1024 * 1024 * 5) {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato è troppo grande, la dimensione massima per la preview evento è di 5 MB.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        if (currFile['type']!=='image/jpeg' && currFile['type']!=='image/png') {
          this.$confirm(
            {
              title: 'Errore',
              message: 'Il file selezionato deve essere un file jpeg o un file png.',
              button: {
                yes: 'Ho capito',
              }
            }
          )
          return;
        }
        this.selectedFile = event.target.files[0]
        this.championship_image_browser_URL = URL.createObjectURL(this.selectedFile)
      },
      createChampionship(){
        console.log(this.championship.files)
        for (var i = 0; i < this.championship.files.length; i++) {
            if (this.championship.files[i][0]=="" | this.championship.files[i][1]==""){
              this.championship.files.splice(i,1)
              i--
              }
            //Do something
        }
        console.log(this.championship.files)
        this.$confirm(
          {
            title: "Creazione campionato",
            message: "Vuoi creare il nuovo campionato?",
            button: {
              no: 'No',
              yes: 'Si'
            },
            /**
            * Callback Function
            * @param {Boolean} confirm 
            */
            callback: confirm => {
              if (confirm) {
                
                championshipService.registerChampionship(this.championship).then(json=>{
                    var championship_ID = json['id_'];
                    if(this.selectedFile)
                      championshipService.uploadChampionshipImage(championship_ID,this.selectedFile)
                    if(championship_ID){
                        this.$confirm(
                          {
                            title: 'Operazione completata',
                            message: "Il campionato è stato creato correttamente, ora puoi visualizzarlo nei campionati futuri.",
                            button: {
                              yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                          }
                        )
                    }
                }
                ).catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Il campionato non è stato creato correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                        }
                    )
                );
              }
            }
          }
        )
      },
      objToJSON(obj){
        return JSON.stringify(obj)
      },
      onImgError(){
        this.championship_image_browser_URL = "/img/event_default.jpg"
      },
      updateChampionship(){
        this.$confirm(
          {
            title: "Modifica campionato",
            message: "Vuoi modificare il campionato?",
            button: {
              no: 'No',
              yes: 'Si'
            },
            /**
            * Callback Function
            * @param {Boolean} confirm 
            */
            callback: confirm => {
              if (confirm) {
                console.log("QUI "+this.championship.id)
                championshipService.updateChampionship(this.championship.id,this.championship).then(data=>{
                    if(this.selectedFile)
                      championshipService.uploadChampionshipImage(this.championship.id,this.selectedFile)
                        this.$confirm(
                          {
                            title: 'Operazione completata',
                            message: "Il campionato è stato modificato correttamente, ora puoi visualizzarlo nei campionati futuri.",
                            button: {
                              yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                          }
                        )
                }
                ).catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Il campionato non è stato modificato correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/championship-list')
                                }
                              }
                        }
                    )
                );
              }
            }
          }
        )
    },
  }
  }
</script>
<style>

</style>
