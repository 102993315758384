<template>
    <div class="content">
            <div v-if="loggedin_user.scopes.includes('Admin')" class="row mb-2 ml-2">
                <button @click.prevent="goToCreate()" class="btn btn-success nav-link btn-fill"><i class="fa fa-plus"></i>  Crea evento</button>
            </div>
            <card>
                <ul class="nav nav-pills nav-fill">
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('live')" :class="{ active: isActiveLive }" href="#">Eventi in corso</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('future')" :class="{ active: isActiveFuture}" href="#">Eventi futuri</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('past')" :class="{ active: isActivePast }" href="#">Eventi conclusi</a>
                    </li>
                </ul>
            </card>
            <card>
                <div class="tab-content py-3" id="myTabContent">
                    <transition name="slide-fade" >
                    <div v-show="isActiveLive" id="live">
                        <div class="row">
                            <event class="col-sm-6 mx-auto" @showManageOnParent="showManageInUpdate" v-for="(event, index) in liveEvents" :event_name="event.event_name"  :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" :event_json_string="objToJSON(event)" v-bind:key="index"/>
                            <i v-if="liveEvents.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div v-if="liveEvents.length===0" class="col-sm-12  text-center"> Non ci sono eventi Live al momento</div>
                        </div>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActiveFuture" id="future">
                        <div class="row">
                            <event class="col-sm-6 mx-auto" @showManageOnParent="showManageInUpdate" v-for="(event, index) in futureEvents" :event_name="event.event_name" :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" :event_json_string="objToJSON(event)" v-bind:key="index"/>
                            <i v-if="futureEvents.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div v-if="futureEvents.length===0" class="col-sm-12  text-center"> Non ci sono eventi in programma al momento</div>
                        </div>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActivePast" id="past">
                        <div class="row">
                            <event class="col-sm-6 mx-auto" @showManageOnParent="showManageInUpdate" v-for="(event, index) in currentpastEvents" :event_name="event.event_name" :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" :event_json_string="objToJSON(event)" v-bind:key="index"/>
                            <i v-if="currentpastEvents.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div v-if="currentpastEvents.length===0" class="col-sm-12  text-center"> Non ci sono eventi passati</div> 
                        </div>
                        <button @click.prevent="loadMorePastEvents()" v-if="pastEvents.length>past_events_page_size" class="btn btn-success nav-link btn-fill"><i class="fa fa-refresh"></i>  Carica altri...</button>

                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActiveManageEvent" id="manageEvent">
                        <event-profile-form :event_json_string_for_update="selected_event_json_string">
                        </event-profile-form>
                    </div>
                    </transition>
                </div>
            </card>
    </div>
</template>
<script>
import Event from 'src/components/Event/Event.vue';
import EventProfileForm from 'src/components/Event/EventProfileForm.vue'
import { eventService } from '@/_services';
import Vue from 'vue'
import { utilsService } from '@/_services';


export default {
    components: {
        Event,
        EventProfileForm
    },
    data () {
        return {
            activeItem: 'live',
            liveEvents: [],
            futureEvents: [],
            pastEvents: [],
            currentpastEvents: [],
            selected_event_json_string: "",
            isActiveLive: false,
            isActiveFuture: true,
            isActivePast:false,
            isActiveManageEvent:false,
            past_events_page_size:4,
            loggedin_user: {
                id: '',
                scopes: [],
                email: ''
            }
        }
    },
    created(){
        eventService.getEventsWithSessionsSingle().then(json=>{
            var i;
            for (i = 0; i < json.length; i++) {
                this.splitEvents(json[i]);
            }
            this.liveEvents.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.futureEvents.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.pastEvents.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.currentpastEvents=this.pastEvents.slice(0, this.past_events_page_size)
        }
        );
        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.loggedin_user.id = curr_user.id
        this.loggedin_user.email = curr_user.sub
        this.loggedin_user.scopes = curr_user.scopes
    },
    methods: {
        setActive (menuItem) {
            this.isActiveLive= false
            this.isActiveFuture= false
            this.isActivePast= false
            this.isActiveManageEvent = false

            if(menuItem==='live')
                this.isActiveLive= true
            else if(menuItem==='future')
                this.isActiveFuture= true
            else if(menuItem==='past')
                this.isActivePast= true
            else if(menuItem==='manageEvent')
                this.isActiveManageEvent= true

        },
        showManageInUpdate(value){
            this.selected_event_json_string = value
            this.goToUpdate()
        },
        loadMorePastEvents(){
            this.past_events_page_size = this.past_events_page_size + 4
            this.currentpastEvents=this.pastEvents.slice(0, this.past_events_page_size)
        },
        goToCreate(){
            this.selected_event_json_string= ""
            this.setActive( "manageEvent")
        },
        goToUpdate(){
            this.setActive( "manageEvent")
        },
        splitEvents(event){
            //if at least one is ongoing the event is Live
            if(this.checkForValue(event, "status",1))
            {
                this.liveEvents.push(event)
            }
            else if(this.checkForValue(event, "status",0))
            {
                this.futureEvents.push(event)
            }
            else{
                this.pastEvents.push(event)
            }

            //if all status are 2 --> closed session
            //else if(this.checkAllValue(event, "status",2)){
            //    this.pastEvents.push(event)
            //}
            //else{
            //    this.pastEvents.push(event)
            //}
        },
        //at least one json key with a specific value
        checkForValue(json, input_key, value) {
            var key
            var found = false
            for (key in json) {
                if (found === false && typeof (json[key]) === "object") {
                    found = this.checkForValue(json[key], input_key,value);
                } else if (String(key) === String(input_key) && String(json[key]) === String(value)) {
                    return true;
                } else{
                    found = found || false;
                }
            }
            return found;
        },
        //all json key with a specific value
        checkAllValue(json, input_key, value) {
            var key
            var found = true
            for (key in json) {
                if (typeof (json[key]) === "object") {
                    found = found && this.checkAllValue(json[key], input_key,value);
                } else if (String(key) === String(input_key) && String(json[key]) === String(value)) {
                    return true;
                } else if (String(key) === String(input_key) && String(json[key]) !== String(value)) {
                    return false;
                }
            }
            return found;
        },
        objToJSON(obj){
            return JSON.stringify(obj)
        }

    }
};
</script>
<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>