<template>
    <div class="content">
            <div v-if="loggedin_user.scopes.includes('Admin')" class="row mb-2 ml-1">
                <button @click.prevent="goToChampionshipCreate()" class="btn btn-success nav-link btn-fill ml-1"><i class="fa fa-plus"></i>  Crea campionato</button>
                <button @click.prevent="goToChampionshipEventCreate()" class="btn btn-warning nav-link btn-fill ml-1"><i class="fa fa-plus"></i>  Aggiungi tappa</button>
            </div>
            <card>
                <ul class="nav nav-pills nav-fill">
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('live')" :class="{ active: isActiveLive }" href="#">Live-Timing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('current')" :class="{ active: isActiveCurrent }" href="#">Campionati</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="setActive('past')" :class="{ active: isActivePast }" href="#">Campionati conclusi</a>
                    </li>
                </ul>
            </card>
            <card>
                <div class="tab-content py-3">
                    <transition name="slide-fade" >
                    <div v-show="isActiveLive" id="live">
                        <div class=row>
                            <event class="col-sm-6 mx-auto" @showManageOnParent="showManageEventInUpdate" v-for="(event, index) in liveEvents" 
                            :event_name="event.event_name" 
                            :championship_id="event.champ_id"
                            :view_mode="true" 
                            :subscriber_ids="event.subscriber_ids" 
                            :max_drivers="event.max_drivers" :id="event.id_" 
                            :event_json_string="objToJSON(event)" v-bind:key="index"/>
                            <i v-if="liveEvents.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i>
                            <div v-if="liveEvents.length===0" class="col-sm-12 text-center"> Non ci sono eventi di campionato Live al momento</div>
                        </div>
                    </div>
                    </transition>
                    <transition name="slide-fade">
                    <div  v-show="isActiveCurrent" id="current">
                        <div class=row>
                            <championship class="col-sm-6 mx-auto" @showManageOnParent="showManageChampionshipInUpdate" v-for="(championship, index) in currentChampionships" :championship_name="championship.championship_name" :subscriber_ids="championship.subscriber_ids" :max_drivers="championship.max_drivers" :id="championship.id_" :championship_json_string="objToJSON(championship)" v-bind:key="index"/>
                            <i v-if="currentChampionships.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i>                            
                            <div v-if="currentChampionships.length===0" class="col-sm-12 text-center"> Non ci sono campionati in programma al momento</div>
                        </div>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActivePast" id="past">
                        <div class=row>
                            <championship class="col-sm-6 mx-auto" @showManageOnParent="showManageChampionshipInUpdate" v-for="(championship, index) in currentpastChampionships" :championship_name="championship.championship_name" :subscriber_ids="championship.subscriber_ids" :max_drivers="championship.max_drivers" :id="championship.id_" :championship_json_string="objToJSON(championship)" v-bind:key="index"/>
                            <i v-if="currentpastChampionships.length===0" class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div v-if="currentpastChampionships.length===0" class="col-sm-12 text-center"> Non ci sono campionati passati</div>
                        </div>
                        <button @click.prevent="loadMorePastChamps()" v-if="pastChampionships.length>past_champ_page_size" class="btn btn-success nav-link btn-fill"><i class="fa fa-refresh"></i>  Carica altri...</button>

                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActiveManageChampionship" id="manageChampionship">
                        <championship-profile-form @showManageOnParent="showManageEventInUpdate" :championship_json_string_for_update="selected_championship_json_string">
                        </championship-profile-form>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActiveManageChampionshipEvent" id="manageChampionshipEvent">
                        <championship-event-profile-form :event_json_string_for_update="selected_event_json_string">
                        </championship-event-profile-form>
                    </div>
                    </transition>

                </div>
            </card>
    </div>
</template>
<script>
import Event from 'src/components/Event/Event.vue';
import Championship from 'src/components/Event/Championship.vue';

import EventProfileForm from 'src/components/Event/EventProfileForm.vue'
import ChampionshipProfileForm from 'src/components/Event/ChampionshipProfileForm.vue'
import ChampionshipEventProfileForm from 'src/components/Event/ChampionshipEventProfileForm.vue'


import { eventService } from '@/_services';
import { championshipService } from '@/_services';
import { utilsService } from '@/_services';



export default {
    components: {
        Championship,
        Event,
        ChampionshipProfileForm,
        ChampionshipEventProfileForm
    },
    data () {
        return {
            liveEvents: [],
            currentChampionships: [],
            pastChampionships: [],
            currentpastChampionships: [],
            past_champ_page_size:4,
            selected_championship_json_string: "",
            selected_event_json_string: "",
            isActiveLive: false,
            isActiveCurrent: true,
            isActivePast:false,
            isActiveManageChampionship:false,
            isActiveManageChampionshipEvent:false,
            loggedin_user: {
                id: '',
                scopes: [],
                email: ''
            }
        }
    },
    created(){
        eventService.getEventsWithSessionsChampionship().then(json=>{
            var i;
            for (i = 0; i < json.length; i++) {
                this.splitEvents(json[i]);
            }
            this.liveEvents.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
        }
        );
        championshipService.getChampionshipsWithEventsSessions().then(json=>{
            var i;
            for (i = 0; i < json.length; i++) {
                this.splitChampionships(json[i]);
            }
            this.currentChampionships.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.pastChampionships.sort(utilsService.GetSortOrderChampAndEvents("created_on"))
            this.currentpastChampionships=this.pastChampionships.slice(0, this.past_champ_page_size)
        }
        );
        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.loggedin_user.id = curr_user.id
        this.loggedin_user.email = curr_user.sub
        this.loggedin_user.scopes = curr_user.scopes
    },
    methods: {
        loadMorePastChamps(){
            this.past_champ_page_size = this.past_champ_page_size + 4
            this.currentpastChampionships=this.pastChampionships.slice(0, this.past_champ_page_size)
        },
        setActive (menuItem) {
            console.log("setActive "+menuItem)
            this.isActiveLive= false
            this.isActiveCurrent= false
            this.isActivePast= false
            this.isActiveManageChampionship = false
            this.isActiveManageChampionshipEvent = false
            if(menuItem==='live')
                this.isActiveLive= true
            else if(menuItem==='current')
                this.isActiveCurrent= true
            else if(menuItem==='past')
                this.isActivePast= true
            else if(menuItem==='manageChampionship')
                this.isActiveManageChampionship= true
            else if(menuItem==='manageChampionshipEvent')
                this.isActiveManageChampionshipEvent= true
        },
        splitEvents(event){
            //if at least one is ongoing the event is Live
            if(this.checkForValue(event, "status",1))
            {
                this.liveEvents.push(event)
            }
        },
        splitChampionships(championship){
            
            //if all status are 2 --> closed session
            if(this.checkAllValue(championship, "status",2)){
                if(this.loggedin_user.scopes.includes('Admin'))
                    this.pastChampionships.push(championship)
                if(!this.loggedin_user.scopes.includes('Admin') && !championship.hidden==true)
                    this.pastChampionships.push(championship)
                console.log("TEST 1")
            }
            else{
                if(this.loggedin_user.scopes.includes('Admin'))
                    this.currentChampionships.push(championship)
                if(!this.loggedin_user.scopes.includes('Admin') && !championship.hidden==true)
                    this.currentChampionships.push(championship)
                console.log("TEST 2")
            }
        },
        goToChampionshipCreate(){
            this.selected_championship_json_string= ""
            this.setActive( "manageChampionship")
        },
        showManageChampionshipInUpdate(value){
            console.log("catch emit showManageChampionshipInUpdate "+value)
            this.selected_championship_json_string = value
            this.goToChampionshipUpdate()
        },
        showManageEventInUpdate(value){
            this.selected_event_json_string = value
            this.goToEventUpdate()
        },
        goToChampionshipEventCreate(){
            this.selected_event_json_string= ""
            this.setActive( "manageChampionshipEvent")
        },
        goToChampionshipUpdate(){
            this.setActive( "manageChampionship")
        },
        goToEventUpdate(){
            this.setActive( "manageChampionshipEvent")
        },
        //at least one json key with a specific value
        checkForValue(json, input_key, value) {
            var key
            var found = false
            for (key in json) {
                if (found === false && typeof (json[key]) === "object") {
                    found = this.checkForValue(json[key], input_key,value);
                } else if (String(key) === String(input_key) && String(json[key]) === String(value)) {
                    return true;
                } else{
                    found = found || false;
                }
            }
            return found;
        },
        //all json key with a specific value
        checkAllValue(json, input_key, value) {
            var key
            var found = true
            for (key in json) {
                if (typeof (json[key]) === "object" ) {
                    console.log("TEST recall")
                    found = found && this.checkAllValue(json[key], input_key,value);
                } else if (String(key) === String(input_key) && String(json[key]) === String(value)) {
                    console.log("TEST 3")
                    console.log(String(input_key)+" "+String(json[key]))
                    return true;
                } else if (String(key) === String(input_key) && String(json[key]) !== String(value)) {
                    console.log("TEST 4")
                    console.log(String(input_key)+" "+String(json[key]))
                    return false;
                }
            }
            return found;
        },
        objToJSON(obj){
            return JSON.stringify(obj)
        }

    }
};
</script>
<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>