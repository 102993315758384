<template>
        <div class="container">
          <img src="/img/ATK_logo.png" alt="atk logo" class="mx-auto d-block w-50">
          <div class="row">
            <div class="col-md-12 col-md-offset-2 text-center">
              <h2 class="title text-danger">404 Not Found</h2>
              <h2 class="title">Oops! La pagina che stai cercando non esiste.</h2>
            </div>
          </div>
        </div>
</template>

<script>
  export default {}

</script>
