<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">ATK-Webapp</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <!--<li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown">
              <i class="nc-icon nc-palette"></i>
            </a>
          </li>-->
          <!--<base-dropdown tag="li">
            <template slot="title">
              <i class="nc-icon nc-send"></i>
              <b class="caret"></b>
              <span class="notification" v-if="expiration_notified">1</span>
            </template>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
          </base-dropdown> -->
          <!--<li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nc-icon nc-zoom-split"></i>
              <span class="d-lg-block">&nbsp;Search</span>
            </a>
          </li>-->
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">
              {{loggedin_user.email}}
            </a>
            <a class="my-0 py-0 px-1"
            :class="loggedin_user.scope==='Admin' ? 'btn btn-danger btn-fill': 'btn btn-success btn-fill'" href="#">
              {{loggedin_user.scope}}
            </a>
          </li>
          <!--<base-dropdown title="Dropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something</a>
            <div class="divider"></div>
            <a class="dropdown-item" href="#">Separated link</a>
          </base-dropdown>-->
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logOut">
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import { userService } from '@/_services';
  import { paymentService } from '@/_services';


  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false,

        loggedin_user: {
          id: '',
          scope: '',
          email: ''
        }
      }
    },
    created(){
      var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
      this.loggedin_user.id = curr_user.id
      if (curr_user.scopes.includes("Admin"))
        this.loggedin_user.scope="Admin"
      else
        this.loggedin_user.scope="Driver"
      this.loggedin_user.email = curr_user.sub  

    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      logOut(){
        userService.logout();
        this.$router.push('/login');
      }
    }
  }

</script>
<style>

</style>
