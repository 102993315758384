<template>
    <div class="content">
            <card  v-if="loggedin_user.scopes.includes('Admin') && ( hasPracticeResultFailed || hasQualifyResultFailed || hasRace1ResultFailed || hasRace2ResultFailed)">
                <div class="alert alert-danger alert-with-icon" data-notify="container">
                    <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                    <span data-notify="message">I risultati delle seguenti sessioni non sono stati trovati sui server di gioco:
                        <ul class="ml-4">
                            <li v-if="hasPracticeResultFailed">Pratica</li>
                            <li v-if="hasQualifyResultFailed">Qualifica</li>
                            <li v-if="hasRace1ResultFailed">Gara1</li>
                            <li v-if="hasRace2ResultFailed">Gara2</li>
                        </ul>  
                    </span>
                    <span data-notify="message">Clicca sulla relativa sessione per passare al caricamento manuale.</span>
                </div>
            </card>
            <h4 class="card-title my-auto">Risultati evento "{{event_json['event_name']}}"</h4>

            <card>
                <ul class="nav nav-pills nav-fill">
                    <li class="nav-item" v-if="hasPractice">
                        <a class="nav-link"  @click="setActive('practice')" :class="{ active: isActivePractice }" href="#">Pratica</a>
                    </li>
                    <li class="nav-item" v-if="hasQualify">
                        <a class="nav-link"  @click="setActive('qualify')" :class="{ active: isActiveQualify}" href="#">Qualifica</a>
                    </li>
                    <li class="nav-item" v-if="hasRace1">
                        <a class="nav-link"  @click="setActive('race1')" :class="{ active: IsActiveRace1 }" href="#">Gara 1</a>
                    </li>
                    <li class="nav-item" v-if="hasRace2">
                        <a class="nav-link"  @click="setActive('race2')" :class="{ active: IsActiveRace2 }" href="#">Gara 2</a>
                    </li>
                </ul>
            </card>
            <card>
                <div class="tab-content py-3" id="myTabContent">
                    <p v-if="!isActivePractice && !isActiveQualify && !IsActiveRace1 && !IsActiveRace2"> Seleziona una sessione per visualizzare i risultati</p>
                    <transition name="slide-fade" >
                    <div v-show="isActivePractice" id="practice">
                        <div class="row" v-show="!practice_result && !loggedin_user.scopes.includes('Admin')">
                            <i class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div class="col-sm-12 text-center">Non ci sono ancora risultati per questa sessione</div>
                        </div>
                        <p v-show="!practice_result && !hasPracticeResultFailed && loggedin_user.scopes.includes('Admin')">I risultati devono ancora essere processati dal server, attendere o caricarli manualmente. <br /></p>
                        <div v-show="!practice_result && loggedin_user.scopes.includes('Admin')">
                            <result-loader :game="event_json['game']" :session_id="practiceSessionId">
                            </result-loader>
                        </div>
                        <card v-show="practice_result" 
                        class="card-plain strpied-tabled-with-hover"
                        body-classes="table-full-width table-responsive">

                        <div class="row">
                            <div class="col-md-6 my-auto">
                                    <p><strong>Sessione di pratica</strong></p>
                                    <p  v-if="practice_result"><strong>Risultati aggiornati a: </strong>{{formatDate(new Date(practice_result['date']))}}</p>
                            </div>
                            <div class="col-md-6 my-auto mx-0">
                                <div class="card-body h-100 d-flex flex-row-reverse" >
                                    <div class="btn-group align-middle"> 
                                        <button href="#" v-if="event_json['event_type']=='Championship' && loggedin_user.scopes.includes('Admin')" @click="updatePenaltiesOnSession(practice_result)" class="btn btn-success btn-fill d-flex align-items-center"><i class="fa fa-pencil-square-o fa-2x"></i>  Aggiorna penalità</button>
                                        <button href="#" v-if="loggedin_user.scopes.includes('Admin')" @click="deleteSessionResult(practiceSessionId,practice_result['id_'])" class="btn btn-fill btn-danger d-flex align-items-center" ><i class="fa fa-trash fa-2x"></i> Cancella risultati</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                            <div v-if="this.practice_result" >
                                <div style="overflow-x: auto">
                                <table class="table table-striped table-bordered ">
                                    <thead >
                                        <tr>
                                            <th>Rank</th>
                                            <th>Nome</th>
                                            <th v-if="loggedin_user.scopes.includes('Admin')">SteamId</th>
                                            <th>Car</th>
                                            <th>Miglior giro</th>
                                            <!--<th>Laps</th>-->
                                            <!--<th v-show="event_json['event_type']=='Championship'">Penalità</th>-->
                                            <!--<th v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">Descrizione Penalità</th> -->                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(res_entry, index) in this.practice_result['session_result']" :key="index">
                                            <td>{{res_entry["position"]}}</td>
                                            <td>{{res_entry['driver_name']}}</td>
                                            <td v-show="loggedin_user.scopes.includes('Admin')">{{res_entry['driver_steamID']}}</td>
                                            <td>{{res_entry['driver_car']}}</td>
                                            <td>{{getLapTime(res_entry['best_time'])}}</td>
                                            <!--<td>
                                                <div>
                                                    <span class="font-weight-bold">Giri: </span>{{res_entry['laps']}}
                                                    <span class="font-weight-bold">Crash: </span>{{res_entry['crashes']}}
                                                </div>
                                            </td>  -->                                   
                                            <!--<td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="number"
                                                    :min="1" :max="100" inline center controls size="small"
                                                    v-model="res_entry['penalty']">
                                                </base-input>
                                            </td>-->
                                            <!--<td valign=middle v-if="!loggedin_user.scopes.includes('Admin') && 
                                                        event_json['event_type']=='Championship'">
                                                        {{res_entry['penalty']}}   
                                                        <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>

                                            <td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="text"
                                                    v-model="res_entry['penalty_info']">
                                                </base-input>
                                            </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </card>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="isActiveQualify" id="qualify">
            
                        <div class="row" v-show="!qualify_result && !loggedin_user.scopes.includes('Admin')">
                            <i class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div class="col-sm-12 text-center">Non ci sono ancora risultati per questa sessione</div>
                        </div>
                        
                        <p v-show="!qualify_result && !hasQualifyResultFailed && loggedin_user.scopes.includes('Admin')">I risultati devono ancora essere processati dal server, attendere o caricarli manualmente. <br /></p>
                        <div v-show="!qualify_result && loggedin_user.scopes.includes('Admin')">
                            <result-loader :game="event_json['game']" :session_id="qualifySessionId">
                            </result-loader>
                        </div>
                        <card v-show="qualify_result" 
                        class="card-plain strpied-tabled-with-hover"
                        body-classes="table-full-width table-responsive">
                        <div class="row">
                            <div class="col-md-6 my-auto">
                                    <p><strong>Sessione di qualifica</strong></p>
                                    <p  v-if="qualify_result"><strong>Risultati aggiornati a: </strong>{{formatDate(new Date(qualify_result['date']))}}</p>
                            </div>
                            <div class="col-md-6 my-auto mx-0">
                                <div class="card-body h-100 d-flex flex-row-reverse" >
                                    <div class="btn-group align-middle"> 
                                        <button href="#" v-if="event_json['event_type']=='Championship' && loggedin_user.scopes.includes('Admin')" @click="updatePenaltiesOnSession(qualify_result)" class="btn btn-success btn-fill d-flex align-items-center"><i class="fa fa-pencil-square-o fa-2x"></i>  Aggiorna penalità</button>
                                        <button href="#" v-if="loggedin_user.scopes.includes('Admin')" @click="deleteSessionResult(qualifySessionId,qualify_result['id_'])" class="btn btn-fill btn-danger d-flex align-items-center" ><i class="fa fa-trash fa-2x"></i> Cancella risultati</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                            <div v-if="this.qualify_result" >
                                <div style="overflow-x: auto">
                                <table class="table table-striped table-bordered ">
                                    <thead >
                                        <tr>
                                            <th>Rank</th>
                                            <th>Nome</th>
                                            <th v-if="loggedin_user.scopes.includes('Admin')">SteamId</th>
                                            <th>Car</th>
                                            <th>Miglior giro</th>
                                            <th>Penalità (secondi)</th>
                                            <!--<th>Laps</th>-->
                                            <!--<th v-show="event_json['event_type']=='Championship'">Penalità</th>-->
                                            <th v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">Descrizione Penalità</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(res_entry, index) in this.qualify_result['session_result']" :key="index">
                                            <td>{{res_entry["position"]}}</td>
                                            <td>{{res_entry['driver_name']}}</td>
                                            <td v-show="loggedin_user.scopes.includes('Admin')">{{res_entry['driver_steamID']}}</td>
                                            <td>{{res_entry['driver_car']}}</td>
                                            <td>{{getLapTime(res_entry['best_time'])}}</td>
                                            <td>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])!='-'" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])=='-'">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>   
                                            <!--<td>
                                                <div>
                                                    <span class="font-weight-bold">Giri: </span>{{res_entry['laps']}}
                                                    <span class="font-weight-bold">Crash: </span>{{res_entry['crashes']}}
                                                    <span class="font-weight-bold">Cuts: </span>{{res_entry['cuts']}}
                                                </div>
                                            </td>   -->                                            
                                            <!--<td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="number"
                                                    :min="1" :max="100" inline center controls size="small"
                                                    v-model="res_entry['penalty']">
                                                </base-input>
                                            </td>-->
                                            <!--<td valign=middle v-if="!loggedin_user.scopes.includes('Admin') && 
                                                        event_json['event_type']=='Championship'">
                                                        {{res_entry['penalty']}}   
                                                        <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>-->

                                            <td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="text"
                                                    v-model="res_entry['penalty_info']">
                                                </base-input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </card>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="IsActiveRace1" id="race1">
                        <div class="row" v-show="!race1_result && !loggedin_user.scopes.includes('Admin')">
                            <i class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div class="col-sm-12 text-center">Non ci sono ancora risultati per questa sessione</div>
                        </div>
                        
                        <p v-show="!race1_result && !hasRace1ResultFailed && loggedin_user.scopes.includes('Admin')">I risultati devono ancora essere processati dal server, attendere o caricarli manualmente. <br /></p>                        
                        <div v-show="!race1_result && loggedin_user.scopes.includes('Admin')">
                            <result-loader :game="event_json['game']" :session_id="race1SessionId">
                            </result-loader>
                        </div>
                        <card v-show="race1_result" 
                        class="card-plain strpied-tabled-with-hover"
                        body-classes="table-full-width table-responsive">
                        <div v-show="race1_result" class="row">
                            <div class="col-md-6 my-auto">
                                    <p><strong>Gara 1</strong></p>
                                    <p  v-if="race1_result"><strong>Risultati aggiornati a: </strong>{{formatDate(new Date(race1_result['date']))}}</p>
                            </div>
                            <div class="col-md-6 my-auto mx-0">
                                <div class="card-body h-100 d-flex flex-row-reverse" >
                                    <div class="btn-group align-middle"> 
                                        <button href="#" v-if="event_json['event_type']=='Championship' && loggedin_user.scopes.includes('Admin')" @click="updatePenaltiesOnSession(race1_result)" class="btn btn-success btn-fill d-flex align-items-center"><i class="fa fa-pencil-square-o fa-2x"></i>  Aggiorna penalità</button>
                                        <button href="#" v-if="loggedin_user.scopes.includes('Admin')" @click="deleteSessionResult(race1SessionId,race1_result['id_'])" class="btn btn-fill btn-danger d-flex align-items-center" ><i class="fa fa-trash fa-2x"></i> Cancella risultati</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                            <div v-if="this.race1_result" >
                                <div style="overflow-x: auto">
                                <table class="table table-striped table-bordered ">
                                    <thead >
                                        <tr>
                                            <th>Rank</th>
                                            <th>Nome</th>
                                            <th v-show="loggedin_user.scopes.includes('Admin')">SteamId</th>
                                            <th>Car</th>
                                            <th>Tempo totale</th>
                                            <th>Penalità (secondi)</th>
                                            <th>Miglior giro</th>
                                            <th>Laps</th>
                                            <!--<th v-show="event_json['event_type']=='Championship'">Penalità</th>-->
                                            <th v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">Descrizione Penalità</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(res_entry, index) in this.race1_result['session_result']" :key="index">
                                            <td>{{res_entry["position"]}}</td>
                                            <td>{{res_entry['driver_name']}}</td>
                                            <td v-show="loggedin_user.scopes.includes('Admin')">{{res_entry['driver_steamID']}}</td>
                                            <td>{{res_entry['driver_car']}}</td>
                                            <td>{{getTotalTime(res_entry['total'])}}</td>
                                            <td>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])!='-'" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])=='-'">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                    <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>
                                            <td>{{getLapTime(res_entry['best_time'])}}</td>
                                            <td>
                                                <div>
                                                    <span class="font-weight-bold">Giri: </span>{{res_entry['laps']}}
                                                    <span class="font-weight-bold">Crash: </span>{{res_entry['crashes']}}
                                                    <span class="font-weight-bold">Cuts: </span>{{res_entry['cuts']}}
                                                </div>
                                            </td>

                                            <!--<td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="number"
                                                    :min="1" :max="100" inline center controls size="small"
                                                    v-model="res_entry['penalty']">
                                                </base-input>
                                            </td>-->
                                            <!--<td valign=middle v-show="!loggedin_user.scopes.includes('Admin') && 
                                                        event_json['event_type']=='Championship'">
                                                        {{res_entry['penalty']}}   
                                                        <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>-->

                                            <td  v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <textarea class="mx-auto"
                                                    rows="2"
                                                    v-model="res_entry['penalty_info']">
                                                </textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </card>
                    </div>
                    </transition>
                    <transition name="slide-fade" >
                    <div v-show="IsActiveRace2" id="race2">
                        <div class="row" v-show="!race2_result && !loggedin_user.scopes.includes('Admin')">
                            <i class="fa fa-times fa-4x mb-4 mx-auto" style="color:red" aria-hidden="true"></i> 
                            <div class="col-sm-12 text-center">Non ci sono ancora risultati per questa sessione</div>
                        </div>
                        
                        <p v-show="!race2_result && !hasRace2ResultFailed && loggedin_user.scopes.includes('Admin')">I risultati devono ancora essere processati dal server, attendere o caricarli manualmente. <br /></p>                                                
                        <div v-show="!race2_result && loggedin_user.scopes.includes('Admin')">
                            <result-loader :game="event_json['game']" :session_id="race2SessionId">
                            </result-loader>
                        </div>
                        <card v-show="race2_result" 
                        class="card-plain strpied-tabled-with-hover"
                        body-classes="table-full-width table-responsive">
                            <div class="row">
                                <div class="col-md-6 my-auto">
                                    <p><strong>Gara 2</strong></p>
                                    <p  v-if="race2_result"><strong>Risultati aggiornati a: </strong>{{formatDate(new Date(race2_result['date']))}}</p>
                                </div>
                                <div class="col-md-6 my-auto mx-0">
                                    <div class="card-body h-100 d-flex flex-row-reverse" >
                                        <div class="btn-group align-middle"> 
                                            <button href="#" v-if="event_json['event_type']=='Championship' && loggedin_user.scopes.includes('Admin')" @click="updatePenaltiesOnSession(race2_result)" class="btn btn-success btn-fill d-flex align-items-center"><i class="fa fa-pencil-square-o fa-2x"></i>  Aggiorna penalità</button>
                                            <button href="#" v-if="loggedin_user.scopes.includes('Admin')" @click="deleteSessionResult(race2SessionId,race2_result['id_'])" class="btn btn-fill btn-danger d-flex align-items-center" ><i class="fa fa-trash fa-2x"></i> Cancella risultati</button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div v-if="this.race2_result" >
                                <div style="overflow-x: auto">
                                <table class="table table-striped table-bordered ">
                                    <thead >
                                        <tr>
                                            <th>Rank</th>
                                            <th>Nome</th>
                                            <th v-show="loggedin_user.scopes.includes('Admin')">SteamId</th>
                                            <th>Car</th>
                                            <th>Tempo totale</th>
                                            <th>Penalità (secondi)</th>
                                            <th>Miglior giro</th>
                                            <th>Laps</th>
                                            <!--<th v-show="event_json['event_type']=='Championship'">Penalità</th>-->
                                            <th v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">Descrizione Penalità</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(res_entry, index) in this.race2_result['session_result']" :key="index">
                                            <td>{{res_entry["position"]}}</td>
                                            <td>{{res_entry['driver_name']}}</td>
                                            <td v-show="loggedin_user.scopes.includes('Admin')">{{res_entry['driver_steamID']}}</td>
                                            <td>{{res_entry['driver_car']}}</td>
                                            <td>{{getTotalTime(res_entry['total'])}}</td>
                                            <td>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])!='-'" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                <a v-if="getPenaltyTime(res_entry['penalty_time'])=='-'">
                                                {{getPenaltyTime(res_entry['penalty_time'])}}
                                                </a>
                                                    <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>                                            
                                            <td>{{getLapTime(res_entry['best_time'])}}</td>
                                            <td>
                                                    <div>
                                                        <span class="font-weight-bold">Giri: </span>{{res_entry['laps']}}
                                                        <span class="font-weight-bold">Crash: </span>{{res_entry['crashes']}}
                                                        <span class="font-weight-bold">Cuts: </span>{{res_entry['cuts']}}
                                                    </div>
                                            </td>                                               
                                            <!--<td v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <base-input
                                                    type="number"
                                                    :min="1" :max="100" inline center controls size="small"
                                                    v-model="res_entry['penalty']">
                                                </base-input>
                                            </td>-->
                                            <!--<td valign=middle v-if="!loggedin_user.scopes.includes('Admin') && 
                                                        event_json['event_type']=='Championship'">
                                                        {{res_entry['penalty']}}   
                                                        <i v-show="res_entry['penalty_info']" @click="openPenaltyPopup(res_entry['penalty_info'],res_entry['driver_name'])" class="fa fa-info-circle fa-lg pl-2 my-auto" style="cursor: pointer;" aria-hidden="true"></i>
                                            </td>-->
                                            <td  v-show="loggedin_user.scopes.includes('Admin') && event_json['event_type']=='Championship'">
                                                <textarea class="mx-auto"
                                                    rows="2"
                                                    v-model="res_entry['penalty_info']">
                                                </textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </card>
                    </div>
                    </transition>
                </div>
            </card>
            <transition name="fade" appear>
                            <div class="modal-overlay" v-if="showModalPenaltySelected" @click="closePopup"></div>
                </transition>
                <transition name="slide" appear>
                <div class="modal-popup" v-if="showModalPenaltySelected">
                    <div class="row">
                        <div class="col-md-11 my-auto">
                            <h6 class="my-auto d-print-inline-block">Penalità ricevute per il pilota {{modal_penalty_user}}</h6>
                        </div>
                        <div class="col-md-1 text-right">
                            <i class="fa fa-times" @click="closePopup" aria-hidden="true"></i>
                        </div>
                    </div>
                    <card class="mt-4">
                    <div class="row">
                        <div class="col-md-12" style="white-space: pre-wrap;">
                            <p>{{modal_penalty_info}}</p>
                        </div>
                    </div>
                    </card>
                    <div class="clearfix"></div>
                </div>
            </transition>
    </div>
</template>

<script>
import Event from 'src/components/Event/Event.vue';
import EventProfileForm from 'src/components/Event/EventProfileForm.vue'
import ResultLoader from 'src/components/Event/ResultLoader.vue'

import { eventService } from '@/_services';
import Vue from 'vue'


export default {
    components: {
        Event,
        EventProfileForm,
        ResultLoader
    },
    data () {
        return {
            isActivePractice: false,
            isActiveQualify: false,
            IsActiveRace1:false,
            IsActiveRace2:false,
            hasPractice:false,
            hasQualify:false,
            hasRace1:false,
            hasRace2:false,

            hasPracticeResultFailed:false,
            hasQualifyResultFailed:false,
            hasRace1ResultFailed:false,
            hasRace2ResultFailed:false,

            practiceSessionId:'',
            qualifySessionId:'',
            race1SessionId:'',
            race2SessionId:'',

            event_json: '',

            event_id: '',
            practice_result: null,
            qualify_result: null,
            race1_result: null,
            race2_result: null,
            showModalPenaltySelected: false,
            modal_penalty_info: '',
            modal_penalty_user: '',

            loggedin_user: {
                id: '',
                scopes: [],
                email: ''
            }
        }
    },
    created(){
        console.log("EventResultCREATED")

        var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
        this.loggedin_user.id = curr_user.id
        this.loggedin_user.email = curr_user.sub
        this.loggedin_user.scopes = curr_user.scopes

        console.log("GETTING VALUES FROM URL")
        console.log(this.$route.query.event_id)
        this.event_id=this.$route.query.event_id
        eventService.getEventWithSessions(this.event_id).then(json=>{
            this.event_json = json
            console.log(json)
            for(var curr_sess_count in json["sessions"]){
                var curr_sess = json["sessions"][curr_sess_count]
                console.log(curr_sess)
                if(curr_sess["sessionType"]==="Practice"){
                    this.hasPractice=true
                    this.setActive('practice')
                    this.practiceSessionId=curr_sess["id_"]
                    console.log("SET PRACTICE ID")
                    if(curr_sess["session_result_id"] && curr_sess["session_result_id"]!=="FAIL")
                        eventService.getSessionResult(curr_sess["session_result_id"]).then(json=>{
                            this.practice_result=json
                        })
                    else if(curr_sess["session_result_id"] && curr_sess["session_result_id"]==="FAIL")
                        this.hasPracticeResultFailed=true
                }
                else if(curr_sess["sessionType"]==="Qualify"){
                    this.hasQualify=true
                    this.setActive('qualify')
                    this.qualifySessionId=curr_sess["id_"]

                    if(curr_sess["session_result_id"] && curr_sess["session_result_id"]!=="FAIL")
                        eventService.getSessionResult(curr_sess["session_result_id"]).then(json=>{
                            this.qualify_result=json
                            console.log(this.qualify_result)
                        })
                    else if(curr_sess["session_result_id"] && curr_sess["session_result_id"]==="FAIL")
                        this.hasQualifyResultFailed=true
                }
                else if(curr_sess["sessionType"]==="Race"){
                    this.hasRace1=true
                    this.setActive('race1')
                    this.race1SessionId=curr_sess["id_"]

                    if(curr_sess["session_result_id"] && curr_sess["session_result_id"]!=="FAIL")
                        eventService.getSessionResult(curr_sess["session_result_id"]).then(json=>{
                            this.race1_result=json
                        })
                    else if(curr_sess["session_result_id"] && curr_sess["session_result_id"]==="FAIL")
                        this.hasRace1ResultFailed=true
                }
                else if(curr_sess["sessionType"]==="Race2"){
                    this.hasRace2=true
                    this.setActive('race2')
                    this.race2SessionId=curr_sess["id_"]

                    if(curr_sess["session_result_id"] && curr_sess["session_result_id"]!=="FAIL")
                        eventService.getSessionResult(curr_sess["session_result_id"]).then(json=>{
                            this.race2_result=json
                        })
                    else if(curr_sess["session_result_id"] && curr_sess["session_result_id"]==="FAIL")
                        this.hasRace2ResultFailed=true
                }
            }
        }
        )//.catch(err => 
                    //this.$router.push({name: 'Nots Found', })
        //);
    },
    methods: {
        formatDate(date){
            var options = {year: "numeric", month: "long", day: "numeric" , hour: "2-digit",minute: "2-digit"};
            return date.toLocaleString("it-IT", options);
        }, 
        closePopup(){
            this.showModalPenaltySelected=false;
            this.modal_penalty_info=''
            this.modal_penalty_user=''
        },
        openPenaltyPopup(penalty_info,penalty_user){
            this.modal_penalty_info=penalty_info
            this.modal_penalty_user=penalty_user
            console.log("OPEN POPUP")
            this.showModalPenaltySelected=true;
        },
        updatePenaltiesOnSession(selected_result_json){
            console.log(selected_result_json["id_"])
            //TODO update session results passing this JSON and then confirmation popup
            this.$confirm(
            {
                title: 'Modifica penalità',
                message: "Sei sicuro di voler modificare le penalità assegnate a questa sessione?",
                button: {
                yes: 'Si',
                no: 'No'
                },
                callback: confirm => {
                if (confirm) {
                    eventService.updateSessionResult(selected_result_json["id_"],selected_result_json).then(data=>{
                        this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "Penalità aggiornate correttamente.",
                            button: {
                                yes: 'Ho capito',
                            }
                        })
                    }).
                    catch(err => 
                        this.$confirm(
                            {
                                title: 'Errore',
                                message: "Penalità non aggiornate correttamente, riprovare o contattare un amministratore.",
                                button: {
                                    yes: 'Ho capito',
                                }
                            }
                        )
                    );
                }
                }
            }
            )
        },
        deleteSessionResult(session_id,session_result_id){
            console.log(session_id)
            console.log(session_result_id)
            this.$confirm(
            {
                title: 'Cancella risultati evento',
                message: "Sei sicuro di voler cancellare definitivamente i risultati evento selezionati? Una volta cancellati dovranno essere ricaricati manualmente",
                button: {
                yes: 'Si',
                no: 'No'
                },
                callback: confirm => {
                if (confirm) {
                    eventService.deleteSessionResult(session_id,session_result_id).then(data=>{
                        this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "Risultati sessione cancellati correttamente.",
                            button: {
                                yes: 'Ho capito',
                            },
                                callback: confirm => {
                                if (confirm) {
                                    this.$router.go()
                                }
                            }
                        })
                    }).
                    catch(err => 
                        this.$confirm(
                            {
                                title: 'Errore',
                                message: "Risultati sessione non cancellati, riprovare o contattare un amministratore.",
                                button: {
                                    yes: 'Ho capito',
                                }
                            }
                        )
                    );
                }
                }
            }
            )
        },
        onResultFileSelected(){

        },
        onUpload(){

        },
        setActive (menuItem) {
            this.isActivePractice= false
            this.isActiveQualify= false
            this.IsActiveRace1= false
            this.IsActiveRace2 = false

            if(menuItem==='practice')
                this.isActivePractice= true
            else if(menuItem==='qualify')
                this.isActiveQualify= true
            else if(menuItem==='race1')
                this.IsActiveRace1= true
            else if(menuItem==='race2')
                this.IsActiveRace2= true

        },
        objToJSON(obj){
            return JSON.stringify(obj)
        },
        getLapTime(timestamp){
            var minutes = parseInt((timestamp / 1000)  / 60);
            var seconds = Number((timestamp / 1000) % 60);

            var minutes_string = minutes
            if(minutes<10)
                minutes_string="0"+minutes
            
            var seconds_string = seconds.toFixed(3)
            if(seconds<10)
                seconds_string="0"+seconds.toFixed(3)

            return minutes_string+":"+seconds_string
        },
        getTotalTime(timestamp){
            var hours = parseInt((timestamp / 1000)  / 60 / 60);
            var minutes = parseInt((timestamp / 1000)  / 60 % 60);
            var seconds = Number((timestamp / 1000) % 60);

            var hours_string = hours
            if(hours<10)
                hours_string="0"+hours

            var minutes_string = minutes
            if(minutes<10)
                minutes_string="0"+minutes

            var seconds_string = seconds.toFixed(3)
            if(seconds<10)
                seconds_string="0"+seconds.toFixed(3)

            return hours_string+":"+minutes_string+":"+seconds_string
        },
        getPenaltyTime(timestamp){
            var seconds = Number((timestamp / 1000000000));

            if (seconds==0)
                return "-"
            var seconds_string = seconds

            return "+"+seconds_string+" sec."
        }

    }
};
</script>
<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


/*From here for the penalty popup*/
* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.popup {
 box-shadow: 1px 0 10px 15px rgba(0, 0, 0, .3);
}

.modal-overlay {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.5);
}
.modal-popup {
 position: fixed;
 z-index: 99;
 background-color: #FFF;
 border-radius: 4px;
 transform: translate(-50%, -50%);
 top: 50%;
 left: 50%;
 padding: 25px;

}

.fade-enter-active,
.fade-leave-active {
 transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
 transform: translateY(-50%) translateX(100vw);
}
</style>