/*import config from 'config';*/
import auth from '../_helpers/auth-header';
import { userService } from '@/_services';



var backendURL=process.env.VUE_APP_BE_URL

export const eventService = {
    registerEvent,
    updateEvent,
    updateSession,
    deleteEventAndSession,
    getSubscriberDetails,
    deleteSession,
    registerSession,
    addSessionToEvent,
    getEventBySessionId,
    uploadEventImage,
    getEventWithSessions,
    getMyEvents,
    getEventsWithSessionsSingle,
    getEventsWithSessionsChampionship,
    getEventById,
    getSessionResult,
    subscribe,
    unsubscribe,
    unsubscribe_user,
    addAcSessionResult,
    deleteSessionResult,
    updateSessionResult
};



function registerEvent(event) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers_,
        //headers: auth.authHeader(),
        body: JSON.stringify(event)
    };

    return fetch(`${backendURL}/events/`, requestOptions).then(handleResponse);
};

function getSubscriberDetails(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'GET',
        headers: headers_
    };
    return fetch(`${backendURL}/events/subscriberDetails/${id}`, requestOptions).then(handleResponse);
}

function updateEvent(id,event) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'
    const requestOptions = {
        method: 'PATCH',
        headers: headers_,
        //headers: auth.authHeader(),
        body: JSON.stringify(event)
    };

    return fetch(`${backendURL}/events/${id}`, requestOptions).then(handleResponse);
};

function registerSession(session) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers_,
        body: JSON.stringify(session)
    };

    return fetch(`${backendURL}/events/sessions/`, requestOptions).then(handleResponse);
};

function addSessionToEvent(id,session_id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_,
    };

    return fetch(`${backendURL}/events/addSessionToEvent/${id}?session_id=${session_id}`, requestOptions).then(handleResponse);
};

function updateSession(id,event) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PATCH',
        headers: headers_,
        body: JSON.stringify(event)
    };

    return fetch(`${backendURL}/events/sessions/${id}`, requestOptions).then(handleResponse);
};

function updateSessionResult(id, session_result) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PATCH',
        headers: headers_,
        body: JSON.stringify(session_result)
    };

    return fetch(`${backendURL}/events/sessions/session_result/${id}`, requestOptions).then(handleResponse);
};

function deleteSession(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'DELETE',
        headers: headers_,
    };

    return fetch(`${backendURL}/events/sessions/${id}`, requestOptions).then(handleResponse);
};

function deleteEventAndSession(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'DELETE',
        headers: headers_,
    };

    return fetch(`${backendURL}/events/${id}`, requestOptions).then(handleResponse);
};

function subscribe(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/events/${id}/subscribe`, requestOptions).then(handleResponse);
};

function unsubscribe(id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/events/${id}/unsubscribe`, requestOptions).then(handleResponse);
};

function unsubscribe_user(id,userId) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_
    };

    return fetch(`${backendURL}/events/${id}/unsubscribe_user?user_id=${userId}`, requestOptions).then(handleResponse);
};

function getEventsWithSessionsSingle() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/getAllWithSessionSingle/`, requestOptions).then(handleResponse);
}

function getMyEvents() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/getMyEvents/`, requestOptions).then(handleResponse);
}

function getEventWithSessions(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/${id}`, requestOptions).then(handleResponse);
}

function getEventById(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/${id}`, requestOptions).then(handleResponse);
}

function getSessionResult(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/session_result/${id}`, requestOptions).then(handleResponse);
}

function getEventBySessionId(id) {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/eventBySessionId/${id}`, requestOptions).then(handleResponse);
}

function getEventsWithSessionsChampionship() {
    var headers_ = auth.authHeader()
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers_
    };

    return fetch(`${backendURL}/events/getAllWithSessionChampionship/`, requestOptions).then(handleResponse);
}

function uploadEventImage(id,file){

    var headers_ = auth.authHeader()
    //headers_['Content-Type'] = 'multipart/form-data; charset=UTF-8'
    headers_['accept'] = 'application/json'
    const formData = new FormData()
    formData.append('file', file)

    const requestOptions = {
      method: 'POST',
      headers: headers_,
      body: formData
    };

    return fetch(`${backendURL}/events/upload_image/${id}`, requestOptions)
      .then(response => { 
        if(response.ok){
            return response.json();    
        } 
        if(response.status==401){
            userService.logout();
            this.$router.go('/login');
        }
        else{
            alert("Server returned " + response.status + " : " + response.statusText);
        }               
      })
      .then(response => {
        console.log(response);

      })
      .catch(err => {
        console.log(err);
      });

  };

  function addAcSessionResult(session_id, json_url) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_,
    };

    return fetch(`${backendURL}/events/results/addAcSessionResult?session_id=${session_id}&json_URL=${json_url}`, requestOptions).then(handleResponse);
};

function deleteSessionResult(session_id, session_result_id) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    headers_['accept'] = 'application/json'

    const requestOptions = {
        method: 'DELETE',
        headers: headers_,
    };

    return fetch(`${backendURL}/events/results/deleteSessionResult?session_id=${session_id}&session_result_id=${session_result_id}`, requestOptions).then(handleResponse);
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //if update --> 304 ("Not modified") is not a problem
        if (!response.ok ) {
            if (response.status === 401 ) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            if(response.status !== 304){
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        }
        //console.log(data);
        return data;
    });

}



