<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
<!--      <sidebar-link to="/admin/overview">
        <i class="nc-icon nc-chart-pie-35"></i>
        <p>Dashboard</p>
      </sidebar-link>-->
      <sidebar-link to="/admin/user">
        <i class="nc-icon nc-circle-09"></i>
        <p>User Profile</p>
      </sidebar-link>
      <sidebar-link to="/admin/users-page">
        <i class="fa fa-users" aria-hidden="true"></i>
        <p>Users</p>
      </sidebar-link>
      <sidebar-link to="/admin/event-list">
        <i class="nc-icon nc-badge"></i>
        <p>Events</p>
      </sidebar-link>
      <sidebar-link to="/admin/championship-list">
        <i class="nc-icon nc-notes"></i>
        <p>Championships</p>
      </sidebar-link>
      <sidebar-link to="/admin/FAQ">
        <i class="fa fa-info-circle"></i>
        <small><b>Comportamento in pista</b></small>
      </sidebar-link>
 <!--     <sidebar-link to="/admin/table-list">
        <i class="nc-icon nc-bullet-list-67"></i>
        <p>Table list</p>
      </sidebar-link>-->
     <!-- <sidebar-link to="/admin/typography">
        <i class="nc-icon nc-caps-small"></i>
        <p>Typography</p>
      </sidebar-link>-->
      <!--      <sidebar-link to="/admin/icons">
        <i class="nc-icon nc-atom"></i>
        <p>Icons</p>
      </sidebar-link>
<sidebar-link to="/admin/maps">
        <i class="nc-icon nc-pin-3"></i>
        <p>Maps</p>
      </sidebar-link>
  <sidebar-link to="/admin/notifications">
        <i class="nc-icon nc-bell-55"></i>
        <p>Notifications</p>
      </sidebar-link>   --> 



    <template slot="bottom-links">

      <!--<iframe class="border rounded border-dark" src="https://discordapp.com/widget?id=639933413712855048" width="100%" height="250" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
      -->

  <div class="text-center mb-4">
      <a href="https://discord.gg/FrUGc8RVuf"><img src="/img/discord_aci.png" alt="Join ATK discord" width="70%"></a>
  </div>

    <div class="text-center mb-3">
      <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="hosted_button_id" value="VK83HV2U3YYXG" />
      <input width="70%" type="image" src="/img/donazione.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/it_IT/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>

  <div class="text-center">
      <a href="https://discord.gg/MtDbjTPuUv"><img src="/img/discord.png" alt="Join ATK discord" width="70%"></a>
  </div>

  <br> 

  </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <content-footer>

      </content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    }
  }

</script>
