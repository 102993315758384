<template>
    <div class="content">
            <card >
                <div class="wrapper">
                    <div class="hidelogo_top"></div>
                    <!--<iframe scrolling="yes" class="w-100"
                    :src="server_url" 
                     
                    width="700px"
                    style="border: 0px none; margin-left: 0px; height: 100%; 
                    margin-top: -0px; margin-bottom: 0px; ">
                    </iframe>-->
                    <!--<div class="hidelogo_bottom"></div>-->
                </div>
            </card>
    </div>
</template>

<script>
import Event from 'src/components/Event/Event.vue';
import EventProfileForm from 'src/components/Event/EventProfileForm.vue'
import { eventService } from '@/_services';
import Vue from 'vue'


export default {
    components: {

    },
    data () {
        return {
            event_id: '',
            server_url: ''
        }
    },
    created(){
        console.log("GETTING VALUES FROM URL")
        console.log(this.$route.query.event_id)
        this.event_id=this.$route.query.event_id
        eventService.getEventWithSessions(this.event_id).then(json=>{

        for (var dict_key in json["sessions"]){
            if(json["sessions"][dict_key].status===1){
                if(json["sessions"][dict_key].server_num==-1){
                    this.server_url=json["sessions"][dict_key].server_url+"/live-timing"
                    console.log(this.server_url)
                    window.open(this.server_url, '_self');
                }
                else{
                    this.server_url=json["sessions"][dict_key].server_url+"/live-timing?server="+json["sessions"][dict_key].server_num
                    console.log(this.server_url)
                    window.open(this.server_url, '_self');
                }
            }
        }
        }
        ).catch(err => 
                    this.$router.push({name: 'Not Found', })
        );

    },
    methods: {

        objToJSON(obj){
            return JSON.stringify(obj)
        }
    }
};
</script>
<style>
.wrapper{
    position:relative;
    z-index: 1;
}

.hidelogo_top{
    position: absolute;
    width: 100%;
    height: 62px;
    background: rgb(255, 255, 255);
    left: 0px;
    top: 0px;
    z-index:999;
    display: block;
    color: #fff;
}
.hidelogo_bottom{
    position: absolute;
    width: 100%;
    height: 175px;
    background: rgb(255, 255, 255);
    right: 0px;
    bottom: 0px;
    z-index:999;
    display: block;
    color: #fff;
}
</style>