export const utilsService = {
    GetSortOrderChampAndEvents,
    sendContactUs
};
var backendURL=process.env.VUE_APP_BE_URL

//Comparer Function    
function GetSortOrderChampAndEvents(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function sendContactUs(mailInfo) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mailInfo)
    };
    return fetch(`${backendURL}/contact_us/`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log(data);
        return data;
    });

}