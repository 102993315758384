<template>
    <div class="content">
                    <div class="card">
                        <div class="row">
                              <div class="col-md-12" style="position:relative;">
                                <img
                                    v-bind:src="event_img_url"
                                    alt="..."
                                    class="img-fluid" 
                                    @error="onImgError()"
                                    style="min-width:100%;"/>
                                <img v-if="new_event"
                                    src="/img/new_ribbon.png"
                                    alt="..."
                                    class="img-fluid new-image" 
                                    @error="onImgError()"
                                    />
                                </div>
                        </div>
                        <div class="card-header" v-if="JSON.parse(this.event_json_string)['event_type'] === 'Championship'">
                            <h5 class="card-title text-center">{{event_name}} <strong>{{is_completed?" - Evento concluso":""}}</strong></h5>
                        </div>
                        <div class="row ">
                            <div class="col-xs-1 mx-auto" v-show="has_sessions_live_on_URL && game==='AC'">
                                <div class="card-body py-2 h-100 align-items-center d-flex" >
                                    <!--<h5 class="card-title font-weight-bold mb-4 w-100">{{event_name}}</h5>-->
                                    <div class="btn-group align-bottom mx-auto">
                                    <div class="btn btn-danger btn-fill  mx-auto" @click="openLivePage"
                                      style="min-width:80px;">
                                        <i class="fa fa-circle mr-2"></i>
                                        <small>Live</small>
                                    </div>
                                    <a :href="generateJoinURL()" class="btn btn-fill btn-success"><small>Join</small></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-8 mx-auto">
                                <div class="card-body  py-2 h-100 align-items-center d-flex" >
                                    <div class="btn-group align-bottom mx-auto"> 
                                        <!--<h5 class="card-title font-weight-bold mb-4 w-100">{{event_name}}</h5>-->
                                        <div v-if="!this.championship_id" v-bind:class="{'btn btn-success btn-fill': (max_drivers-subscriber_ids.length)>3,  
                                          'btn btn-warning btn-fill': (max_drivers-subscriber_ids.length)<4,  
                                          'btn btn-danger btn-fill': (max_drivers-subscriber_ids.length)===0}"
                                          style="min-width:60px;">
                                            <i class="fa fa-users"></i>
                                            <small >   {{subscriber_ids.length}}/{{max_drivers}}</small>
                                        </div>
                                        <!--<h5 class="card-title font-weight-bold mb-4 w-100">{{event_name}}</h5>-->
                                        <div class="btn btn-neutral btn-fill border" @click="openResultPage"
                                          style="min-width:60px;">
                                            <i class="fa fa-flag-checkered mr-2"></i>
                                            <small>Ris.</small>
                                        </div>
                                        <!--<div class="btn btn-success btn-fill" 
                                          style="">
                                            <small>{{game}}</small>
                                        </div>-->
                                        <div class="btn btn-success btn-fill border" @click="this.goToDetails"
                                          style="min-width:60px;">
                                            <i class="fa fa-sign-in mr-2"></i>
                                            <small> Dettagli</small>
                                        </div>
                                    </div>
                                  </div>
                            </div>
                            <div v-show="!view_mode && loggedin_user.scopes.includes('Admin')" class="col-xs-1 mx-auto">
                                <div  class="card-body py-2 h-100 align-items-center d-flex " >
                                    <div class="btn-group align-bottom mx-auto"> 
                                        <button href="#" class="btn btn-info btn-fill" @click="this.showParentManageForm"><i class="fa fa-pencil-square-o"></i></button>
                                        <button href="#" class="btn btn-fill btn-danger" @click="this.deleteEvent"><i class="fa fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    </div>
</template>
<script>
import { eventService } from '@/_services';
import { championshipService } from '@/_services';
import moment from 'moment';

export default {
    name: 'event',
    data(){
      return {
        event_img_url: "/img/event_default.jpg",
        has_sessions_live_on_URL: null,
        sessions_live_on_hostname: null,
        sessions_live_on_port: null,
        new_event : false,
        is_completed:true,
        game: "AC",
        loggedin_user: {
            id: '',
            scopes: [],
            email: ''
        }
      }
    },
    props: {
      event_json_string: {
        type: String
      },
      championship_id: {
        type: String
      },
      view_mode:{
        type: Boolean,
        default: () => false
      },
      session_ids: {
        type: Array,
        default: () => []
      },
      subscriber_ids: {
        type: Array,
        default: () => []
 /*       validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1}*/
      },
      event_name: {
        type: String
      },
      max_drivers: {
        type: Number,
      },
      id: {
        type: String,
        default: ""
      }
    },
    created(){
      if(this.id){
        if(!this.championship_id)
          this.event_img_url = process.env.VUE_APP_AWS_BUCKET_URL+this.id+"_event_image"
        else
           //this.event_img_url = process.env.VUE_APP_AWS_BUCKET_URL+this.championship_id+"_championship_image"
           this.event_img_url = process.env.VUE_APP_AWS_BUCKET_URL+this.id+"_champ_event_image"         
        
      }
      var event_json = JSON.parse(this.event_json_string)
      this.game = event_json.game
      for (var dict_key in event_json.sessions){
        if(event_json.sessions[dict_key].status!==2)
          this.is_completed=false
        if(event_json.sessions[dict_key].status===1){
          this.has_sessions_live_on_URL=event_json.sessions[dict_key].server_url

          var str = "Visit Microsoft!";
          var res = str.replace("Microsoft", "W3Schools");
          if(event_json.sessions[dict_key].server_url){
            this.sessions_live_on_hostname=event_json.sessions[dict_key].server_url.replace("https://","").replace("http://","")
            this.sessions_live_on_port=event_json.sessions[dict_key].http_port
          }
          console.log(this.has_sessions_live_on_URL)
          }
      }
      var curr_time = moment().format("X")
      var created_on = event_json.created_on
      //1 day is 86400 seconds, here we consider an event new if it has been created in the last 2 days
      this.new_event = curr_time-created_on < 86400*2

      var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
      this.loggedin_user.id = curr_user.id
      this.loggedin_user.email = curr_user.sub
      this.loggedin_user.scopes = curr_user.scopes
    },
    methods: {
      generateJoinURL(){
        var result=""
        if(this.sessions_live_on_hostname && this.sessions_live_on_port){
          if(this.sessions_live_on_hostname.indexOf(":")>-1)
            this.sessions_live_on_hostname = this.sessions_live_on_hostname.substring(0, this.sessions_live_on_hostname.indexOf(":"));
          result= "https://acstuff.ru/s/q:race/online/join?httpPort="+this.sessions_live_on_port+"&ip="+this.sessions_live_on_hostname
          return result
        }
        else return ""
      },
      goToDetails(){
        this.$router.push({name: 'Event Details', query: {event_id: this.id}});

        //var enhanced_event_json = JSON.parse(this.event_json_string)
        //if(this.championship_id)
        //  enhanced_event_json.championship_id = this.championship_id
        //this.$router.push({
        //    name: 'Event Details', 
        //    params: { event: JSON.stringify(enhanced_event_json) }
        //});
      },

      onImgError(){
        this.event_img_url = "/img/event_default.jpg"
      },
      showParentManageForm(){
        this.$emit('showManageOnParent', this.event_json_string)
      },
      openResultPage(){
        console.log("Open result")
        let routeData = this.$router.resolve({name: 'Event Result', query: {event_id: this.id}});
        window.open(routeData.href, '_blank');
      },
      openLivePage(){
        console.log("Open Live")
        let routeData = this.$router.resolve({name: 'Event Live', query: {event_id: this.id}});
        window.open(routeData.href, '_blank');
      },
      deleteEvent(){
        this.$confirm(
          {
            title: 'Cancella evento',
            message: "Sei sicuro di voler cancellare l'evento selezionato e tutte le relative sessioni?",
            button: {
              yes: 'Si',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                if(!this.championship_id){
                    eventService.deleteEventAndSession(this.id).then(data=>{
                          this.$confirm(
                          {
                              title: 'Operazione completata',
                              message: "L'evento è stato cancellato correttamente.",
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  if (confirm) {
                                      this.$router.go('/admin/event-list')
                                  }
                                }
                          }
                      )
                    }).
                    catch(err => 
                      this.$confirm(
                          {
                              title: 'Errore',
                              message: "L'evento non è stato cancellato correttamente, puoi riprovare o contattare un amministratore.",
                              button: {
                                  yes: 'Ho capito',
                              },                
                                callback: confirm => {
                                  if (confirm) {
                                      this.$router.go('/admin/event-list')
                                  }
                                }
                          }
                      )
                  );
                }
                else {
                  championshipService.deleteEventFromChampionship(this.championship_id,this.id).then(data=>{
                        this.$confirm(
                        {
                            title: 'Operazione completata',
                            message: "L'evento è stato cancellato correttamente.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/event-list')
                                }
                              }
                        }
                    )
                  }).
                  catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "L'evento non è stato cancellato correttamente, puoi riprovare o contattare un amministratore.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.go('/admin/event-list')
                                }
                              }
                        }
                    )
                );
                }
              }
              }
          }
        )
      }
    }
}
</script>
<style>
.new-image {
    left: 0%;
    padding-left: 15px;
    top: 0%;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    width: 14%;
}
</style>