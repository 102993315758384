<template>
    <div class="content">
        <card>
            <h4 slot="header" class="card-title">Dettagli campionato</h4>
            <transition-expand>
            <div class="row justify-content-center mb-2">
            <img
                v-bind:src="championship_image_URL" 
                alt="..."
                @error="onImgError()"
                class="img-fluid" style="min-height:219px; -o-object-fit: contain; object-fit: contain;"/>
            
            </div>
            </transition-expand>
            <div class="row">
                <h4 v-if="championship.cost!=0" slot="header" class="card-title m-2">Costo di iscrizione {{championship.cost}} €</h4>
                <h4 v-if="championship.cost==0" slot="header" class="card-title m-2">Iscrizione gratuita</h4>
            </div>
            <div class="row">
                <div class="col-md-6">
                <base-input type="text"
                            readonly
                            label="Nome campionato"
                            placeholder="Nome campionato"
                            v-model="championship.championship_name">
                </base-input>
                </div>
                <div class="col-md-6">
                <base-input
                            label="Max Drivers" type="number" placeholder="1"
                            readonly
                            :min="1" :max="99" inline center controls size="small"
                            v-model="championship.max_drivers">
                </base-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                <div class="form-group">
                    <label>Dettagli campionato</label>
                    <textarea :rows="championship.championship_details_rows" class="form-control border-input"
                            placeholder="Dettagli campionato non presenti"
                            readonly
                            v-model="championship.championship_details">
                    </textarea>
                </div>
                </div>
            </div>
            <div  class="text-center">
                <button v-if='championship.subscriber_ids.indexOf(loggedin_user.id)<0 && 
                championship.max_drivers-championship.subscriber_ids.length>0 &&
                championship.subscription_open' 
                class="btn btn-success btn-fill float-right" @click="this.subscribe">
                    <i class="fa fa-plus"/> Iscriviti</button>

                <button v-if='championship.subscriber_ids.indexOf(loggedin_user.id)<0 && 
                championship.max_drivers-championship.subscriber_ids.length>0 && 
                !championship.subscription_open' 
                class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-times"/> Iscrizioni chiuse</button>

                <button v-if='championship.subscriber_ids.indexOf(loggedin_user.id)>-1 &&
                              championship.cost==0  
                              && championship.subscription_open' 
                    class="btn btn-danger btn-fill float-right" 
                    @click="this.unsubscribe">
                    <i class="fa fa-eraser"/> Cancella iscrizione</button>

                <button v-if='championship.subscriber_ids.indexOf(loggedin_user.id)>-1 &&
                              championship.cost==0  
                              && !championship.subscription_open' 
                    class="btn btn-danger btn-fill float-right" 
                   >
                    <i class="fa fa-eraser"/> Non puoi modificare l'iscrizione</button>

                <button v-if='championship.subscriber_ids.indexOf(loggedin_user.id)>-1 &&
                              championship.cost>0 ' 
                    class="btn btn-danger btn-fill float-right" 
                    >
                    <i class="fa fa-eraser"/> Non puoi cancellare l'iscrizione di un campionato a pagamento</button>
                
                <button v-if='championship.max_drivers-championship.subscriber_ids.length===0' 
                class="btn btn-danger btn-fill float-right">
                    <i class="fa fa-close"/> Non ci sono posti disponibili</button>
            </div>
            <div class="clearfix"></div>
        </card>

      <card class="mt-4">
        <h4 slot="header" class="card-title">Documenti allegati:</h4>
        <div class="row mb-2 mx-auto" v-for="(file, index) in this.championship.files" v-bind:key="index">
        <div class="col-md-1 align-self-center">
            <div class="align-items-center d-flex">
                <i class="fa fa-file-text fa-2x mx-auto" aria-hidden="true"></i>
            </div>
        </div>
          <div class="col-md-11 align-self-center">
            <a :href="file[1]" target="_blank">{{file[0]}}</a>
          </div>
        </div>
      </card>

      <card v-show="championship.championship_discord" class="mt-4">
        <h4  slot="header" class="card-title">Discord dedicato al campionato:</h4>
        <div class="row mb-2 mx-auto">
        <div class="col-md-1 align-self-center">
            <div class="align-items-center d-flex">
                <i class="fa fa-discord fa-2x mx-auto" aria-hidden="true"></i>
                <img class="w-100" style='max-width: 38px;' src="/img/discord_icon.png" />
            </div>
        </div>
          <div class="col-md-10 align-self-center">
            <a :href="championship.championship_discord" target="_blank">Collegati al canale</a>
          </div>
        </div>
      </card>

        <card class="mt-4">
            <h4 v-if="event_list !== []" slot="header" class="card-title">Tappe di campionato:</h4>
            <div class="row">
                    <event class="col-sm-6" v-for="(event, index) in event_list" 
                    :event_name="event.event_name" 
                    :subscriber_ids="event.subscriber_ids" :max_drivers="event.max_drivers" :id="event.id_" 
                    :championship_id="championship.id"
                    :view_mode="true"
                    :event_json_string="objToJSON(event)" v-bind:key="index"/>
            </div>
        </card>
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Utenti iscritti</h4>
              <p class="card-category">in ordine di iscrizione</p>
            </template>
            <div class="tableScrollableFixHeader" >
                <table class="table table-striped table-bordered ">
                    <thead >
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')">SteamId</th>
                            <th v-if="loggedin_user.scopes.includes('Admin')">Rimuovi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in this.subscriberDetails" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{user['first_name']}} {{user['last_name']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')">{{user['steamID']}}</td>
                            <td v-if="loggedin_user.scopes.includes('Admin')" >
                                <button @click="unsubscribe_user(user)" class="my-0 py-0 px-1 btn btn-danger btn-fill text-white" >
                                Disiscrivi
                              </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </card>

    </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue';
import Toggle from 'src/components/Toggle.vue';
import TransitionExpand from 'src/components/TransitionExpand.vue';
import { eventService } from '@/_services';
import { championshipService } from '@/_services';

import moment from 'moment';


export default {
    components: {
      Card,
      Toggle,
      TransitionExpand
    },
    data () {
        return {
        championship_id: '',
        championship: {
          event_ids: [],
          subscriber_ids: [],
          championship_name: '',
          championship_discord:'',
          files: [],
          max_drivers: 0,
          id : '',
          cost: 0,
          championship_details: ``,
          subscription_open: true,
          championship_details_rows: 1,
          id: ''
        },      
        selectedFile: null,
        championship_image_URL: "/img/event_default.jpg",

        loggedin_user: {
            id: "",
            scopes: []
            },
        subscriberDetails: [],
        event_list: []
        }
    },
    created(){
        this.setCurrentUserInfo()
        console.log("In Championship Details")
        this.championship_id=this.$route.query.championship_id
        championshipService.getChampionshipWithEventsById(this.championship_id).then(json=>{
            var json_championship = json
            console.log(json)
            if(json_championship){
                this.event_list = json_championship.events
                this.championship.championship_name =json_championship.championship_name
                this.championship.championship_discord =json_championship.championship_discord

                this.championship.session_ids = json_championship.session_ids
                this.championship.subscriber_ids = json_championship.subscriber_ids
                this.championship.max_drivers = json_championship.max_drivers
                this.championship.championship_details = json_championship.championship_details
                this.championship.id = json_championship.id_
                console.log(json_championship.id_)
                console.log(json_championship.championship_name)
                this.championship.cost = json_championship.cost
                this.championship.subscription_open = json_championship.subscription_open

                for (var key in json_championship.files) {
                    this.championship.files.push([key,json_championship.files[key]])
                }

                if(json_championship.championship_id)
                    this.championship_id = json_championship.championship_id
                
                this.getSubscriberDetails()


                if(this.championship.championship_details)
                    this.championship.championship_details_rows = this.championship.championship_details.split(/\r\n|\r|\n/).length
                

                if(this.championship.id){
                        this.championship_image_URL = process.env.VUE_APP_AWS_BUCKET_URL+this.championship.id+"_championship_image"
                }
            }

        }).catch(err => 
                this.$router.push({name: 'Not Found', })
        );
    },
    methods: {
        onImgError(){
            console.log("ERROR")
            this.championship_image_URL = "/img/event_default.jpg"
        },
        objToJSON(obj){
            return JSON.stringify(obj)
        },
        setCurrentUserInfo(){
            var curr_user = JSON.parse(atob(this.$store.getters.getAccount.user.access_token.split('.')[1]));
            this.loggedin_user.id = curr_user.id
            this.loggedin_user.scopes = curr_user.scopes
        },
        getSubscriberDetails(){
            console.log(this.championship.id)
            championshipService.getSubscriberDetails(this.championship.id).then(json=>{
                JSON.parse(JSON.stringify(json))
                this.subscriberDetails = json
            })
        },
        subscribe(){
            var confirmString = ""
            if(this.championship.cost>0)
                confirmString = ", procedi al pagamento"
            this.$confirm(
            {
                message: `Vuoi confermare l'iscrizione al campionato?`,
                button: {
                no: 'No',
                yes: 'Si'+ confirmString
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    if(this.championship.cost<=0){
                        championshipService.subscribe(this.championship.id).then(json=>{
                            this.$confirm(
                                {
                                    message: `Iscrizione completata`,
                                    button: {
                                    yes: 'Ho capito'
                                    },
                                    callback: confirm => {
                                    if (confirm) {
                                        this.$router.push("/admin/championship-list")
                                    }
                                    }
                                }
                                )
                        }).catch(err => 
                        this.$confirm(
                            {
                                title: 'Errore',
                                message: "Iscrizione al campionato fallita. Contatta un amministratore se il problema persiste",
                                button: {
                                    yes: 'Ho capito',
                                },                
                                callback: confirm => {
                                    if (confirm) {
                                        this.$router.push("/admin/championship-list")
                                    }
                                }
                            }
                        )
                    );
                
                    }
                    //else manda a pagina di pagamento
                    else
                    this.goToPaymentEvent()
                    }
                }
            }
            )
        },
        goToPaymentEvent(){
            /*this.$router.push({
                name: 'Payment Event', 
                params: { type:"event", id: this.event.id }
            });*/
            this.$router.push({ path: 'payment-event', query: { type:"championship", id: this.championship.id }})
        },
        unsubscribe(){
            this.$confirm(
            {
                message: `Vuoi confermare cancellare l'iscrizione all'evento?`,
                button: {
                no: 'No',
                yes: 'Si'
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    championshipService.unsubscribe(this.championship.id).then(json=>{
                        this.$confirm(
                            {
                                message: `Cancellazione iscrizione completata`,
                                button: {
                                yes: 'Ho capito'
                                },
                                /**
                                * Callback Function
                                * @param {Boolean} confirm 
                                */
                                callback: confirm => {
                                if (confirm) {
                                    this.$router.push("/admin/championship-list")
                                }
                                }
                            }
                            )
                    }).catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Cancellazione iscrizione non riuscita, puoi riprovare o contattare un amministratore se il problema persiste.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.push("/admin/championship-list")
                                }
                              }
                        }
                    )
                );

                }
                }
            }
            )
        },
        unsubscribe_user(user){
            this.$confirm(
            {
                message: `Vuoi confermare cancellare l'iscrizione di `+ user['first_name']+' ' + user['last_name'] +` dal campionato?`,
                button: {
                no: 'No',
                yes: 'Si'
                },
                /**
                * Callback Function
                * @param {Boolean} confirm 
                */
                callback: confirm => {
                if (confirm) {
                    championshipService.unsubscribe_user(this.championship.id,user['id_']).then(json=>{
                        this.$confirm(
                            {
                                message: `Cancellazione iscrizione completata`,
                                button: {
                                yes: 'Ho capito'
                                },
                                /**
                                * Callback Function
                                * @param {Boolean} confirm 
                                */
                                callback: confirm => {
                                if (confirm) {
                                    this.$router.push("/admin/championship-list")
                                }
                                }
                            }
                            )
                    }).catch(err => 
                    this.$confirm(
                        {
                            title: 'Errore',
                            message: "Cancellazione iscrizione non riuscita, puoi riprovare o contattare un amministratore se il problema persiste.",
                            button: {
                                yes: 'Ho capito',
                            },                
                              callback: confirm => {
                                if (confirm) {
                                    this.$router.push("/admin/championship-list")
                                }
                              }
                        }
                    )
                );

                }
                }
            }
            )
        }
    }
};
</script>
<style>

</style>
