/*import config from 'config';*/
import auth from '../_helpers/auth-header';


var backendURL=process.env.VUE_APP_BE_URL

export const userService = {
    login,
    getMe,
    logout,
    startRegistration,
    completeRegistration,
    startResetPassword,
    completeResetPassword,
    getAll,
    getById,
    update,
    userResetPassword,
    startChangeMail,
    completeChangeEmail,
    delete_user,
    make_admin,
    remove_admin,
    enable_user,
    disable_user,
    getUserLevel
};

function login(email, password) {
    const data = new URLSearchParams();
    data.append('username', email);
    data.append('password', password);

    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data
    };

    return fetch(`${backendURL}/token`, requestOptions)
        .then(handleLoginResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            console.log('qui');
            if (user.access_token) {
                console.log(user.access_token);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    console.log('REMOVING USER')
    localStorage.removeItem('user');
}


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/`, requestOptions).then(handleResponse);
}

function getMe() {
    const requestOptions = {
        method: 'GET',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/me`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: auth.authHeader()
    };
    return fetch(`${backendURL}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
      method: 'PATCH',
      //headers: auth.authHeader(),
      headers: headers_,
      body: JSON.stringify(user)
    };

    return fetch(`${backendURL}/users/updateMe`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function delete_user(email) {
    const requestOptions = {
        method: 'DELETE',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/delete?user_email=${email}`, requestOptions).then(handleResponse);
}

function make_admin(email) {
    const requestOptions = {
        method: 'PATCH',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/add_admin?user_email=${email}`, requestOptions).then(handleResponse);
}

function remove_admin(email) {
    const requestOptions = {
        method: 'PATCH',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/remove_admin?user_email=${email}`, requestOptions).then(handleResponse);
}

function enable_user(email) {
    const requestOptions = {
        method: 'PATCH',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/enable?user_email=${email}`, requestOptions).then(handleResponse);
}

function disable_user(email) {
    const requestOptions = {
        method: 'PATCH',
        headers: auth.authHeader()
    };

    return fetch(`${backendURL}/users/disable?user_email=${email}`, requestOptions).then(handleResponse);
}

function startRegistration(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${backendURL}/startRegistration`, requestOptions).then(handleResponse);
}

function completeRegistration(user, token) {
    var headers_ = { 'Authorization': 'Bearer ' + token }
    headers_['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers_,
        body: JSON.stringify(user)
    };
    //handleLoginResponse prevent refresh page in case of 401
    return fetch(`${backendURL}/register`, requestOptions).then(handleLoginResponse);
}

function startResetPassword(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${backendURL}/startResetPassword`, requestOptions).then(handleResponse);
}

function completeResetPassword(email_password, token) {
    var headers_ = { 'Authorization': 'Bearer ' + token }
    headers_['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_,
        body: JSON.stringify(email_password)
    };
    //handleLoginResponse prevent refresh page in case of 401
    return fetch(`${backendURL}/resetPassword`, requestOptions).then(handleLoginResponse);
}

function startChangeMail(user) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers_,
        body: JSON.stringify(user)
    };
    return fetch(`${backendURL}/startChangeMail`, requestOptions).then(handleResponse);
}

function completeChangeEmail(token) {
    var headers_ = { 'Authorization': 'Bearer ' + token }
    headers_['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'PUT',
        headers: headers_,
    };
    //handleLoginResponse prevent refresh page in case of 401
    return fetch(`${backendURL}/changeEmail`, requestOptions).then(handleLoginResponse);
}

function userResetPassword(email_password) {
    var headers_ = auth.authHeader()
    headers_['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers_,
        body: JSON.stringify(email_password)
    };
    //handleLoginResponse prevent refresh page in case of 401
    return fetch(`${backendURL}/users/resetUserPassword`, requestOptions).then(handleLoginResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log(data);
        return data;
    });

}
function handleLoginResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log(data);
        return data;
    });
}

function getUserLevel(score,isPro){
    if(!isPro)
        return "Rookie"
    else if (score <= 1000)
        return "Rookie"
    else if (score <= 2000)
        return "Junior"
    else if (score <= 3000)
        return "Semi-pro"
    else if (score > 3000)
        return "Pro"
    else
        return "Rookie"
}